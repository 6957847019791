import React, { useEffect, useState } from "react";
import {
    createStyles,
    Image,
    Container,
    Title,
    Button,
    Group,
    Text,
    List,
    ThemeIcon,
    Stack,
    SimpleGrid,
    Center,
    Transition,
  } from '@mantine/core';
  import { IconCheck } from '@tabler/icons-react';
  import image from '../../Resources/Assets/ic_doggo.jpg';
  import feature from '../../Resources/Assets/feature.png';

import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CLandingHeader, CLandingMainSection } from "./Modern/CLandingHeaderView";
import useWindowDimensions from "../Root/useWindowDimensions";
import { CLandingFeaturesView } from "./Modern/CLandingFeaturesView";
import { CLandingOveralView } from "./Modern/CLandingOveralView";
  
  export const LandingView: React.FC = () => {
    const [mounted, _mounted] = useState(false);

    useEffect(() => {
      _mounted(true);
    }, [])

    return (
        <Container p={0} maw={1280}>
        <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => <div style={{height: "100%", ...styles}}>
          <CLandingHeader />
          <CLandingMainSection />
          <CLandingFeaturesView />
          <CLandingOveralView />
        </div>}
        </Transition>  
      </Container>
    )
};
