import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { MapInteractor } from "./MapInteractor";
import mapboxgl from "mapbox-gl";
import { CLocation2DPoint, DangerAreaDTO, PlaceFitler, PlaceType } from "../../Domain/Places/PlaceService";
import { GeolocationManager } from "./GeolocationManager";
import { ToastManager } from "./ToastManager";
import { error } from "console";
import { MapObjectFabric } from "./MapObjectFabric";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

export interface MapViewModelInput {
    mapContainer: RefObject<HTMLDivElement | null>
}

export function makeLocation(point: CLocation2DPoint): [number, number] {
    return [point.longtitude, point.latitude];
};

export function MapViewModelModern(input: MapViewModelInput) {
    // Запросы в сеть
    const interactor = new MapInteractor();
    const { t } = useTranslation();

    const isMobile = useMediaQuery('(max-width: 36em)');

    // Рисование на карте
    const mapObjectsDrawer = MapObjectFabric();

    // Получение геолокации
    const geolocationManager = GeolocationManager({
        didLocationUpdate: didLocationUpdate
    });

    // Карта
    const mapView = useRef<mapboxgl.Map | null>(null);

    // Координаты центральной точки карты
    const [cameraPosition, _cameraPosition] = useState<CLocation2DPoint>({ latitude: 49.2569, longtitude: -123.1121})
    const [creatingPlaceLocation, _creatingPlaceLocation] = useState<CLocation2DPoint | undefined>({ latitude: 49.2569, longtitude: -123.1121});

    // Создание карты, когда создается контейнер во view
    useEffect(() => {
        if (input.mapContainer.current == null || mapView.current != null) {
            return;
        }

        mapView.current = new mapboxgl.Map({
            container: input.mapContainer.current,
            center: makeLocation(cameraPosition),
            zoom: 10,            
            style: 'mapbox://styles/mapbox/outdoors-v12',
        });
    }, [input.mapContainer]);

    // Видимость модулей
    type VisibilityType = "filters" | "placeCreate" | "placeDetails" | "confirmCreationPlace";
    const [visibilityState, _visibilityState] = useState<VisibilityType>("filters");

    // Кнопки
    // Кнопка выделения опасных мест
    const [isDangerAreasSelected, _isDangerAreasSelected] = useState(false);
    // Кнопка безопасных мест
    const [isSafeAreasSelected, _isSafeAreasSelected] = useState(false);
    // Кнопка "Только мои места"
    const [isOwnerAreasSelected, _isOwnerAreasSelected] = useState(false);

    // Кнопка Опасные места
    function dangerAreasTapped() {
        _isDangerAreasSelected(!isDangerAreasSelected);
    };

    // Кнопка безопасные места
    function safeAreasTapped() {
        _isSafeAreasSelected(!isSafeAreasSelected);
    };

    // Кнопка Мои места
    function ownerAreasTapped() {
        _isOwnerAreasSelected(!isOwnerAreasSelected);
    };

    // Кнопка геолокации
    function geolocationTapped() {
        geolocationManager.obtainGeolocation();
    };

    useEffect(() => {
        updateData();
    }, [isDangerAreasSelected, isSafeAreasSelected, isOwnerAreasSelected]);

    // Геолокация
    // Delegate location
    function didLocationUpdate(location: CLocation2DPoint) {
        moveCamera(location);

        if (mapView.current) {
            mapObjectsDrawer.addGeolocationMarker(location, mapView.current);
        }
    }

    // Перемещение камеры
    function moveCamera(location: CLocation2DPoint) {
        if (!mapView.current) {
            return;
        }

        mapView.current.flyTo({
            center: makeLocation(location),
            zoom: 15
        });
    };

    // Прослушивание на события карты
    useEffect(() => {
        if (mapView.current != null) {
            mapView.current?.on("move", mapViewChangeCameraPositionListener)
            mapView.current?.on("moveend", didMapViewEndMoveCameraListener)
        }

        return () => {
            mapView.current?.off("move", mapViewChangeCameraPositionListener)
            mapView.current?.off("moveend", didMapViewEndMoveCameraListener)
        }
    })

    // Пользователь перестал двигать карту
    function didMapViewEndMoveCameraListener() {
        didMapViewEndMoveCamera();
    };

    function mapViewChangeCameraPositionListener() {
        mapViewChangeCameraPosition();
    }

    const didMapViewEndMoveCamera = useCallback(() => {
        const location = obtainCameraCenter();

        if (!location) { return; };

        _cameraPosition(location);

        if (oldUpdateLocation != null && location.latitude === oldUpdateLocation.latitude && 
            location.longtitude === oldUpdateLocation.longtitude) {
                return;
        }
        updateData();
    }, [isDangerAreasSelected, isOwnerAreasSelected, isOwnerAreasSelected, cameraPosition]);

    // Пользователь двигает карту
    const mapViewChangeCameraPosition = useCallback(() => {
        const location = obtainCameraCenter();

        if (!location) { return; };

        _cameraPosition(location);
    }, [cameraPosition]);

    const didTapOnPlaceMarker = useCallback((placeID: string) => {
        _visibilityState("placeDetails");
        _currentPlaceDetailsID(placeID);
        obtainPlaceDetails(placeID);
    }, [])

    // Получение центра камеры в данный момент
    function obtainCameraCenter() {
        if (!mapView.current) { return undefined; };

        const position = mapView.current.getCenter();
        const location: CLocation2DPoint = { latitude: position.lat, longtitude: position.lng };
        return location;
    };

    // Полчение данных
    const [oldUpdateLocation, _oldUpdateLocation] = useState<CLocation2DPoint | undefined>();

    function updateData() {
        console.log({
            "filters": [
                `danger: ${isDangerAreasSelected}`,
                `safe: ${isSafeAreasSelected}`,
                `owner: ${isOwnerAreasSelected}`,
            ],
            "center": {
                "lat": `${cameraPosition.latitude}`,
                "lng": `${cameraPosition.longtitude}`,
            }
        });

        let filters: Array<PlaceFitler> = [];
        if (isDangerAreasSelected) { filters.push("danger"); };
        if (isSafeAreasSelected) { filters.push("safe"); };

        if (filters.length === 0) {
            mapObjectsDrawer.removeAllPlaces();
            return 
        }

        _oldUpdateLocation(cameraPosition);
        interactor.obtainPlaces(cameraPosition, filters, isOwnerAreasSelected)
            .then((result) => {
                if (!mapView.current) { return }
                mapObjectsDrawer.addPlaces(result.data, mapView.current, didTapOnPlaceMarker)
            })
            .catch((error) => {
                ToastManager.getInstance().showError(`${t("Couldn't get seats nearby:")} ${error}`);
            })
    };

    // Создание места
    const [newPlaceModel, _newPlaceModel] = useState<NewPlaceDataInfoModel>({
        type: "danger"
    });

    const [newPlaceCreateOnProcess, _newPlaceCreateOnProcess] = useState(false);

    // Кнопка Создать место
    function createPlaceConfirmMarkerMobile() {
        _visibilityState("placeCreate");
    };

    function createPlaceCancelMarkerMobile() {
        _visibilityState("filters");
        _creatingPlaceLocation(undefined);
        mapObjectsDrawer.removeCreatePlaceMarker();
    }

    function createPlaceTapped() {
        if (!mapView.current) { return; };

        if (!isMobile) {
            _visibilityState("placeCreate");
        } else {
            _visibilityState("confirmCreationPlace");
        }
        _creatingPlaceLocation(cameraPosition);
        mapObjectsDrawer.drawNewPlaceMarker(cameraPosition, mapView.current, createPlaceMarkerDidChangedLocation);
    };

    // Кнопка Закрыть в модуле создания места
    function createPlaceCloseTapped() {
        if (!isMobile) {
            _visibilityState("filters");
            _creatingPlaceLocation(undefined);
            mapObjectsDrawer.removeCreatePlaceMarker();
        } else {
            _visibilityState("confirmCreationPlace");
        }
    };

    // Listener на именение положения локации маркера создания места
    const createPlaceMarkerDidChangedLocation = useCallback((location: CLocation2DPoint) => {
        _creatingPlaceLocation(location);
    }, []);

    function placeTypeTapped(type: PlaceType) {
        _newPlaceModel({ ...newPlaceModel, ["type"]:type })
    };

    function commentDidChange(commentText?: string) {
        _newPlaceModel({ ...newPlaceModel, ["comment"]:commentText })
    };

    function newPlaceTappedCreate() {
        const placeLocation = creatingPlaceLocation;
        const comment = newPlaceModel.comment;
        const type = newPlaceModel.type;

        if (placeLocation == null) {
            ToastManager.getInstance()
            .showError(t("There are no coordinates of the place you want to create. Reload the page and try again"));
            return;
        }
        if (comment == null) {
            ToastManager.getInstance()
                .showError(t("In order for everyone to understand what kind of place this is, your description is very necessary"));
            return;
        }
        
        _newPlaceCreateOnProcess(true);
        interactor.createPlace(placeLocation, type, comment)
        .then((result) => {
            _newPlaceCreateOnProcess(false);
            if (result.status === 200) {
                updateData();
            }
            if (!isMobile) {
                createPlaceCloseTapped();
            } else {
                createPlaceCancelMarkerMobile();
            }
            _newPlaceModel({type: "danger"});
            ToastManager.getInstance().showSuccess(t("The place has been successfully created! Thank you for participating in the project!"));
        })
        .catch((error) => {
            ToastManager.getInstance().showError(`${t("Unable to create a place, try again:")} ${error}`);
            _newPlaceCreateOnProcess(false);
        })
    };

    // Деталка места
    const [currentPlaceDetailsID, _currentPlaceDetailsID] = useState<string | undefined>();
    const [placeDetailsModel, _placeDetailsModel] = useState<DangerAreaDTO>();
    const [isErrorOnPlaceDetails, _isErrorOnPlaceDetails] = useState(false);
    const [placeDetailsLoading, _placeDetailsLoading] = useState(false);

    function obtainPlaceDetails(elementID: string) {
        _placeDetailsModel(undefined);
        _placeDetailsLoading(true);
        interactor.obtainPlace(elementID)
            .then((result) => {
                if (!result.data) { return; };
                _isErrorOnPlaceDetails(false);
                _placeDetailsModel(result.data);
                _placeDetailsLoading(false);
            })
            .catch((error) => {
                ToastManager.getInstance()
                    .showError(`${"Couldn't get a seat:"} ${error}`);
                _placeDetailsModel(undefined);
                _isErrorOnPlaceDetails(true);
                _placeDetailsLoading(false);
            })
    };

    function petDetailsCloseTapped() {
        _visibilityState("filters");
    };

    function deletePlaceTapped(placeID: string) {
        _placeDetailsLoading(true);
        interactor.deletePlace(placeID)
        .then((result) => {
            petDetailsCloseTapped();
            updateData();
        })
        .catch((error) => {
            ToastManager.getInstance()
                .showError(`${t("Failed to delete location:")} ${error}`);
            _placeDetailsLoading(false);
        })
    };

    function petDetailsRetryTapped() {
        if (!currentPlaceDetailsID) { return; };

        obtainPlaceDetails(currentPlaceDetailsID);
    };

    return ({
        map: {
            mapView
        },
        buttons: {
            isDangerAreasSelected, dangerAreasTapped,
            isSafeAreasSelected, safeAreasTapped,
            isOwnerAreasSelected, ownerAreasTapped,
            geolocationTapped, createPlaceTapped
        },
        visibility: {
            visibilityState
        },
        createPlaceInput: {
            createPlaceCloseTapped,
            commentDidChange,
            placeTypeTapped,
            creatingPlaceLocation,
            newPlaceModel,
            newPlaceTappedCreate,
            newPlaceCreateOnProcess,
            createPlaceConfirmMarkerMobile,
            createPlaceCancelMarkerMobile
        },
        detailsInput: {
            isErrorOnPlaceDetails,
            placeDetailsModel,
            placeDetailsLoading,
            petDetailsRetryTapped,
            petDetailsCloseTapped,
            deletePlaceTapped    
        }
    })
}

export interface NewPlaceDataInfoModel {
    type: PlaceType,
    comment?: string,
}