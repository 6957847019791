import { Container, Group, Paper, SimpleGrid, Stack, Text } from "@mantine/core";
import { IconNotebook, IconMap2, IconInfoSquareRounded } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

export const CLandingFeaturesView: React.FC = () => {
    const { t } = useTranslation();

    const items = [
        { icon: "document", title: t("Pet health tracker"), subtitle: t("Keep track of your pet's vaccinations, treatments, test results, doctor appointments, and personal notes in one convenient place.") },
        { icon: "map", title: t("Public map"), subtitle: t("Find safe places to take your pet, while also identifying and avoiding dangerous areas in your community.") },
        { icon: "info", title: t("Pet information"), subtitle: t("Store important details about your pet, such as their name, age, breed, and other information, in one easy-to-access location.") }
    ]

    function createItem(model: { icon: string, title: string, subtitle: string }): React.ReactElement {
        const icon = () => {
            switch (model.icon) {
                case "document":
                    return <IconNotebook stroke={2.5} size={40}/>

                case "map":
                    return <IconMap2 stroke={2.5} size={40}/>

                case "info":
                    return <IconInfoSquareRounded stroke={2.5} size={40}/>
            }
        };

        return (
            <Paper p={20} bg={"#F5F5F5"} radius="lg" h={250} w={300}>
                <Stack h={"100%"} justify="space-between">
                    { icon() }
                    <Stack spacing={12}>
                        <Text style={{lineHeight: 1.1}} size={18} weight={700}>{model.title}</Text>
                        <Text color="dimmed" style={{lineHeight: 1.1}} size={16} weight={500}>{model.subtitle}</Text>
                    </Stack>
                </Stack>
            </Paper>
        )
    }

    const views = items.map((item) => {
        return createItem(item)
    })

    return (
        <Container fluid p={20} mt={30}>
            <Stack spacing={24}>
            <Group w={"100%"} position="center">
                <Text
                    weight={900}
                    style={{lineHeight: 1.1}}
                    size={48}>{t("Features")}
                </Text>
            </Group>
            <Group w={"100%"} position="center">
                { views }
            </Group>
            </Stack>
        </Container>
    )
};