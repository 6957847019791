import { useTranslation } from "react-i18next";
import { CLocation2DPoint } from "../../Domain/Places/PlaceService";
import { ToastManager } from "./ToastManager";

export interface GeolocationManagerInput {
    didLocationUpdate: (location: CLocation2DPoint) => void;
}

export function GeolocationManager(input: GeolocationManagerInput) {
    const { t } = useTranslation();
    
    function obtainGeolocation() {
        navigator.geolocation.getCurrentPosition(
            geolocationDidRecieved,
            geolocationDidFailed
        )
    };

    function geolocationDidRecieved(position: GeolocationPosition): void {
        const location: CLocation2DPoint = {
            latitude: position.coords.latitude,
            longtitude: position.coords.longitude
        }

        input.didLocationUpdate(location);
    }

    function geolocationDidFailed(positionError: GeolocationPositionError): void {
        ToastManager.getInstance().showError(`${t("Couldn't get your geolocation:")} ${positionError}`);
    };

    return {
        obtainGeolocation
    }
}