import axios from "axios";
import { BaseURL } from "../BasicURLScheme";

export class PetListService {
    private baseURL: BaseURL = BaseURL.shared;

    public async obtainPetList() {
        const token = this.baseURL.obtainHeader();

        return axios.get<Array<PetDTO>>(this.baseURL.buildEndpoin("api/modern/pets/list/my"), {
            headers: {
                ...token
            }
        });
    };

    public async createPet(petDTO: CreatePetDTO) {
        const token = this.baseURL.obtainHeader();

        return axios({
            method: "POST",
            url: this.baseURL.buildEndpoin("api/modern/pets/create"),
            headers: { ...token },
            data: { ...petDTO }
        });
    };

    public async obtainPetDetails(petID: string) {
        const token = this.baseURL.obtainHeader();

        return axios.get<PetDTO>(this.baseURL.buildEndpoin(`api/modern/pets/${petID}`), {
            headers: {
                ...token
            }
        });
    };

    public async obtainBrandDetails(brandID: string) {
        const token = this.baseURL.obtainHeader();

        return axios.get<BrandDTO>(this.baseURL.buildEndpoin(`api/modern/brand/${brandID}`), {
            headers: {
                ...token
            }
        });
    };

    public async obtainChipDetails(chipID: string) {
        const token = this.baseURL.obtainHeader();

        return axios.get<ChipDTO>(this.baseURL.buildEndpoin(`api/modern/chip/${chipID}`), {
            headers: {
                ...token
            }
        });
    }

    public async createBrand(brandDTO: BrandClientDTO) {
        const token = this.baseURL.obtainHeader();

        return axios<BrandDTO>({
            method: "POST",
            url: this.baseURL.buildEndpoin(`api/modern/brand/`),
            headers: { ...token },
            data: { ...brandDTO }
        });
    }

    public async createChip(chipDTO: ChipClientDTO) {
        const token = this.baseURL.obtainHeader();

        return axios({
            method: "POST",
            url: this.baseURL.buildEndpoin(`api/modern/chip/`),
            headers: { ...token },
            data: { ...chipDTO }
        });
    };

    public async deleteChip(chipID: string) {
        const token = this.baseURL.obtainHeader();

        return axios.delete(this.baseURL.buildEndpoin(`api/modern/chip/${chipID}`), {
            headers: {
                ...token
            }
        });
    };

    public async deleteBrand(chipID: string) {
        const token = this.baseURL.obtainHeader();

        return axios.delete(this.baseURL.buildEndpoin(`api/modern/brand/${chipID}`), {
            headers: {
                ...token
            }
        });
    };

    public async updatePet(petID: string, dto: PetUpdateDTO) {
        const token = this.baseURL.obtainHeader();

        return axios({
            method: "PUT",
            url: this.baseURL.buildEndpoin(`api/modern/pets/update/${petID}`),
            headers: { ...token },
            data: { ...dto }
        });
    };

    public async updateAvatar(petID: string, file: File) {
        const token = this.baseURL.obtainHeader();

        const formData = new FormData();
        const blob = new Blob([file], {
            type: `${file.type}`,
        });
              
        formData.append(`avatar`, blob, "avatar");

        return axios.put(this.baseURL.buildEndpoin(`api/modern/pets/update/avatar/${petID}`), formData, {
            headers: { 
                ...token,
            }
        });
    };
}

export interface PetUpdateDTO {
    name?: string;
    height?: number;
    weight?: number;
    birthday?: string;
    gender?: string;
    colour?: string;
    breed?: string;
    kind?: string;
}

export interface CreatePetDTO {
    name: string,
    birthday: string,
    gender: string,
    kind: string    
};

export interface PetDTO {
    id: string,
    ownerID: string,
    name: string,
    birthday: string,
    imageID?: string,
    height?: number,
    weight?: number,
    breed?: string,
    colour?: string,
    chipDTO?: ChipDTO,
    brandDTO?: BrandDTO,

    // enums
    genderType?: string,
    biologicalKindType?: string
};

export interface BrandDTO {
    id: string;
    ownerID: string;
    petID: string;
    number: string;
    brandingDate: string;
}


export interface ChipDTO {
    id: string;
    ownerID: string;
    petID: string;
    number: string;
    implantingDate: string;
    location: string;
}

export interface BrandClientDTO {
    petID: string;
    brandNumber: string;
    brandingDate: string;
}

export interface ChipClientDTO {
    petID: string;
    number: string;
    implantingDate: string;
    location: string;
}