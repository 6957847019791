import { ActionIcon, createStyles, Drawer, Group, Modal, Space, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconCircleX } from "@tabler/icons-react";
import { useState } from "react";
import { DewormingCardCreateInterface, DewormingCardCreateView } from "../../Modules/AmbulanceCards/Create/DewormingCardCreate";
import { EctoparasitiesCardCreateInterface, EctoparasitiesCardCreateView } from "../../Modules/AmbulanceCards/Create/EctoparasitiesCardCreate";
import { ExaminationCardCreateInterface, ExaminationCardCreateView } from "../../Modules/AmbulanceCards/Create/ExaminationCardCreate";
import { NotesCardCreateInterface, NotesCardCreateView } from "../../Modules/AmbulanceCards/Create/NotesCardCreate";
import { TestCardCreateInterface, TestCardCreateView } from "../../Modules/AmbulanceCards/Create/TestCardCreate";
import { VaccineCardCreateView, VaccineCardCreateViewInterface } from "../../Modules/AmbulanceCards/Create/VaccineCardCreate";
import { DewormingDetailsInfoModel, DewormingDetailsView } from "../../Modules/AmbulanceCards/Details/DewormingDetailsView";
import { EctoparasitiesDetailsInfoModel, EctoparasitiesDetailsView } from "../../Modules/AmbulanceCards/Details/EctoparasitiesDetailsView";
import { ExaminationDetailsInfoModel, ExaminationDetailsView } from "../../Modules/AmbulanceCards/Details/ExaminationDetailsView";
import { NoteDetailsInfoModel, NoteDetailsView } from "../../Modules/AmbulanceCards/Details/NotesDetailsView";
import { TestDetailsInfoModel, TestDetailsView } from "../../Modules/AmbulanceCards/Details/TestDetailsView";
import { VaccineDetailsView, VaccineDetailsInfoModel } from "../../Modules/AmbulanceCards/Details/VaccineDetailsView";
import { CBrandView, BrandDetailsViewBuilder } from "../../Modules/BrandCard/BrandDetailsView";
import { CChipDetailsViewBuilder, CChipView } from "../../Modules/ChipCard/CChipView";
import { CPetEditCardInfoModel, CPetEditCardView } from "../../Modules/PetEditCard/CPetEditCardView";
import { CMedicineListType } from "../../Modules/PetDetailsMedicaList/CMedicineListView";
import { CreatePetFormView } from "../../Modules/Creations/Pet/CreatePetFormView";
import { ProfileEditCard, ProfileEditCardBuilder } from "../../Modules/Profile/ProfileEditCard";
import { useTranslation } from "react-i18next";

export function CDrawerRouter() {
    const [isActive, _isActive] = useState(false);
    const [modalTitle, _modalTitle] = useState<string | undefined>(undefined);
    const [modalContent, _modalContent] = useState<JSX.Element>(<></>);

    const { classes } = createStyles((theme) => ({
        content: {
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
        },
      }))();

    const view = (
        <Drawer
            opened={isActive}
            onClose={() => { 
                _isActive(false);
            }}
            position="bottom"
            withCloseButton={false}
            scrollAreaComponent={Modal.NativeScrollArea}
            size="85%"
            trapFocus={false}
            classNames={classes}
      >
        <>
          <Group position="apart">
            <Title order={4}>{modalTitle}</Title>
            <ActionIcon onClick={() => { _isActive(false) }}><IconCircleX /></ActionIcon>
          </Group>
          <Space h={12}></Space>
          { modalContent }
        </>
      </Drawer>
    );

    return ({
        view,
        _isActive,
        _modalTitle,
        _modalContent
    });
};

export function CModalRouter() {
    const [isActive, _isActive] = useState(false);
    const [modalTitle, _modalTitle] = useState<string | undefined>(undefined);
    const [modalContent, _modalContent] = useState<JSX.Element>(<></>);

    const view = (
        <Modal
            padding="lg"
            centered
            opened={isActive}
            onClose={() => _isActive(false)}
            radius="lg"
            size="md"
            withCloseButton={false}
            trapFocus={false}
        >
        <>
          <Group position="apart">
            <Title order={4}>{modalTitle}</Title>
            <ActionIcon onClick={() => { _isActive(false) }}><IconCircleX /></ActionIcon>
          </Group>
          <Space h={12}></Space>
          { modalContent }
        </>
      </Modal>
    )

    return ({
        view, 
        _isActive,
        _modalTitle,
        _modalContent,
    });
};

export function CModalProviderRouter() {
    const isDesktop = useMediaQuery('(min-width: 61.85em)');

    const desktop = CModalRouter();
    const mobile = CDrawerRouter();
    const { t } = useTranslation();

    function showVaccineCreate(builder: VaccineCardCreateViewInterface) {
        if (isDesktop == undefined) { return; }
        const { _isActive, _modalTitle, _modalContent } = isDesktop ? desktop : mobile

        _modalTitle(t("Vaccine creation")!);
        _modalContent(<VaccineCardCreateView {...builder as VaccineCardCreateViewInterface} />);
        _isActive(true);
    };

    function showDewormingCreate(builder: DewormingCardCreateInterface) {
        if (isDesktop == undefined) { return; }
        const { _isActive, _modalTitle, _modalContent } = isDesktop ? desktop : mobile

        _modalTitle(t("New deworming")!);
        _modalContent(<DewormingCardCreateView {...builder} />);
        _isActive(true);
    };

    function showEctoparasitiesCreate(builder: EctoparasitiesCardCreateInterface) {
        if (isDesktop == undefined) { return; }
        const { _isActive, _modalTitle, _modalContent } = isDesktop ? desktop : mobile

        _modalTitle(t("New treatment")!);
        _modalContent(<EctoparasitiesCardCreateView {...builder} />);
        _isActive(true);
    };

    function showTestCreate(builder: TestCardCreateInterface) {
        if (isDesktop == undefined) { return; }
        const { _isActive, _modalTitle, _modalContent } = isDesktop ? desktop : mobile

        _modalTitle(t("New test")!);
        _modalContent(<TestCardCreateView {...builder} />);
        _isActive(true);
    };

    function showExaminationCreate(builder: ExaminationCardCreateInterface) {
        if (isDesktop == undefined) { return; }
        const { _isActive, _modalTitle, _modalContent } = isDesktop ? desktop : mobile

        _modalTitle(t("New doctor's appointment")!);
        _modalContent(<ExaminationCardCreateView {...builder} />);
        _isActive(true);
    };

    function showNotesCreate(builder: NotesCardCreateInterface) {
        if (isDesktop == undefined) { return; }
        const { _isActive, _modalTitle, _modalContent } = isDesktop ? desktop : mobile

        _modalTitle(t("New Note")!);
        _modalContent(<NotesCardCreateView {...builder} />);
        _isActive(true);
    };

    function showVaccineDetails(builder: VaccineDetailsInfoModel) {
        if (isDesktop == undefined) { return; }
        const { _isActive, _modalTitle, _modalContent } = isDesktop ? desktop : mobile

        _modalTitle(t("Vaccine")!);
        _modalContent(<VaccineDetailsView {...builder} />);
        _isActive(true);
    };

    function showDewormingDetails(builder: DewormingDetailsInfoModel) {
        if (isDesktop == undefined) { return; }
        const { _isActive, _modalTitle, _modalContent } = isDesktop ? desktop : mobile

        _modalTitle(t("Deworming")!);
        _modalContent(<DewormingDetailsView {...builder} />);
        _isActive(true);
    };

    function showEctoparasitiesDetails(builder: EctoparasitiesDetailsInfoModel) {
        if (isDesktop == undefined) { return; }
        const { _isActive, _modalTitle, _modalContent } = isDesktop ? desktop : mobile

        _modalTitle(t("Treatment")!);
        _modalContent(<EctoparasitiesDetailsView {...builder} />);
        _isActive(true);
    };

    function showTestsDetails(builder: TestDetailsInfoModel) {
        if (isDesktop == undefined) { return; }
        const { _isActive, _modalTitle, _modalContent } = isDesktop ? desktop : mobile

        _modalTitle(t("Test")!);
        _modalContent(<TestDetailsView {...builder} />);
        _isActive(true);
    };

    function showExaminationDetails(builder: ExaminationDetailsInfoModel) {
        if (isDesktop == undefined) { return; }
        const { _isActive, _modalTitle, _modalContent } = isDesktop ? desktop : mobile

        _modalTitle(t("Appointment")!);
        _modalContent(<ExaminationDetailsView {...builder} />);
        _isActive(true);
    };

    function showNoteDetails(builder: NoteDetailsInfoModel) {
        if (isDesktop == undefined) { return; }
        const { _isActive, _modalTitle, _modalContent } = isDesktop ? desktop : mobile

        _modalTitle(t("Note")!);
        _modalContent(<NoteDetailsView {...builder} />);
        _isActive(true);
    }; 

    function showPetDetails(builder: CPetEditCardInfoModel) {
        if (isDesktop == undefined) { return; }
        const { _isActive, _modalTitle, _modalContent } = isDesktop ? desktop : mobile

        _modalTitle(t("Editing the pet")!);
        _modalContent(<CPetEditCardView {...builder} />);
        _isActive(true);
    };

    function showBrandDetails(builder: BrandDetailsViewBuilder) {
        if (isDesktop == undefined) { return; }
        const { _isActive, _modalTitle, _modalContent } = isDesktop ? desktop : mobile

        _modalTitle(t("Branding")!);
        _modalContent(<CBrandView {...builder} needClose={ () => { _isActive(false) } } />);
        _isActive(true);
    };

    function showChipDetails(builder: CChipDetailsViewBuilder) {
        if (isDesktop == undefined) { return; }
        const { _isActive, _modalTitle, _modalContent } = isDesktop ? desktop : mobile

        _modalTitle(t("Chip")!);
        _modalContent(<CChipView {...builder} needClose={ () => { _isActive(false) } } />);
        _isActive(true);
    };

    function showPetCreate() {
        if (isDesktop == undefined) { return; }
        const { _isActive, _modalTitle, _modalContent } = isDesktop ? desktop : mobile

        _modalTitle(t("Creating a pet")!);
        _modalContent(<CreatePetFormView onClose={ () => { _isActive(false) } } />);
        _isActive(true);
    };

    function showProfieEditCard(model: ProfileEditCardBuilder) {
        if (isDesktop == undefined) { return; }
        const { _isActive, _modalTitle, _modalContent } = isDesktop ? desktop : mobile

        _modalTitle(t("Editing Profile")!);
        _modalContent(<ProfileEditCard {...model} onClose={ () => { _isActive(false) } } />);
        _isActive(true);
    }

    return ({
        isActive: isDesktop ? desktop._isActive : mobile._isActive,
        showVaccineCreate,
        showDewormingCreate,
        showEctoparasitiesCreate,
        showExaminationCreate,
        showNotesCreate,
        showTestCreate,
        showVaccineDetails,
        showDewormingDetails,
        showNoteDetails,
        showTestsDetails,
        showExaminationDetails,
        showEctoparasitiesDetails,
        showPetDetails,
        showBrandDetails,
        showChipDetails,
        showPetCreate,
        showProfieEditCard,
        drawerProvider: mobile.view,
        modalProvider: desktop.view
    })
}

export function CModalProvider() {
    interface ChooseMedicalUIBuilder {
        tab: CMedicineListType;
    };
    interface ChooseMedicalCreateDataBuilder {
        petID: string;
        moduleDidCloseAfterSuccess: () => void;
    };
    interface ChooseMedicalDetailsDataBuilder {
        petID: string;
        medicalID: string;
    };

    const router = CModalProviderRouter();

    function openProfileEditCard(model: ProfileEditCardBuilder) {
        router.showProfieEditCard(model);
    }

    function openPetCard(builder: CPetEditCardInfoModel) {
        router.showPetDetails(builder);
    };

    function openBrandCard(builder: BrandDetailsViewBuilder) {
        router.showBrandDetails(builder);
    };

    function openChipCard(builder: CChipDetailsViewBuilder) {
        router.showChipDetails(builder);
    };

    function openCreatePetCard() {
        router.showPetCreate();
    };

    function vaccineType(sectionType: "mandatory_vaccination" | "other_mandatory_vaccination" | "other_vaccination") {
        switch (sectionType) {
            case "mandatory_vaccination":
                return "rabies";

            case "other_mandatory_vaccination":
                return "parvovirus";

            case "other_vaccination":
                return "other";

            default:
                return "other";
        }
    };

    function chooseMedicalDetailsView(builder: ChooseMedicalUIBuilder & ChooseMedicalDetailsDataBuilder) {
        const tab = builder.tab;
        const petID = builder.petID;

        switch (tab) {
            case "mandatory_vaccination":
            case "other_mandatory_vaccination":
            case "other_vaccination":
                router.showVaccineDetails({
                    petID: petID,
                    medicineID: builder.medicalID,
                });
                break;

            case "deworming":
                router.showDewormingDetails({
                    petID: petID,
                    medicineID: builder.medicalID,
                });
                break;

            case "ectoparasites":
                router.showEctoparasitiesDetails({
                    petID: petID,
                    medicineID: builder.medicalID,
                });
                break;

            case "test":
                router.showTestsDetails({
                    petID: petID,
                    medicineID: builder.medicalID,
                });
                break;

            case "examination":
                router.showExaminationDetails({
                    petID: petID,
                    medicineID: builder.medicalID,
                });
                break;

            case "notes":
                router.showNoteDetails({
                    petID: petID,
                    medicineID: builder.medicalID,
                });
                break;
        }
    };

    function chooseMedicalCreateView(builder: ChooseMedicalUIBuilder & ChooseMedicalCreateDataBuilder) {
        const tab = builder.tab;
        const moduleDidCloseAfterSuccess = () => {
            router.isActive(false);
            builder.moduleDidCloseAfterSuccess();
        };
        const petID = builder.petID;

        switch (tab) {
            case "mandatory_vaccination":
            case "other_mandatory_vaccination":
            case "other_vaccination":
                router.showVaccineCreate({
                    vaccineType: vaccineType(tab),
                    petID: petID,
                    moduleDidCloseAfterSuccess: moduleDidCloseAfterSuccess
                });
                break;

            case "deworming":
                router.showDewormingCreate({
                    petID: petID,
                    moduleDidCloseAfterSuccess: moduleDidCloseAfterSuccess
                });
                break;

            case "ectoparasites":
                router.showEctoparasitiesCreate({
                    petID: petID,
                    moduleDidCloseAfterSuccess: moduleDidCloseAfterSuccess
                });
                break;

            case "test":
                router.showTestCreate({
                    petID: petID,
                    moduleDidCloseAfterSuccess: moduleDidCloseAfterSuccess
                });
                break;

            case "examination":
                router.showExaminationCreate({
                    petID: petID,
                    moduleDidCloseAfterSuccess: moduleDidCloseAfterSuccess
                });
                break;

            case "notes":
                router.showNotesCreate({
                    petID: petID,
                    moduleDidCloseAfterSuccess: moduleDidCloseAfterSuccess
                });
                break;
        }
    }

    return ({
        chooseMedicalCreateView,
        chooseMedicalDetailsView,
        openPetCard,
        openBrandCard,
        openChipCard,
        openCreatePetCard,
        openProfileEditCard,
        drawerProvider: router.drawerProvider,
        modalProvider: router.modalProvider
    })
};