export class DataObservable {
    private static instance: DataObservable;
    private observers: Observable[] = [];
  
    private constructor() {}
  
    public static getInstance(): DataObservable {
      if (!DataObservable.instance) {
        DataObservable.instance = new DataObservable();
      }
      return DataObservable.instance;
    }
  
    public addObserver(observer: Observable): void {
        console.log(`add observable ${observer.observableID}`);
        this.removeObserver(observer);

        this.observers.push(observer);
        console.log(`add observable ${this.observers}`);
    }
  
    public removeObserver(observer: Observable): void {
      this.observers = this.observers.filter((obs) => obs.observableID !== observer.observableID);
    }
  
    public notifyObservers(type: ObservableType, data?: any): void {
      this.observers.forEach((observer) => {
            if (type === observer.type) {
                observer.action(data)
            }
    });
    }

    public doSomething(type: ObservableType): void {
      // do some work here
      this.notifyObservers(type);
    }
};

export type ObservableType = "medicalTable" | "petCreated"

export interface Observable {
    observableID: string;
    type: ObservableType;
    action: (data?: any) => void;
};