import { Container, LoadingOverlay, Stack, Text } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { CareFileInputView } from "../../../Core/FileInputComponent/CareFileInputView";
import { CFileListView } from "../../../Core/FileListComponent/CFileListView";
import { useTranslation } from "react-i18next";
import { AppointmentAPIDTO, AppointmentService } from "../../../Domain/Medical/AppointmentService";
import { dateToHumanString } from "../../../Core/Extensions/ConvertToDateFromTS";
import { FileService, MedicalDocumentTypeDTO } from "../../../Domain/FileService/FileService";

export interface ExaminationDetailsInfoModel {
    petID: string;
    medicineID: string;
}

export function ExaminationDetailsViewModel(infoModel: ExaminationDetailsInfoModel) {
    const appointmentService = new AppointmentService();
    const fileService = new FileService();

    const { t } = useTranslation();
    const [examinationName] = useState({
        label: t("Appointment reason"),
    });

    const [examinationDate] = useState({
        label: t("Date of appointment"),
    });

    const [vaccineFilesValidLabel] = useState({
        label: t("You can attach files"),
        accept: "image/png,image/jpeg",
        description: t("Maximum number of files - 5"),
        placeholder: t("Select files. For now, png/jpeg files are accepted")
    });

    const [data, setData] = useState<AppointmentAPIDTO | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const [files, setFiles] = useState<File[]>([]);
    const [addTappedState, setAddTappedState] = useState(false);

    function obtainExamination() {
        appointmentService.fetchDetails(infoModel.medicineID)
        .catch((error) => {
            notifications.show({
                title: t("Error"),
                message: t("Failed to load the card, please try again later"),
                color: "red",
                autoClose: true
            })
            return;
        })
        .then((response) => {
            if (!response || !response.data) {
                return;
            }

            setData(response.data);
            setLoading(false);
        });
    };

    useEffect(() => {
        obtainExamination();
    }, [])

    function update() {
        obtainExamination();
    };

    function saveDocumentsTapped() {
        if (!data) { return; };
        
        fileService.uploadMedicalFiles(data.id, MedicalDocumentTypeDTO.examination, files)
        .catch(error => {
            console.log(error);
        })
        .then(result => {
            if (!result) { return }

            if (result.status == 200) {
                update();
                setAddTappedState(false);
            }
        });
    };

    return ({
        data, examinationName, examinationDate, loading, vaccineFilesValidLabel, addTappedState,
        update, setAddTappedState, setFiles, saveDocumentsTapped,
    });
}

export function ExaminationDetailsView(infoModel: ExaminationDetailsInfoModel) {
    const viewModel = ExaminationDetailsViewModel(infoModel);

    const fileView = () => {
        if (!viewModel.addTappedState) {
            return <CFileListView addTapped={() => { viewModel.setAddTappedState(true) }} update={viewModel.update} loading={viewModel.loading} documents={viewModel.data?.documentDTOs ?? []}/>
        }

        return (
            <>
                <Text component="label" htmlFor="revaccinationDate" size="sm" weight={500}>
                    { viewModel.vaccineFilesValidLabel.label }
                </Text>
                <CareFileInputView
                    description={viewModel.vaccineFilesValidLabel.description}
                    placeholder={viewModel.vaccineFilesValidLabel.placeholder}
                    accept={viewModel.vaccineFilesValidLabel.accept}
                    filesDidChange={viewModel.setFiles}
                    saveEnabled={true}
                    saveTapped={viewModel.saveDocumentsTapped}
                    uploadedDocuments={viewModel.data?.documentDTOs ?? []}
                />
            </>
        )
    }

    const mainView = (
        <Stack>
            <Stack spacing={2}>
                <Text color="dimmed" component="label" htmlFor="vaccineType" size="sm" weight={400}>
                    { viewModel.examinationName.label }
                </Text>
                <Text
                    size="md"
                    weight={600}
                >
                    { viewModel.data?.reason ?? "" }
                </Text>
            </Stack>
            <Stack spacing={2}>
                <Text color="dimmed" component="label" htmlFor="vaccineName" size="sm" weight={400}>
                    { viewModel.examinationDate.label }
                </Text>
                <Text
                    size="md"
                    weight={600}
                >
                    { dateToHumanString(viewModel.data?.appointmentDate) ?? ""}
                </Text>
            </Stack>
            <Stack spacing={2}>
                { fileView() }
            </Stack>
        </Stack>
    );

    return (
        <>
        <Container p={0}>
            { mainView }
            <LoadingOverlay visible={viewModel.loading} overlayBlur={5} />
        </Container>
        </>
    )
}