import axios from "axios";
import { BaseURL } from "./BasicURLScheme";

export class MedicalDocumentsService {
    private baseURL: BaseURL = BaseURL.shared;
    private module: string = "api/modern/documents"

    public async obtainDetalis(id: string) {
        const token = this.baseURL.obtainHeader();
        return axios.get<DocumentAPIDTO>(
            this.baseURL.buildEndpoin(`${this.module}/${id}`),
            {
                headers: {
                    ...token
                }
            }
        );
    }

    public async remove(id: string) {
        const token = this.baseURL.obtainHeader();
        return axios.delete(
            this.baseURL.buildEndpoin(`${this.module}/${id}`),
            {
                headers: {
                    ...token
                }
            }
        );
    }
}

export interface DocumentAPIDTO {
    id: string;
    fileID: string;
    fileName: string;
    medicalID: string;
    fileExtension: string;
}