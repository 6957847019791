import i18next from "i18next";

export enum Gender {
    Boy = "boy",
    Girl = "girl",
}

export function makeGenderEnum(rawValue: string | undefined) {
    if (Gender.Boy == rawValue) {
        return Gender.Boy
    }
    if (Gender.Girl == rawValue) {
        return Gender.Girl
    }

    return undefined;
}

export function obtainGenderReadble(gender: Gender | undefined) {
    switch (gender) {
        case Gender.Boy:
            return i18next.t("Boy");

        case Gender.Girl:
            return i18next.t("Girl");
    }

    return undefined;
}