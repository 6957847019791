import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { vaccineTypeHelper } from "../../../Resources/Helpers/vaccineTypeHelper";
import { useTranslation } from "react-i18next";
import { VaccinationCreateAPIDTO, VaccinationService } from "../../../Domain/Medical/VaccinationService";
import { FileService, MedicalDocumentTypeDTO } from "../../../Domain/FileService/FileService";
import { VaccineType } from "../../../Domain/Ambulance/AmbulanceService";

export interface VaccineCardCreateInfoModel {
    petID: string;
    vaccineType: VaccineType;
    moduleDidCloseAfterSuccess: () => void;
}

export interface VaccineCardCreateViewModelOutput {
    isActionButtonEnabled: boolean;
    data: VaccineFillDataInterface;
    vaccineNameLabel: { label: string; placeholder: string; };
    vaccineNumberLabel: { label: string; placeholder: string; };
    vaccineDateLabel: { label: string; placeholder: string; };
    reVaccineDateLabel: { label: string; placeholder: string; description: string; };
    vaccineDateValidLabel: { label: string; placeholder: string; description: string; };
    vaccineFilesValidLabel: { label: string; accept: string; description: string; placeholder: string; }
    vaccineTypeSelect: { label: string; placeholder: string; data: { value: string; label: string; }[] }
    actionButtonTitle: string;
    actionButtonState: "success" | "blank";
    changedValue: (value: any, prop: VaccinePole) => void;
    filesChanged: (files: File[]) => void;
    buttonTapped: () => void;
};

interface VaccineFillDataInterface {
    vaccineType: string;
    vaccineName?: string;
    vaccineNumber?: string;
    vaccineDate?: Date;
    revaccinationDate?: Date;
    vaccineValidDate?: Date;
}

type VaccinePole = "vaccineType" | "vaccineName" | "vaccineNumber" | "vaccineDate" | "revaccinationDate" | "vaccineValidDate";

export function VaccineCardCreateViewModel(infoModel: VaccineCardCreateInfoModel) {
    const { t } = useTranslation();
    const [data, setData] = useState<VaccineFillDataInterface>({
        vaccineType: infoModel.vaccineType,
    });
    const [files, setFiles] = useState<File[]>([]);

    const [vaccineNameLabel] = useState({
        label: t("Vaccine name"),
        placeholder: "Novibak"
    });
    const [vaccineNumberLabel] = useState({
        label: t("Vaccine number"),
        placeholder: "DVSDC1"
    });
    const [vaccineDateLabel] = useState({
        label: t("Date of vaccination"),
        placeholder: t("Choose a date")
    });

    const [revaccineDateLabel] = useState({
        label: t("Date of revaccination"),
        placeholder: t("Choose a date"),
        description: t("Rabies revaccination usually takes place once a year")
    });

    const [vaccineDateValidLabel] = useState({
        label: t("Vaccine expiration date"),
        placeholder: t("Choose a date"),
        description: t("If you do not know the date, it is usually +2 years")
    });

    const [vaccineFilesValidLabel] = useState({
        label: t("You can attach files"),
        accept: "image/png,image/jpeg",
        description: t("Maximum number of files - 5"),
        placeholder: t("Select files. For now, png/jpeg files are accepted")
    });

    const [vaccineTypeSelect] = useState<{ label: string; placeholder: string; data: { value: string; label: string; }[] }>({
        label: t("Vaccine type"),
        placeholder: t("Select the type of vaccine"),
        data: [
            { value: "rabies", label: vaccineTypeHelper("rabies")},
            { value: "parvovirus", label: vaccineTypeHelper("parvovirus") },
            { value: "other", label: vaccineTypeHelper("other") },
        ]
    });

    const actionButtonTitle = t("Create");
    const [actionButtonState] = useState<"success" | "blank">("blank");

    const [isActionButtonEnabled, setIsActionButtonEnabled] = useState(false);

    const vaccinationService = new VaccinationService();
    const fileService = new FileService();

    function changedValue(value: any, prop: VaccinePole) {
        setData(
            { ...data, [prop]:value }
        );
    };

    function notificateError(message: string) {
        showNotification({
            title: t("Incorrect data"),
            message: message,
            autoClose: 5000,
            color: "red"
        });
    };

    function notificateCreateSuccess() {
        showNotification({
            title: t("Success!"),
            message: t("The vaccine was successfully created"),
            autoClose: 5000,
            color: "green"
        });
    }

    function notificateErrorCreation() {
        showNotification({
            title: t("Error("),
            message: t("Vaccine creation error, try again later, please"),
            autoClose: 5000,
            color: "red"
        });
    }

    function buttonTapped() {
        setIsActionButtonEnabled(true);

        if (!data) { return; }

        if (!data.vaccineName || data.vaccineName.length === 0) { 
            notificateError(t("You did not fill in the name of the vaccine"));
            setIsActionButtonEnabled(false);
            return;
        }

        if (!data.vaccineNumber || data.vaccineNumber.length === 0) { 
            notificateError(t("You did not fill in the vaccine serial number"));
            setIsActionButtonEnabled(false);
            return;
        }  

        if (!data.vaccineDate) {
            notificateError(t("You did not fill in the vaccination date"));
            setIsActionButtonEnabled(false);
            return;
        }

        if (!data.vaccineValidDate) {
            notificateError(t("You did not fill in the expiration date of the vaccine"));
            setIsActionButtonEnabled(false);
            return;
        } 

        if (!data.revaccinationDate) {
            notificateError(t("You did not fill in the revaccination date"));
            setIsActionButtonEnabled(false);
            return;
        }

        const dto: VaccinationCreateAPIDTO = {
            petID: infoModel.petID,
            serial: data.vaccineNumber,
            name: data.vaccineName,
            takeover: data.vaccineDate.toISOString(),
            repeatVaccinationTakeover: data.revaccinationDate?.toISOString(),
            expiration: data.vaccineValidDate?.toISOString(),
            type: data.vaccineType,        
        };

        vaccinationService.create(dto)
        .catch((error) => { 
            notificateErrorCreation();
            console.log(error);
            setIsActionButtonEnabled(false);
        })
        .then((result) => {
            console.log(result);

            if (!result || !result.data) { return; }

            if (files.length > 0) {
                fileService.uploadMedicalFiles(result.data.id, MedicalDocumentTypeDTO.vaccination, files)
                .catch(error => {
                    console.log(error);
                })
                .then(result => {
                    if (!result) { return }
        
                    if (result.status == 200) {
                        close();
                    }
                });        
            } else {
                close();
            }
        });
    }

    function close() {
        notificateCreateSuccess();
        setIsActionButtonEnabled(false);
        infoModel.moduleDidCloseAfterSuccess();
    }

    useEffect(() => {
    }, []);

    const returnValue: VaccineCardCreateViewModelOutput = {
        isActionButtonEnabled: isActionButtonEnabled,
        data: data,
        vaccineNameLabel: vaccineNameLabel,
        vaccineNumberLabel: vaccineNumberLabel,
        vaccineDateLabel: vaccineDateLabel,
        reVaccineDateLabel: revaccineDateLabel,
        vaccineDateValidLabel: vaccineDateValidLabel,
        vaccineFilesValidLabel: vaccineFilesValidLabel,
        vaccineTypeSelect: vaccineTypeSelect,
        actionButtonTitle: actionButtonTitle,
        actionButtonState: actionButtonState,
        changedValue: changedValue,
        filesChanged: setFiles,
        buttonTapped: buttonTapped
    }

    return returnValue;
}