import { RootNavigationDataSource } from './DataSource';

export function matrixRootPath(path: string) {
    // if (path.includes(RootNavigationDataSource.dashboardRoot.link)) {
    //     return RootNavigationDataSource.dashboardRoot.name
    // }
    if (path.includes(RootNavigationDataSource.petsRoot.link)) {
        return RootNavigationDataSource.petsRoot.name
    }
    if (path.includes(RootNavigationDataSource.mapRoot.link)) {
        return RootNavigationDataSource.mapRoot.name
    }
    // if (path.includes(RootNavigationDataSource.ammunitionRoot.link)) {
    //     return RootNavigationDataSource.ammunitionRoot.name
    // }
    if (path.includes(RootNavigationDataSource.profileRoot.link)) {
        return RootNavigationDataSource.profileRoot.name
    }
    return "";
}