import axios from "axios";
import { BaseURL } from "../BasicURLScheme";
import { DocumentLiteDTO } from "./VaccinationService";

export class NotesService {
    private baseURL: BaseURL = BaseURL.shared;
    private module: string = "/api/modern/note"

    public async fetchList(petID: string) {
        const token = this.baseURL.obtainHeader();
        return axios.get<Array<NotesAPIDTO>>(
            this.baseURL.buildEndpoin(`${this.module}/pet/${petID}`),
            {
                headers: {
                    ...token
                }
            }
        );
    }

    public async fetchDetails(id: string) {
        const token = this.baseURL.obtainHeader();
        return axios.get<NotesAPIDTO>(
            this.baseURL.buildEndpoin(`${this.module}/${id}`),
            {
                headers: {
                    ...token
                }
            }
        );
    }

    public async create(dto: NotesCreateAPIDTO) {
        const token = this.baseURL.obtainHeader();

        return axios({
            method: "POST",
            url: this.baseURL.buildEndpoin(`${this.module}/`),
            headers: { ...token },
            data: { ...dto }
        });
    }

    public async update(dto: NotesUpdateAPIDTO) {
        const token = this.baseURL.obtainHeader();

        return axios({
            method: "PUT",
            url: this.baseURL.buildEndpoin(`${this.module}/`),
            headers: { ...token },
            data: { ...dto }
        });
    }

    public async delete(id: string) {
        const token = this.baseURL.obtainHeader();

        return axios.delete(
            this.baseURL.buildEndpoin(`${this.module}/${id}`),
            {
                headers: {
                    ...token
                }
            }
        );
    }
}

export interface NotesCreateAPIDTO {
    petID: string;
    note: string;
}

export interface NotesUpdateAPIDTO {
    id: string;
    note?: string;
}

export interface NotesAPIDTO {
    id: string;
    note: string;
    created: string;
    documentDTOs: DocumentLiteDTO[];
    isEditable: Boolean;
}