export function dateToHumanString(dateString?: string): string | undefined {
    if (!dateString) {
        return undefined;
    }

    const date = new Date(dateString);

    if (typeof date === 'object' && date !== null && 'toISOString' in date) {
        return date.toLocaleDateString(undefined, { year: "numeric", month: "numeric", day: "numeric" }).replaceAll("/", ".");
    }

    return undefined;
}

export function stringToDate(dateString?: string): Date | undefined {
    if (!dateString) {
        return undefined;
    }

    const date = new Date(dateString);

    return date;
}