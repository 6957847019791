import useWindowDimensions from "./useWindowDimensions";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navbar, Group, Code, Image, Space } from '@mantine/core';
import { useStyles } from './Styles'
import {
  IconBellRinging,
  IconFingerprint,
  IconKey,
  IconReceipt2,
  IconSwitchHorizontal,
  IconLogout,
} from '@tabler/icons-react';

import care_logo from '../../Resources/Assets/web_logo.svg';
import { RootNavigationDataSource } from './DataSource'

import { useLocation, Link } from 'react-router-dom';
import { matrixRootPath } from './matrixRootPath';
import { BaseURL } from "../../Domain/BasicURLScheme";
import { LanguagePicker } from "../../Core/CLanguageSelect/CLanguageSelect";

interface RootNavigationBarViewProps {
  opened: boolean;
  _opened: (_: boolean) => void;
}

export function RootNavigationBarView({ opened, _opened }: RootNavigationBarViewProps) {
    const { t } = useTranslation();
  
    const data = [
      // { link: RootNavigationDataSource.dashboardRoot.link, label: t(RootNavigationDataSource.dashboardRoot.name), icon: IconBellRinging },
      { link: RootNavigationDataSource.petsRoot.link, label: t(RootNavigationDataSource.petsRoot.name), icon: IconReceipt2 },
      { link: RootNavigationDataSource.mapRoot.link, label: t(RootNavigationDataSource.mapRoot.name), icon: IconFingerprint },
      // { link: RootNavigationDataSource.ammunitionRoot.link, label: t(RootNavigationDataSource.ammunitionRoot.name), icon: IconKey },
      { link: RootNavigationDataSource.profileRoot.link, label: t(RootNavigationDataSource.profileRoot.name), icon: IconKey },
    ];
  
    const { classes, cx } = useStyles();
    const { height } = useWindowDimensions();
    const location = useLocation();
  
    const [active, setActive] = useState(t(matrixRootPath(location.pathname)));
  
    const links = data.map((item) => (
      <Link
        className={cx(classes.link, { [classes.linkActive]: item.label === active })}
        to={item.link}
        key={item.label}
        onClick={() => {
            setActive(item.label);
            _opened(false);
        }}
      >
        <item.icon className={classes.linkIcon} stroke={2} />
        <span>{item.label}</span>
      </Link>
    ));
  
    return (
      <Navbar p="md" hiddenBreakpoint="sm" hidden={!opened} height="100%" width={{ sm: 300 }}>
        <Navbar.Section grow>
          <Group className={classes.header} position="apart">
            <Image 
              src={care_logo}
              width={70}
            />
            <Code sx={{ fontWeight: 700 }}>v1.0.0</Code>
          </Group>
          {links}
        </Navbar.Section>
  
        <Navbar.Section className={classes.footer}>
          {/* <a href="/contacts" className={classes.link} onClick={(event) => event.preventDefault()}>
            <IconSwitchHorizontal className={classes.linkIcon} stroke={1.5} />
            <span>{t("Contacts")}</span>
          </a> */}
          <a href="/logout" className={classes.link} onClick={(event) => {
              event.preventDefault();
              BaseURL.shared.logout();
            }}>
            <IconLogout className={classes.linkIcon} stroke={1.5} />
            <span>{t("Logout")}</span>
          </a>
          <Space h={12} />
          <Group position="left"><LanguagePicker /></Group>
        </Navbar.Section>
      </Navbar>
    );
  }