import { Group, Text } from "@mantine/core";

interface CTitleWithSubtitleViewInfoModel {
    title: string;
    subtitle: string;
};

export function CTitleWithSubtitleView(model: CTitleWithSubtitleViewInfoModel) {
    return (
        <Group position="apart" noWrap>
            <Text weight={800} size="md" color="dimmed">{model.subtitle}</Text>
            <Text weight={800} lineClamp={1} size="md">{model.title}</Text>
        </Group>
    )
};