import { Container, Stack, Text, Button, Group, Textarea } from "@mantine/core";
import { useState } from "react";
import { notifications } from "@mantine/notifications";
import { CareFileInputView } from "../../../Core/FileInputComponent/CareFileInputView";
import { useTranslation } from "react-i18next";
import { NotesCreateAPIDTO, NotesService } from "../../../Domain/Medical/NotesService";
import { FileService, MedicalDocumentTypeDTO } from "../../../Domain/FileService/FileService";

export interface NotesCardCreateInterface {
    petID: string;
    moduleDidCloseAfterSuccess: () => void;
}

export function NotesCardCreateViewModel(infoModel: NotesCardCreateInterface) {
    type NotesPole = "noteText";

    interface NotesFillDataInterface {
        noteText?: string;
    };

    const { t } = useTranslation();
    const [data, setData] = useState<NotesFillDataInterface>({});
    const [files, setFiles] = useState<File[]>([]);
    const [isActionButtonEnabled, setIsActionButtonEnabled] = useState(false);
    const actionButtonTitle = t("Create");
    const noteService = new NotesService();
    const fileService = new FileService();

    const [noteName] = useState({
        label: t("Note text"),
        placeholder: t("Any arbitrary text")
    });

    const [vaccineFilesValidLabel] = useState({
        label: t("You can attach files"),
        accept: "image/png,image/jpeg",
        description: t("Maximum number of files - 5"),
        placeholder: t("Select files. For now, png/jpeg files are accepted")
    });

    function changedValue(value: any, prop: NotesPole) {
        setData(
            { ...data, [prop]:value }
        );
    };

    function buttonTapped() {
        setIsActionButtonEnabled(true);

        if (!data) { return; }

        if (!data.noteText || data.noteText.length === 0) {
            notificateError(t("You did not fill in the text of the note"));
            setIsActionButtonEnabled(false);
            return;
        }
        
        const dto: NotesCreateAPIDTO = {
            petID: infoModel.petID,
            note: data.noteText
        }

        noteService.create(dto)
        .catch((error) => { 
            notificateErrorCreation();
            console.log(error);
            setIsActionButtonEnabled(false);
        })
        .then((result) => {
            console.log(result);

            if (!result || !result.data) { return; }

            if (files.length > 0) {
                fileService.uploadMedicalFiles(result.data.id, MedicalDocumentTypeDTO.notes, files)
                .catch(error => {
                    console.log(error);
                })
                .then(result => {
                    if (!result) { return }
        
                    if (result.status == 200) {
                        close();
                    }
                });        
            } else {
                close();
            }
        });
    };

    function close() {
        notificateCreateSuccess();
        setIsActionButtonEnabled(false);
        infoModel.moduleDidCloseAfterSuccess();
    }

    function notificateError(message: string) {
        notifications.show({
            title: t("Incorrect data"),
            message: message,
            autoClose: 5000,
            color: "red"  
        })
    };

    function notificateCreateSuccess() {
        notifications.show({
            title: t("Success!"),
            message: t("The note was successfully created"),
            autoClose: 5000,
            color: "green"
        });
    };

    function notificateErrorCreation() {
        notifications.show({
            title: t("Error("),
            message: t("Note creation error, try again later, please"),
            autoClose: 5000,
            color: "red"
        });
    };

    return({
        data,
        noteName,
        vaccineFilesValidLabel,
        isActionButtonEnabled,
        actionButtonTitle,
        changedValue,
        setFiles,
        buttonTapped
    })
}

export function NotesCardCreateView(infoModel: NotesCardCreateInterface) {
    const viewModel = NotesCardCreateViewModel(infoModel);

    const mainView = (
        <>
            <Stack>
                <Stack spacing={6}>
                    <Text component="label" htmlFor="nameDrug" size="sm" weight={500}>
                        { viewModel.noteName.label }
                    </Text>
                    <Textarea
                        value={viewModel.data.noteText ?? ""}
                        onChange={e => { viewModel.changedValue(e.target.value.toString(), "noteText")}}
                        placeholder={ viewModel.noteName.placeholder }
                        size="sm"
                        radius="md"
                        autosize
                        minRows={2}
                        maxRows={5}
                    />
                <Stack spacing={6}>
                    <Text component="label" htmlFor="revaccinationDate" size="sm" weight={500}>
                        { viewModel.vaccineFilesValidLabel.label }
                    </Text>
                    <CareFileInputView
                        description={viewModel.vaccineFilesValidLabel.description}
                        placeholder={viewModel.vaccineFilesValidLabel.placeholder}
                        accept={viewModel.vaccineFilesValidLabel.accept}
                        filesDidChange={viewModel.setFiles}
                    />
                </Stack>
                </Stack>
                <Button 
                    radius="md" 
                    onClick={() => { viewModel.buttonTapped() }}
                    loading={viewModel.isActionButtonEnabled}
                >
                    {viewModel.actionButtonTitle}
                </Button>
            </Stack>
        </>
    );

    return (
        <Container p={0}>
            { mainView }
        </Container>
    );
}