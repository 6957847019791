import { Button, Text, Container, createStyles, Divider, Group, Paper, rem, Space, Center, Loader, Stack, Table, ActionIcon, ScrollArea, Transition } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { CHoverShadow } from "../../Core/CHoverShadow/CHoverShadow";
import { MedicalType } from "../../Domain/Ambulance/AmbulanceService";
import { useMediaQuery } from "@mantine/hooks";
import { IconCircleArrowRightFilled, IconCirclePlus } from "@tabler/icons-react";

import { CMedicalRepresentation, CMedicalTableDesktopData, CMedicalTableInteractor, CMedicalTableMobileData, CMedicalTableMobileDataItemModel } from "./CMedicalListInteractor";
import { CTabsListView } from "../../Core/CTabsListView/CTabsListView";
import { CardGradient } from "./CardGradient";
import { DataObservable, Observable } from "../../Core/DataObservable";
import { broofa } from "../../Resources/Helpers/uuidv4";
import { useTranslation } from "react-i18next";

interface CMedicineListViewModelDataBuilder {
    petID: string;
    addTapped: (tab: CMedicineListType) => void;
    detailsTapped: (medicineID: string, tab: CMedicineListType) => void;
}

export type CMedicineListType = MedicalType | "mandatory_vaccination" | "other_mandatory_vaccination" | "other_vaccination";
export type CMedicineRespounceType = "add" | "details";

const cmedicineListViewModelObserverID = broofa();

export function CMedicineListViewModel(builder: CMedicineListViewModelDataBuilder) {
    type ViewState = "loading" | "data" | "error" | "empty";
    type CMedicineListItemModel = {value: CMedicineListType; title: string};

    const { t } = useTranslation();

    const tabs: Array<CMedicineListItemModel> = [
        { value: "mandatory_vaccination", title: t("General vaccination") },
        { value: "other_mandatory_vaccination", title: t("General other vaccination") },
        { value: "other_vaccination", title: t("Other vaccination") },
        { value: "deworming", title: t("Deworming") },
        { value: "ectoparasites", title: t("Ectoparasites") },
        { value: "test", title: t("Tests") },
        { value: "examination", title: t("Vet. checks") },
        { value: "notes", title: t("Notes") }
    ];
    
    const isDesktop = useMediaQuery('(min-width: 61.85em)');

    useEffect(() => {
        obtainData(selected);
    }, [isDesktop]);

    const emptyContent = {
        title: t("You haven't entered the medical information in this section yet"),
        subtitle: t("If you want to create an entry, click on the button below")
    };

    const errorContent = {
        title: t("An error occurred while receiving data"),
        subtitle: t("Please try again later")
    };

    const interactor = CMedicalTableInteractor({petID: builder.petID});

    const [selected, _selected] = useState<CMedicineListItemModel>(tabs[0]);
    const [content, _content] = useState<CMedicalTableDesktopData | CMedicalTableMobileData | undefined>()
    const [viewState, _viewState] = useState<ViewState>("loading");
    function _selectedSetter(index: number) {
        _selected(tabs[index]);
        obtainData(tabs[index]);
    };

    function handleError(error: Error) {
        _content(undefined);
        _viewState("error");
    };

    function handleResult(result: CMedicalTableDesktopData | CMedicalTableMobileData) {
        _content(result);
        if (result.isEmpty) {
            _viewState("empty");
        } else {
            _viewState("data");
        }
    }

    function addTapped() {
        DataObservable.getInstance().addObserver({
            observableID: cmedicineListViewModelObserverID,
            type: "medicalTable",
            action: (data) => { reload() }
        })
        builder.addTapped(selected.value);
    };

    function detailsTapped(medicineID: string) {
        builder.detailsTapped(medicineID, selected.value);
    };

    function obtainData(selectedData: CMedicineListItemModel) {
        if (isDesktop == undefined) { return };

        _viewState("loading");

        _content(undefined);
        const representation: CMedicalRepresentation = isDesktop ? "desktop" : "mobile"
        switch (selectedData.value) {
            case "mandatory_vaccination":
                interactor.obtainVaccine("rabies", representation)
                    .then((result) => handleResult(result))
                    .catch(error => handleError(error))
                break;

            case "other_mandatory_vaccination":
                interactor.obtainVaccine("parvovirus", representation)
                    .then((result) => handleResult(result))
                    .catch(error => handleError(error))
                break;

            case "other_vaccination":
                interactor.obtainVaccine("other", representation)
                    .then((result) => handleResult(result))
                    .catch(error => handleError(error))
                break;

            case "deworming":
                interactor.obtainDeworming(representation)
                    .then((result) => handleResult(result))
                    .catch(error => handleError(error))
                break;

            case "ectoparasites":
                interactor.obtainEctoparasites(representation)
                    .then((result) => handleResult(result))
                    .catch(error => handleError(error))
                break;

            case "test":
                interactor.obtainTests(representation)
                    .then((result) => handleResult(result))
                    .catch(error => handleError(error))
                break;

            case "examination":
                interactor.obtainExamination(representation)
                    .then((result) => handleResult(result))
                    .catch(error => handleError(error))
                break;

            case "notes":
                interactor.obtainNotes(representation)
                    .then((result) => handleResult(result))
                    .catch(error => handleError(error))
                break;
        }
    };

    function reload() {
        obtainData(selected);
    };

    return ({
        tabs,
        viewState,
        emptyContent,
        errorContent,
        content,
        _selectedSetter,
        addTapped,
        detailsTapped,
        reload
    })
}

interface CMedicineListViewBulder {
    dataBuilder: CMedicineListViewModelDataBuilder;
    height: number;
    maxheight: number;
    minheight: number;
}

interface CMedicineListErrorViewBuilder {
    reload: () => void; errorContent: {title: string; subtitle: string}
}
const CMedicineListErrorView = (builder: CMedicineListErrorViewBuilder) => {
    const { t } = useTranslation();

    const { classes } = createStyles((theme) => ({
        card: {
            backgroundImage: theme.colorScheme === 'dark' ? 
                theme.fn.linearGradient(323, "rgba(2,0,36,1) 0%", "rgba(45,132,149,1) 100%") :
                theme.colors.gray[0],
            ...CHoverShadow(theme)
        },

        placeholderSubtitle: {
            lineHeight: 1,
            fontWeight: 800,
            fontSize: rem(24),
            [theme.fn.smallerThan("xs")]: {
                fontSize: rem(16),
            },
        },

        placeholderTitle: {
            lineHeight: 1,
            fontWeight: 800,
            fontSize: rem(28),
            [theme.fn.smallerThan("xs")]: {
                fontSize: rem(20),
            },
        },
    }))();

    const [mount, _mount] = useState(false);
    useEffect(() => {
        _mount(true);
    }, [])

    return (
        <Transition mounted={mount} transition="fade" duration={400} timingFunction="ease">
                    {(styles) => <div style={{height: "100%", ...styles}}>
                    <Stack spacing={8} style={{borderRadius: 20}} p={20} justify={"center"} align="flex-start" mt={10} w={"70%"} h={"100%"}>
                        <Text className={classes.placeholderTitle}>{builder.errorContent.title}</Text>
                        <Text className={classes.placeholderSubtitle} color="gray">{builder.errorContent.subtitle}</Text>
                        <Space h={6} />
                        <Button color="dark" onClick={builder.reload} radius="md">{t("Repeat")}</Button>
                    </Stack>
                    </div>}
                </Transition>
    )
};

interface CMedicineListEmptyViewBuilder {
    addTapped: () => void; content: {title: string; subtitle: string}
}
const CMedicineListEmptyView = (builder: CMedicineListEmptyViewBuilder) => {
    const { t } = useTranslation();

    const { classes } = createStyles((theme) => ({
        card: {
            backgroundImage: theme.colorScheme === 'dark' ? 
                theme.fn.linearGradient(323, "rgba(2,0,36,1) 0%", "rgba(45,132,149,1) 100%") :
                theme.colors.gray[0],
            ...CHoverShadow(theme)
        },

        placeholderSubtitle: {
            lineHeight: 1,
            fontWeight: 800,
            fontSize: rem(24),
            [theme.fn.smallerThan("xs")]: {
                fontSize: rem(16),
            },
        },

        placeholderTitle: {
            lineHeight: 1,
            fontWeight: 800,
            fontSize: rem(28),
            [theme.fn.smallerThan("xs")]: {
                fontSize: rem(20),
            },
        },
    }))();

    const [mount, _mount] = useState(false);
    useEffect(() => {
        _mount(true);
    }, [])

    return (
        <Transition mounted={mount} transition="fade" duration={400} timingFunction="ease">
                    {(styles) => <div style={{height: "100%", ...styles}}>
                    <Stack spacing={8} style={{borderRadius: 20}} p={20} justify={"center"} align="flex-start" mt={10} w={"70%"} h={"100%"}>
                    <Text className={classes.placeholderTitle}>{builder.content.title}</Text>
                    <Text className={classes.placeholderSubtitle} color="gray">{builder.content.subtitle}</Text>
                    <Space h={6} />
                <Button color="dark" onClick={builder.addTapped} radius="md">{t("Create")}</Button>
                </Stack>
                    </div>}
                </Transition>
    )
};

const CMedicineListLoadingView = () => {
    const [mount, _mount] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        _mount(true);
    }, []);

    return <Transition mounted={mount} transition="fade" duration={400} timingFunction="ease">
                {(styles) => <div style={{height: "100%", ...styles}}>
                <Center sx={(theme) => ({
            height: "100%",
            [theme.fn.smallerThan("sm")]: {
                height: 220
            },
        })}>
            <Loader size={28} color="dark"></Loader>
        </Center>
            </div>}
            </Transition>
};

export function CMedicineListView(builder: CMedicineListViewBulder) {
    const viewModel = CMedicineListViewModel(builder.dataBuilder);

    const { classes } = createStyles((theme) => ({
        card: {
            backgroundImage: theme.colorScheme === 'dark' ? 
                theme.fn.linearGradient(323, "rgba(2,0,36,1) 0%", "rgba(45,132,149,1) 100%") :
                theme.colors.gray[0],
            ...CHoverShadow(theme)
        },

        placeholderSubtitle: {
            lineHeight: 1,
            fontWeight: 800,
            fontSize: rem(24),
            [theme.fn.smallerThan("xs")]: {
                fontSize: rem(16),
            },
        },

        placeholderTitle: {
            lineHeight: 1,
            fontWeight: 800,
            fontSize: rem(28),
            [theme.fn.smallerThan("xs")]: {
                fontSize: rem(20),
            },
        },
    }))();

    const tabsSection = (
        <Container p={0} fluid>
            <CTabsListView
                values={viewModel.tabs}
                didSelect={(index) => viewModel._selectedSetter(index)}
                addTapped={viewModel.addTapped}
            />
            <Divider />
        </Container>
    );

    const dataStateView = () => {
        if (!viewModel.content) {
            return <CMedicineListEmptyView addTapped={viewModel.addTapped} content={viewModel.emptyContent}/>
        };

        if (viewModel.content.representation === "mobile") {
            return (
                <>
                    <Space h="md" />
                    <Stack>
                        <CTableCompactRepresentation detailsTapped={viewModel.detailsTapped} data={viewModel.content as CMedicalTableMobileData}/>
                    </Stack>
                </>
            )
        }
        if (viewModel.content.representation === "desktop") {
            return (
                <ScrollArea h={"95%"}>
                    <CTableRegularRepresentation detailsTapped={viewModel.detailsTapped} addTapped={viewModel.addTapped} tableData={viewModel.content as CMedicalTableDesktopData}/>
                </ScrollArea>
            )
        }
        return <></>
    };

    function viewFromState() {
        switch (viewModel.viewState) {
            case "data":
                return dataStateView();

            case "loading":
                return <CMedicineListLoadingView />;

            case "empty":
                return <CMedicineListEmptyView addTapped={viewModel.addTapped} content={viewModel.emptyContent}/>

            case "error":
                return <CMedicineListErrorView reload={viewModel.reload} errorContent={viewModel.errorContent} />
        }
    };

    return (
        <Paper
            sx={(theme) => ({
                height: 500,
                [theme.fn.smallerThan("sm")]: {
                    height: "auto",
                    minHeight: 220
                },
            })}
            className={classes.card}
            p="lg"
            radius="lg"
            withBorder
        >
            { tabsSection }
            { viewFromState() }
        </Paper>
    );
};

interface CTableRepresentationBuilder {
    tableData: CMedicalTableDesktopData;
    addTapped: () => void;
    detailsTapped: (medicalID: string) => void;
}

export function CTableRegularRepresentation(builder: CTableRepresentationBuilder) {
    const items = builder.tableData.columns.map((element) => (
        <th style={{ whiteSpace: "nowrap" }}>{element.title}</th>
    ));

    const rows = builder.tableData.rows.map((item) => (
        <tr key={item.medicineID}>
            { item.rows.map((row) => (
                <td style={{
                    fontWeight: 700
                }}>{row.value.slice(0, 255)}{ row.value.length > 256 ? "..." : ""}</td>
            )) }
            <td>
                <Group position="right">
                    <ActionIcon color="dark" onClick={() => {builder.detailsTapped(item.medicineID)}}>
                        <IconCircleArrowRightFilled color="dark" size={26} />
                    </ActionIcon>
                </Group>
            </td>
        </tr>
    ));

    const [mount, _mount] = useState(false);

    useEffect(() => {
        _mount(true);
    }, []);

    return <Transition mounted={mount} transition="fade" duration={400} timingFunction="ease">
                    {(styles) => <div style={{height: "100%", ...styles}}>
                    <Table highlightOnHover verticalSpacing="md">
                        <thead>
                        <tr>
                            { items }
                        <th>
                        <Group position="right">
                            <ActionIcon onClick={builder.addTapped} color="dark">
                                <IconCirclePlus size={26} />
                            </ActionIcon>
                        </Group>
                        </th>
                        </tr>
                        </thead>
                            <tbody>{rows}</tbody>
                    </Table>
                    </div>}
                </Transition>
};

interface CTableCompactRepresentationBuilder {
    data: CMedicalTableMobileData;
    detailsTapped: (medicalID: string) => void;
}

export function CTableCompactRepresentation(builder: CTableCompactRepresentationBuilder) {
    const { t } = useTranslation();
    
    const items = builder.data.items.map((item) => (
        <CTableCompactRepresentationItemView tapped={builder.detailsTapped} data={item}/>
    ));
    
    const button = () => {
        if (items.length > 2) {
            return <Button onClick={() => { _short(!short) }} hidden={true} radius="md" fullWidth variant="subtle" color="dark">{ short ? t("Show more") : t("Hide more") }</Button>
        } else {
            return <></>
        }
    }

    const [short, _short] = useState(true);
    const [mount, _mount] = useState(false);
    useEffect(() => {
        _mount(true);
    }, []);

    return (
        <Transition mounted={mount} transition="fade" duration={400} timingFunction="ease">
                    {(styles) => <div style={{height: "100%", ...styles}}>
                    <Stack spacing={8} mah={short ? 128 * 3 + 16 * 3 : "unset"}>{short ? items.slice(0, 3) : items}</Stack>
                    <Space h={12} />
                    {button()}
                </div>}
            </Transition>  
    )
}

interface CTableCompactRepresentationItemBuilder {
    data: CMedicalTableMobileDataItemModel;
    tapped: (medicalID: string) => void;
}
export function CTableCompactRepresentationItemView(builder: CTableCompactRepresentationItemBuilder) {
    
    return (
        <CardGradient onClick={ builder.tapped } title={builder.data.title} infoModel={{ type: "", medicalID: builder.data.medicalID, content: builder.data.content }}/>
    );
};