import i18next from "i18next";

export enum Kind {
    Dog = "dog",
    Cat = "cat",
}

export function makeKindEnum(rawValue: string | undefined) {
    if (Kind.Dog == rawValue) {
        return Kind.Dog
    }
    if (Kind.Cat == rawValue) {
        return Kind.Cat
    }

    return undefined;
}

export function obtainKindReadble(kind: Kind | undefined) {
    switch (kind) {
        case Kind.Dog:
            return i18next.t("Dog");

        case Kind.Cat:
            return i18next.t("Cat");
    }

    return undefined;
}