import { Container, TextInput, Stack, Text, Button, NumberInput, Group } from "@mantine/core";
import { useState } from "react";
import { DateLargePickerUTC } from "../../../Core/DateLargePickerUTC";
import { notifications } from "@mantine/notifications";
import { CareFileInputView } from "../../../Core/FileInputComponent/CareFileInputView";
import { useTranslation } from "react-i18next";
import { EctoparasitesCreateAPIDTO, EctoparasitesService } from "../../../Domain/Medical/EctoparasitesService";
import { FileService, MedicalDocumentTypeDTO } from "../../../Domain/FileService/FileService";

export interface EctoparasitiesCardCreateInterface {
    petID: string;
    moduleDidCloseAfterSuccess: () => void;
}

interface EctoparasitiesFillDataInterface {
    nameDrug?: string;
    dateTake?: Date;
    weekValidCount?: number;
}

type EctoparasitiesPole = "nameDrug" | "dateTake" | "weekValidCount";

export function EctoparasitiesCardViewModel(infoModel: EctoparasitiesCardCreateInterface) {
    const [data, setData] = useState<EctoparasitiesFillDataInterface>({});
    const { t } = useTranslation();

    const [isActionButtonEnabled, setIsActionButtonEnabled] = useState(false);
    const actionButtonTitle = t("Create");
    const [files, setFiles] = useState<File[]>([]);

    const ectoparasitesService = new EctoparasitesService();
    const fileService = new FileService();

    const [drugName] = useState({
        label: t("Name of drug"),
        placeholder: "Bravekto"
    });

    const [drugDate] = useState({
        label: t("Date of taking"),
        placeholder: t("Choose a date")
    });

    const [dosage] = useState({
        label: t("How many weeks is it valid?"),
        placeholder: "12"
    });

    const [vaccineFilesValidLabel] = useState({
        label: t("You can attach files"),
        accept: "image/png,image/jpeg",
        description: t("Maximum number of files - 5"),
        placeholder: t("Select files. For now, png/jpeg files are accepted")
    });

    function changedValue(value: any, prop: EctoparasitiesPole) {
        setData(
            { ...data, [prop]:value }
        );
    };

    function buttonTapped() {
        setIsActionButtonEnabled(true);

        if (!data) { return; }

        if (!data.nameDrug || data.nameDrug.length === 0) {
            notificateError(t("You did not fill in the name of the drug"));
            setIsActionButtonEnabled(false);
            return;
        }
        if (!data.weekValidCount || data.weekValidCount <= 0) {
            notificateError(t("You did not fill in the expiration date"));
            setIsActionButtonEnabled(false);
            return;
        }
        if (!data.dateTake) {
            notificateError(t("You did not fill in the date you took the medication"));
            setIsActionButtonEnabled(false);
            return;
        }

        const dto: EctoparasitesCreateAPIDTO = {
            petID: infoModel.petID,
            name: data.nameDrug,
            validWeeks: data.weekValidCount,
            takeover: data.dateTake.toISOString()
        };

        ectoparasitesService.create(dto)
        .catch((error) => { 
            notificateErrorCreation();
            console.log(error);
            setIsActionButtonEnabled(false);
        })
        .then((result) => {
            console.log(result);

            if (!result || !result.data) { return; }

            if (files.length > 0) {
                fileService.uploadMedicalFiles(result.data.id, MedicalDocumentTypeDTO.ectoparasites, files)
                .catch(error => {
                    console.log(error);
                })
                .then(result => {
                    if (!result) { return }
        
                    if (result.status == 200) {
                        close();
                    }
                });        
            } else {
                close();
            }
        });
    };

    function close() {
        notificateCreateSuccess();
        setIsActionButtonEnabled(false);
        infoModel.moduleDidCloseAfterSuccess();
    };

    function notificateError(message: string) {
        notifications.show({
            title: t("Incorrect data"),
            message: message,
            autoClose: 5000,
            color: "red"  
        })
    };

    function notificateCreateSuccess() {
        notifications.show({
            title: t("Success!"),
            message: t("Ectoparasite treatment successfully established"),
            autoClose: 5000,
            color: "green"
        });
    };

    function notificateErrorCreation() {
        notifications.show({
            title: t("Error("),
            message: t("Ectoparasite treatment creation error, try again later, please"),
            autoClose: 5000,
            color: "red"
        });
    };

    return({
        data,
        drugName,
        drugDate,
        dosage,
        isActionButtonEnabled,
        actionButtonTitle,
        vaccineFilesValidLabel,
        changedValue,
        buttonTapped,
        setFiles
    });
}

export function EctoparasitiesCardCreateView(infoModel: EctoparasitiesCardCreateInterface) {
    const viewModel = EctoparasitiesCardViewModel(infoModel);

    const mainView = (
        <>
            <Stack>
                <Stack spacing={6}>
                    <Text component="label" htmlFor="nameDrug" size="sm" weight={500}>
                        { viewModel.drugName.label }
                    </Text>
                    <TextInput
                        value={viewModel.data.nameDrug ?? ""}
                        onChange={e => { viewModel.changedValue(e.target.value.toString(), "nameDrug")}}
                        placeholder={ viewModel.drugName.placeholder }
                        size="sm"
                        radius="md"
                    />
                    <Text component="label" htmlFor="takeDate" size="sm" weight={500}>
                        { viewModel.drugDate.label }
                    </Text>
                    <Group position="center" align="center">
                    <DateLargePickerUTC
                        value={viewModel.data.dateTake}
                        onChange={e => { viewModel.changedValue(e, "dateTake")} }
                        size="sm"
                        placeholder={viewModel.drugDate.placeholder}
                    />
                    </Group>
                    <Text component="label" htmlFor="dosage" size="sm" weight={500}>
                        { viewModel.dosage.label }
                    </Text>
                    <NumberInput
                        value={viewModel.data.weekValidCount ?? ""}
                        onChange={e => { viewModel.changedValue(e, "weekValidCount")}}
                        placeholder={ viewModel.dosage.placeholder }
                        min={1}
                        size="sm"
                        radius="md"
                    />
                    <Stack spacing={6}>
                    <Text component="label" htmlFor="revaccinationDate" size="sm" weight={500}>
                        { viewModel.vaccineFilesValidLabel.label }
                    </Text>
                    <CareFileInputView
                        description={viewModel.vaccineFilesValidLabel.description}
                        placeholder={viewModel.vaccineFilesValidLabel.placeholder}
                        accept={viewModel.vaccineFilesValidLabel.accept}
                        filesDidChange={viewModel.setFiles}
                    />
                </Stack>
                </Stack>
                <Button 
                    radius="md" 
                    onClick={() => { viewModel.buttonTapped() }}
                    loading={viewModel.isActionButtonEnabled}
                >
                    {viewModel.actionButtonTitle}
                </Button>
            </Stack>
        </>
    );

    return (
        <Container p={0}>
            { mainView }
        </Container>
    );
};