import { createStyles, Avatar, Text, Group, Title, Container, Space, Loader, ActionIcon, Transition } from '@mantine/core';
import { IconArrowBigRightLines } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { FileService } from '../../Domain/FileService/FileService';
import  square from "../../Resources/Assets/square.svg";
import { useTranslation } from 'react-i18next';

const useStyles = createStyles((theme) => ({
  icon: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[5],
  },

  stack: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
  },

  name: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  titles: {
    marginTop: 0,
    marginBottom: 0,
  },

  group: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2],
    padding: 20,
    borderRadius: 20,
  },
  
  groupButton: {
    marginLeft: 'auto',
    marginRight: 0,
  }
}));

export interface UserInfoIconsProps {
  id: string;
  avatar?: string;
  name: string;
  weight?: string;
  height?: string;
  birsday?: string;
  onTapped: (id: string) => void;
}

export function UserInfoIcons({ id, avatar, name, weight, height, birsday, onTapped }: UserInfoIconsProps) {
  const { classes } = useStyles();
  const [image, setImage] = useState<any>();
  const [mount, _mount] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const service = new FileService();
    service.obtainFile(avatar)
        .catch(error => {
            setImage(square);
            return
        })
        .then(file => {
            if (!file) {
                setImage(square);
                return
            }

            setImage(file);
        });

    _mount(true);
  }, []);
  
  return (
    <Transition mounted={mount} transition="fade" duration={400} timingFunction="ease">
                    {(styles) => <div style={{height: "100%", ...styles}}>
                    <div>
      <Group className={classes.group} onClick={() => onTapped(id)}>
        <Avatar src={image} size={110} radius="xl"><Loader size={`30px`} /></Avatar>
        <div>
          <Container px={0}>
            <Title className={classes.titles} order={6} color="dimmed">{t("Pet name")}</Title>
            <Text size="lg" weight={700} className={classes.name} lineClamp={2}>
                {name}
            </Text>
          </Container>

          <Space h={8} />
          <Group px={0}>
            <Container px={0} fluid>
                <Title className={classes.titles} order={6} color="dimmed">{t("Height: ")}</Title>
                <Title className={classes.titles} order={4}>{height} {t("cm")}</Title>
            </Container>
            <Container px={0} fluid>
                <Title className={classes.titles} order={6} color="dimmed">{t("Weight: ")}</Title>
                <Title className={classes.titles} order={4}>{weight} {t("kg")}</Title>
            </Container>
            <Container px={0} fluid>
                <Title className={classes.titles} order={6} color="dimmed">{t("Date of birth: ")}</Title>
                <Title className={classes.titles} order={4}>{birsday}</Title>
            </Container>
          </Group>
        </div>
        <div className={classes.groupButton}>
            <ActionIcon color="blue" variant="outline"><IconArrowBigRightLines size={'16px'} onClick={() => onTapped(id)}/></ActionIcon>
        </div>
      </Group>
    </div>
                    </div>}
    </Transition>
  );
}