import i18next from "i18next";
import { VaccineType } from "../../Domain/Ambulance/AmbulanceService";

export function vaccineTypeHelper(type: VaccineType | string) {
    switch (type) {
        case "other":
            return i18next.t("Other vaccines");

        case "parvovirus":
            return i18next.t("From plague, parvovirus enteritis, etc.");

        case "rabies":
            return i18next.t("Against rabies");

        default:
            return i18next.t("Unknown");
    }
}