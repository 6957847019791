import { ActionIcon, Container, Grid, Group, Paper, SimpleGrid, Stack, Text, Image, Button, Space, Avatar } from "@mantine/core";
import { IconNotebook, IconMap2, IconInfoSquareRounded } from "@tabler/icons-react";
import tg_logo from "../../../Resources/Assets/tg_logo.svg";
import avatar from "../../../Resources/Assets/alvph.jpg";
import { useTranslation } from "react-i18next";

export const CLandingOveralView: React.FC = () => {
    const { t } = useTranslation();

    const teamCard = (
        <Paper bg="#F5F5F5" pl={20} pr={20} pt={15} pb={15} radius={20}>
            <Stack>
                <Group>
                <Avatar size={60} radius={30} src={avatar}></Avatar>
                <Stack spacing={2}>
                <Text
                    weight={700}
                    style={{lineHeight: 1}}
                    size={16}>{"Alexander Volkov"}
                </Text>
                <Group position="left">
                <Button pl={0} variant="subtle" leftIcon={<Image width={24} src={tg_logo} />}>
                    {"@alvph"}
                </Button>
                </Group>
                </Stack>
                </Group>
            </Stack>
        </Paper>
    )
    const aboutUs = (
            <Stack spacing={12} justify="flex-end">
            <Text
                weight={400}
                style={{lineHeight: 1}}
                color="dimmed"
                size={16}>{t("Designed and developed by:")}
            </Text>
            <Group position="center">
                {teamCard}
            </Group>
        </Stack>
    )

    return (
        <Container fluid p={20} mt={30}>
            <Stack spacing={24}>
            <Group w={"100%"} position="center">
                <Text
                    weight={900}
                    style={{lineHeight: 1.1}}
                    size={48}>{t("About")}
                </Text>
            </Group>
            <Group w={"100%"} position="center">
                <Text w={"80%"}
                    weight={600}
                    style={{lineHeight: 1.1}}
                    size={24}>{t("Care is designed to help pet owners take better care of their pets by providing them with the tools and information they need to ensure their pets are healthy, happy, and safe. By using the Pet Health Tracker, Public Map, and Pet Information features in Care, pet owners can better manage their pets' health, find safe places to take their pets, and store important information about their pets, all in one convenient app.")}
                </Text>
            </Group>
            <Space h={8} />
            <Group w={"100%"} position="center">
                { aboutUs }
            </Group>
            </Stack>
        </Container>
    )
};