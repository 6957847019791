import { Avatar, Button, Container, FileButton, Group, LoadingOverlay, NumberInput, Select, Space, Stack, Text, TextInput, createStyles } from "@mantine/core";
import { UserClientDTO, UserDTO, UserService } from "../../Domain/UserService/UserServiceDTO";
import { useState } from "react";
import placeholder_image from "../../Resources/Assets/square.svg";
import { ToastManager } from "../Map/ToastManager";
import { useTranslation } from "react-i18next";

export interface ProfileEditCardBuilderClose {
    onClose: () => void;
}

export interface ProfileEditCardBuilder {
    update: () => void;
    userDTO: UserDTO;
    avatar: any;
}

interface CUserFillDataInterface {
    name?: string;
    email?: string;
    phone?: string;
}

function ProfileEditCardViewModel(builder: ProfileEditCardBuilder) {
    type UserEditPole = "name" | "email" | "phone";
    type UserPasswordEditPole = "password" | "repassword";

    const userService = new UserService();
    const originalUserDTO = builder.userDTO;
    const [data, _data] = useState<CUserFillDataInterface>({
        name: originalUserDTO.name,
        email: originalUserDTO.email,
        phone: originalUserDTO.phone,
    });
    const [file, setFile] = useState<File | null>(null);
    const [imageChanged, setImageChanged] = useState(false);
    const [dataChanged, setDataChanged] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [avatarLoading, setAvatarLoading] = useState(false);

    const { t } = useTranslation();

    function changeFiles(file: File) {
        setFile(file);
        checkChangeImage(file);
    };

    function checkChangeImage(file?: File) {
        setImageChanged(file != null);
    };

    function valueChanged(value: any, pole: UserEditPole) {
        const newData = { ...data, [pole]:value };

        _data(
            { ...data, [pole]:value }
        );
        checkChange(newData);
    };

    function checkChange(newData: CUserFillDataInterface) {
        setDataChanged(
            (
                (newData.name !== originalUserDTO.name && newData.name?.length !== 0) ||
                (newData.phone !== originalUserDTO.phone && newData.phone?.length !== 0) ||
                (newData.email !== originalUserDTO.email && newData.email?.length !== 0)
            )
        );
    };

    function saveTapped() {
        const dto: UserClientDTO = {
            name:  data.name,
            email: data.email,
            phone: data.phone
        }

        if (dataChanged) {
            saveChangedData(dto);
        }

        if (file && imageChanged) {
            saveAvatar(file);
        };
    };

    function saveChangedData(dto: UserClientDTO) {
        setDataLoading(true);
        userService.updateUser(dto)
        .catch(error => {
            ToastManager.getInstance().showError(t("Failed to update profile"))
            setDataLoading(false);
        })
        .then((result) => {
            if (!result) { return; }
            ToastManager.getInstance().showSuccess(t("Data updated!"))
            builder.update();
            setDataLoading(false);
            setDataChanged(false);
        })
    };

    function saveAvatar(file: File) {
        setAvatarLoading(true);
        userService.updateAvatar(file)
            .catch(error => {
                ToastManager.getInstance().showError(t("Failed to update avatar"))
                setAvatarLoading(false);
            })
            .then(result => {
                if (!result) { return; }
                builder.update();
                ToastManager.getInstance().showSuccess(t("Avatar updated successfully!"))
                setAvatarLoading(false);
                setImageChanged(false);
            }
        );
    };

    return ({
        data, file, dataChanged, dataLoading, avatarLoading, imageChanged,
        changeFiles, valueChanged, saveTapped
    })
}

export function ProfileEditCard(builder: ProfileEditCardBuilder & ProfileEditCardBuilderClose) {
    const viewModel = ProfileEditCardViewModel(builder);
    const { t } = useTranslation();

    const { classes } = createStyles((theme) => ({
        input: {
            fontWeight: 600,
        }}
        )
    )();

    return (
        <>
            <Container p={0}>
                <Stack>
                    <Stack spacing={8}>
                        <Group position="center" spacing={0}>
                        <FileButton multiple={false} onChange={viewModel.changeFiles} accept="image/png,image/jpeg">
                            {(props) => 
                                <Avatar radius={150 / 2} size={150} {...props} src={ viewModel.file != null ? URL.createObjectURL(viewModel.file) : builder.avatar ?? placeholder_image}></Avatar>
                            }
                        </FileButton>
                        </Group>
                        <Text align="center" size="sm" weight={600} color="dimmed">{t("To update the avatar, click on it")}</Text>
                    </Stack>
                    <Stack spacing={8}>
                        <Text
                            size="sm"
                            weight={600}
                            color="dimmed"
                        >
                            {t("Name")}
                        </Text>
                        <TextInput
                            size="sm"
                            classNames={classes}
                            value={viewModel.data.name ?? ""}
                            placeholder="Alex"
                            onChange={(e) => { viewModel.valueChanged(e.target.value, "name") }}
                            radius="md"
                        />
                    </Stack>
                    <Stack spacing={8}>
                        <Text
                            size="sm"
                            weight={600}
                            color="dimmed"
                        >
                            {t("Email")}
                        </Text>
                        <TextInput
                            size="sm"
                            classNames={classes}
                            value={viewModel.data.email ?? ""}
                            placeholder="your@email.com"
                            onChange={(e) => { viewModel.valueChanged(e.target.value, "email") }}
                            radius="md"
                        />
                    </Stack>
                    <Stack spacing={8}>
                        <Text
                            size="sm"
                            weight={600}
                            color="dimmed"
                        >
                            {t("Phone")}
                        </Text>
                        <TextInput
                            size="sm"
                            classNames={classes}
                            value={viewModel.data.phone ?? ""}
                            placeholder="89999999999"
                            onChange={(e) => { viewModel.valueChanged(e.target.value, "phone") }}
                            radius="md"
                        />
                    </Stack>
                    <Space />
                    <Button onClick={viewModel.saveTapped} radius="md" disabled={!(viewModel.dataChanged || viewModel.imageChanged)}>{t("Update")}</Button>
                    <LoadingOverlay visible={viewModel.dataLoading || viewModel.avatarLoading} overlayBlur={2} />
                </Stack>
            </Container>
        </>
    )
}