import { useRef } from "react";
import { MapViewModelModern, NewPlaceDataInfoModel } from "./MapViewModel";
import { ActionIcon, Button, Container, Group, Title, Transition, createStyles, Text, Space, Stack, Textarea, LoadingOverlay, rem, MediaQuery, ScrollArea } from "@mantine/core";
import { IconCircleX, IconLocationFilled } from "@tabler/icons-react";
import { CLocation2DPoint, DangerAreaDTO, PlaceType } from "../../Domain/Places/PlaceService";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export function MapView() {
    const { classes } = createStyles((theme) => ({
        wrapper: {
            marginTop: 0, margin: 0, padding: 0,
        }, 
        title: {
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontSize: 24,
        },
        publicMapTitle: {
            backgroundColor: "white",
            padding: 10,
            borderRadius: 10
        },
        mapContainer: {
            position: "absolute",
            top: 0, left: 0,
            height: "100%", width: "100%",
            borderRadius: 20,

            [theme.fn.largerThan("xs")]: {
                overflow: "hidden",
                WebkitMaskImage: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC)"
            },
        },
        container: {
            [theme.fn.largerThan("xs")]: {
                position: "relative",
                height: "99%",
                borderRadius: 20,
                overflow: "hidden"
            },
        },
        moduleTitleLabelContainer: {
            position: "absolute",
            top: 20,
            left: 20,

            [theme.fn.smallerThan("xs")]: {
                top: 60,
                left: 8
            },
        },
        createPlaceContainer: {
            zIndex: 500,
            position: "absolute",
            bottom: 20,
            backgroundColor: "white",
            left: 20,
            width: 450,
            borderRadius: 20,

            [theme.fn.smallerThan("xs")]: {
                width: "auto",
                left: 8,
                right: 8,
                bottom: 5,
            },
        },
        placeDetailsViewContainer: {
            zIndex: 500,
            position: "absolute",
            bottom: 20,
            backgroundColor: "white",
            left: 20,
            width: 450,
            borderRadius: 20,

            [theme.fn.smallerThan("xs")]: {
                width: "auto",
                left: 8,
                right: 8,
                bottom: 5,
            },
        },
    }))();

    const mapContainer = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const viewModel = MapViewModelModern({
        mapContainer: mapContainer
    })

    // Карта
    const mainBackgroundContainer = (
        <div className={classes.mapContainer}>
            <div style={{height: "100%", width: "100%"}} ref={mapContainer} className='map' />  
        </div>
    );

    // Текст модуля
    const moduleTitleLabelContainer = (
        <div className={classes.moduleTitleLabelContainer}>
          <Group className={classes.publicMapTitle}>
              <Title className={classes.title}>{t("Public map")}</Title>
          </Group>
        </div>
    );

    // Кнопка геолокации
    const geolocationButtonContainer = (
        <div className="geolocationButtonContainer" style={{ position: "absolute", top: "50%", right: 15 }}>
            <ActionIcon 
                onClick={ viewModel.buttons.geolocationTapped }
                variant="default" color="black" radius="lg" size={48} p={8}
            >
                <IconLocationFilled color="black" />
            </ActionIcon>
      </div>
    );

    const kBasicButtonHeight = 42;
    const kBasicButtonRadius = 12;
    const kBasicButtonVariant = "white";
    const filtersControllPanelContainer = (
        <div className="filtersControllPanelContainer" style={{ position: "absolute", width: "100%", bottom: 20 }}>
                <ScrollArea w={"100%"} h={kBasicButtonHeight + 12} type={"never"}>
                    <Group position="center" w={"100%"} spacing={8} noWrap>
                        <Button onClick={ viewModel.buttons.createPlaceTapped } variant={kBasicButtonVariant} h={kBasicButtonHeight} radius={kBasicButtonRadius}>{t("Create a place")}</Button>
                        <Button onClick={ viewModel.buttons.dangerAreasTapped } variant={viewModel.buttons.isDangerAreasSelected ? "filled" : kBasicButtonVariant} h={kBasicButtonHeight} radius={kBasicButtonRadius}>{t("Dangerous places")}</Button>
                        <Button onClick={ viewModel.buttons.safeAreasTapped } variant={viewModel.buttons.isSafeAreasSelected ? "filled" : kBasicButtonVariant} h={kBasicButtonHeight} radius={kBasicButtonRadius}>{t("Safe places")}</Button>
                        <Button onClick={ viewModel.buttons.ownerAreasTapped } variant={viewModel.buttons.isOwnerAreasSelected ? "filled" : kBasicButtonVariant} h={kBasicButtonHeight} radius={kBasicButtonRadius}>{t("My places")}</Button>
                    </Group>
                </ScrollArea>
        </div>
    );

    const confirmPlaceMarkerControllPanelContainer = (
        <div className="confirmPlaceMarkerControllPanelContainer" style={{ position: "absolute", width: "100%", bottom: 20 }}>
            <Group position="center" w={"100%"} spacing={8} noWrap>
                <Button onClick={ viewModel.createPlaceInput.createPlaceConfirmMarkerMobile } variant="filled" color="blue" h={kBasicButtonHeight} radius={kBasicButtonRadius}>{t("Confirm")}</Button>
                <Button onClick={ viewModel.createPlaceInput.createPlaceCancelMarkerMobile } variant="filled" color="red" h={kBasicButtonHeight} radius={kBasicButtonRadius}>{t("Cancel")}</Button>
            </Group>
        </div>
    );

    const createPlaceContainer = (
        <div className={classes.createPlaceContainer}>
            <CreatePlaceView
                closeTapped={ viewModel.createPlaceInput.createPlaceCloseTapped }
                placeMarkerLocation={ viewModel.createPlaceInput.creatingPlaceLocation }
                placeModel={ viewModel.createPlaceInput.newPlaceModel }
                placeTypeTapped={ viewModel.createPlaceInput.placeTypeTapped }
                commentDidChanged={ viewModel.createPlaceInput.commentDidChange }
                createTapped={ viewModel.createPlaceInput.newPlaceTappedCreate }
                newPlaceCreateOnProcess={ viewModel.createPlaceInput.newPlaceCreateOnProcess }
            />
        </div>
    );

    const placeDetailsViewContainer = (
        <div className={classes.placeDetailsViewContainer}>
            <PlaceDetailsView 
                isError={ viewModel.detailsInput.isErrorOnPlaceDetails }
                placeDetailsModel={ viewModel.detailsInput.placeDetailsModel }
                placeDetailsLoading={ viewModel.detailsInput.placeDetailsLoading }
                retryTapped={ viewModel.detailsInput.petDetailsRetryTapped }
                closeTapped={ viewModel.detailsInput.petDetailsCloseTapped }
                deletePlaceTapped={ viewModel.detailsInput.deletePlaceTapped }
            />
        </div>
    );

    return (
        <Container className={classes.container} fluid p={0} bg={"gray"}>
            { mainBackgroundContainer }
            { moduleTitleLabelContainer }
            <Transition
                mounted={viewModel.visibility.visibilityState === "filters"}
                transition="fade" duration={200} timingFunction="ease"
            >
                {(styles) => (
                    <div style={{backgroundColor:"black", ...styles}}>
                        { filtersControllPanelContainer }
                    </div>
                )}
            </Transition>
            <Transition
                mounted={viewModel.visibility.visibilityState === "confirmCreationPlace"}
                transition="fade" duration={200} timingFunction="ease"
            >
                {(styles) => (
                    <div style={{backgroundColor:"black", ...styles}}>
                        { confirmPlaceMarkerControllPanelContainer }
                    </div>
                )}
            </Transition>
            <Transition
                mounted={viewModel.visibility.visibilityState === "placeCreate"}
                transition="fade" duration={200} timingFunction="ease"
            >
                {(styles) => (
                    <div style={{backgroundColor:"black", ...styles}}>
                        { createPlaceContainer }
                    </div>
                )}
            </Transition>
            <Transition
                mounted={viewModel.visibility.visibilityState === "placeDetails"}
                transition="fade" duration={200} timingFunction="ease"
            >
                {(styles) => (
                    <div style={{backgroundColor:"black", ...styles}}>
                        { placeDetailsViewContainer }
                    </div>
                )}
            </Transition>
            { geolocationButtonContainer }
        </Container>
    )
};

export interface CreatePlaceViewInput {
    placeMarkerLocation?: CLocation2DPoint;
    placeModel: NewPlaceDataInfoModel;
    newPlaceCreateOnProcess: boolean;
    commentDidChanged: (commentText?: string) => void;
    placeTypeTapped: (type: PlaceType) => void;
    closeTapped: () => void;
    createTapped: () => void;
}

export interface PetDetailsViewInput {
    isError: boolean;
    placeDetailsModel: DangerAreaDTO | undefined;
    placeDetailsLoading: boolean;
    retryTapped: () => void;
    closeTapped: () => void;
    deletePlaceTapped: (placeID: string) => void;
}

function PlaceDetailsView(input: PetDetailsViewInput) {
    const { t } = useTranslation();
    const { classes } = createStyles((theme) => ({
        placeholderSubtitle: {
            lineHeight: 1,
            fontWeight: 800,
            fontSize: rem(24),
            [theme.fn.smallerThan("xs")]: {
                fontSize: rem(16),
            },
        },

        placeholderTitle: {
            lineHeight: 1,
            fontWeight: 800,
            fontSize: rem(28),
            [theme.fn.smallerThan("xs")]: {
                fontSize: rem(20),
            },
        },
    }))();

    const errorContent = {
        title: t("Error"),
        subtitle: t("We were unable to download this location, please try again later")
    };

    const errorView = (
        <Stack spacing={8} style={{borderRadius: 20}} p={0} justify={"center"} align="flex-start" mt={10} w={"70%"} h={"100%"}>
            <Text className={classes.placeholderTitle}>{errorContent.title}</Text>
            <Text className={classes.placeholderSubtitle} color="gray">{errorContent.subtitle}</Text>
            <Space h={6} />
            <Button color="dark" onClick={input.retryTapped} radius="md">{t("Repeat")}</Button>
        </Stack>
    );

    const moduleTitleView = (
        <Group position="apart">
            <Text weight={800} size={18}>{t("Viewing a place")}</Text>
            <ActionIcon onClick={input.closeTapped}><IconCircleX /></ActionIcon>
        </Group>
    );

    function deleteButtonIfNeeded() {
        if (!input.placeDetailsModel) {
            return <></>
        }
        if (input.isError) {
            return <></>
        }

        return (
            <Button
                onClick={() => { 
                    if (!input.placeDetailsModel) { return; };
                    input.deletePlaceTapped(input.placeDetailsModel.id);
                }}
                radius="md"
                color="red">
                    {t("Delete")}
                </Button>
        )
    }

    const detailsView = (
        <Stack spacing={5}>
        <Stack spacing={2}>
            <Text color="dimmed" weight={600} size={14}>{t("Type of place")}</Text>
            <Text weight={600} size={16}>{ input.placeDetailsModel?.type ?? "" }</Text>
        </Stack>
        <Stack spacing={2}>
            <Text color="dimmed" weight={600} size={14}>{t("Exact coordinates")}</Text>
            <Text weight={600} size={16}>{ input.placeDetailsModel?.latitude.toFixed(5) ?? "-" }{"   •   "}{ input.placeDetailsModel?.longitude.toFixed(5) ?? "-" }</Text>
        </Stack>
        <Stack spacing={2}>
            <Text color="dimmed" weight={600} size={14}>{t("Mandatory description")}</Text>
            <Text weight={600} size={16}>{ input.placeDetailsModel?.messageText }</Text>
        </Stack>
        <Space h={5} />
            { deleteButtonIfNeeded() }
        </Stack>
    );

    function chooseView() {
        if (input.isError) {
            return errorView;
        }

        return detailsView;
    }

    return (
        <Container pt={8} pl={12} pr={12} pb={8}>
            { moduleTitleView }
            <Space h={4} />
                    { chooseView() }
            <LoadingOverlay style={{borderRadius: 20}} visible={input.placeDetailsLoading}/>
        </Container>
    )
}

function CreatePlaceView(input: CreatePlaceViewInput) {
    const textParams = {
        weight: 500,
        size: "sm",
        style: { lineHeight: 1.1 }
    };

    const textDescription = (
        <Stack spacing={8}>
            <Text {...textParams} color="dimmed">{t("This place will be public, i.e. it will be seen by other users. If the place is dangerous for walking, please describe it informatively.")}</Text>
            <Text {...textParams} >{t("To change the coordinates of a place, click on the map or move the placemark.")}</Text>
        </Stack>
    );

    const buttonsStack = (
        <Stack spacing={2}>
            <Text color="dimmed" weight={600} size={14}>{"Тип места"}</Text>
            <Button.Group>
                <Button variant="filled" onClick={() => { input.placeTypeTapped("danger") }} color={input.placeModel.type === "danger" ? "red" : "gray"} radius="md">{t("Dangerous")}</Button>
                <Button variant="filled" onClick={() => { input.placeTypeTapped("safe") }} color={input.placeModel.type === "safe" ? "green" : "gray"} radius="md">{t("Safe")}</Button>
            </Button.Group>
        </Stack>
    )

    function placeLocationStack() {
        if (!input.placeMarkerLocation) {
            return (
                <Stack spacing={2}>
                <Text color="dimmed" weight={600} size={14}>{t("Location coordinates")}</Text>
                    <Text weight={700} size={14}>
                        { "-" }
                        {"  •  "}
                        { "-" }
                    </Text>
                </Stack>
            )
        }

        return (
            <Stack spacing={2}>
                <Text color="dimmed" weight={600} size={14}>{t("Location coordinates")}</Text>
                <Text weight={700} size={14}>
                    { input.placeMarkerLocation.latitude?.toFixed(5) }
                    {"  •  "}
                    { input.placeMarkerLocation.longtitude?.toFixed(5) }
                </Text>
            </Stack>
        )
    };

    const commentBlock = (
        <Stack spacing={2}>
            <Text color="dimmed" weight={600} size={14}>{t("Comment")}</Text>
                <Textarea
                    onChange={e => { input.commentDidChanged(e.target.value.toString())}}
                    value={input.placeModel.comment} minRows={4} maxRows={5} radius="md" placeholder={t("Describe this place, preferably in detail, so that other users understand it")!}/>
            </Stack>
    );

    return (
        <Container pt={8} pl={12} pr={12} pb={8}>
            <Group position="apart">
                <Text weight={800} size={18}>{t("Creating a place")}</Text>
                <ActionIcon onClick={input.closeTapped}><IconCircleX /></ActionIcon>
            </Group>
            <Space h={4} />
            { textDescription }
            <Space h={12} />
            { placeLocationStack() }
            <Space h={12} />
            { buttonsStack }
            <Space h={12} />
            { commentBlock }
            <Space h={8} />
            <Group position="right">
                <Button onClick={input.createTapped} h={40} radius="md">{t("Send")}</Button>
            </Group>
            <LoadingOverlay visible={input.newPlaceCreateOnProcess} />
        </Container>
    )
}