import { Button, Center, createStyles, Group, PasswordInput, Space, Stack, Text, TextInput, Transition } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconArrowLeft } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AuthorizationService } from "../../../Domain/Authorization/AuthorizationService";

export class Validator {
    validateEmail(email: string): boolean {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
    };

    validateName(name: string): boolean {
        return /^[a-zA-Zа-яА-Я_ ]{2,25}$/.test(name);
    };

    validatePassword(password: string): boolean {
        return /^[a-zA-Z0-9_@./#&+!-]{8,21}$/.test(password);
    };

    validatePasswords(password: string, repeate: string): boolean {
        return this.compareLargeStrings(password, repeate);
    };

    compareLargeStrings(a: string,b: string){
        if (a.length !== b.length) {
             return false;
        }
        return a.localeCompare(b) === 0;
    };
}

export const CRegisterView: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const authorizationService = new AuthorizationService();

    type Pole = "name" | "email" | "password" | "repassword";
    interface RegistrationForm {
        name?: string;
        email?: string;
        password?: string;
        repassword?: string;
    };

    const localized = {
        nameError: {
            title: t("Error in filling in the name :("),
            message: t("The name should not be empty. The name must be at least 2 characters and no more than 25 characters."),
            color: "red"
        },

        emailError: {
            title: t("Error in filling in the email :("),
            message: t("The email should not be empty. You have entered an incorrect mail format."),
            color: "red"
        },

        passwordError: {
            title: t("Error in filling in the password :("),
            message: t("The password should not be empty. The password must contain 8 or more characters."),
            color: "red"
        },

        matchPasswordError: {
            title: t("Error :("),
            message: t("The entered passwords do not match each other."),
            color: "red"
        },

        registerError: {
            title: t("Registration error :("),
            message: t("There was an error when registering your account, perhaps there is already such an email. Try another one use a different mail."),
            color: "red"
        }
    };

    const [mounted, _mounted] = useState(false);
    const [fillData, _fillData] = useState<RegistrationForm>({});

    function valueChanged(data: string, pole: Pole) {
        _fillData({ ...fillData, [pole]:data });
    };

    useEffect(() => {
        _mounted(true);
    }, []);

    const { classes } = createStyles((theme) => ({
        input: {
            fontWeight: 600,
        }}
        )
    )();

    function buttonTapped() {
        const name = fillData.name;
        const email = fillData.email;
        const password = fillData.password;
        const repassword = fillData.repassword;

        var isValid = true

        if (name == null || name == undefined || new Validator().validateName(name) == false) {
            showNotification({
                title: localized.nameError.title,
                message: localized.nameError.message,
                autoClose: 5000,
                color: localized.nameError.color
            })  
            isValid = false
        }

        if (email == null || email == undefined || new Validator().validateEmail(email) == false) {
            showNotification({
                title: localized.emailError.title,
                message: localized.emailError.message,
                autoClose: 5000,
                color: localized.emailError.color
            })
            isValid = false
        }

        if (password == null || password == undefined || new Validator().validatePassword(password) == false) {
            showNotification({
                title: localized.passwordError.title,
                message: localized.passwordError.message,
                autoClose: 5000,
                color: localized.passwordError.color
            })  
            isValid = false
        }

        if (repassword == null || repassword == undefined || new Validator().validatePasswords(password ?? "", repassword) == false) {
            showNotification({
                title: localized.matchPasswordError.title,
                message: localized.matchPasswordError.message,
                autoClose: 5000,
                color: localized.matchPasswordError.color
            })
            isValid = false
        }

        if (isValid == true && name && email && password) {
            authorizationService.register({
                email: email,
                password: password,
                name: name
            })
            .catch(error => {
                showNotification({
                    title: localized.registerError.title,
                    message: localized.registerError.message,
                    autoClose: 5000,
                    color: localized.registerError.color
                })    
            })
            .then(result => {
                if (result == undefined) { return }

                window.location.reload();
                window.location.href = "/";
            });
        }
    }

    const greeting = (
        <Stack>
            <Group>
                <Button variant="light" radius="md" leftIcon={<IconArrowLeft />} onClick={() => { navigate("/") }}>{t("Back")}</Button>
            </Group>
            <Space h={2}/>
            <Text size={24} weight={800} style={{lineHeight: 1.1}}>{t("Hey, newbie! 👋")}</Text>
            <Text size={16} weight={500} style={{lineHeight: 1.1}} color="dimmed">{t("To sign up, fill in the data. We do not transfer data to third parties.")}</Text>
        </Stack>
    );

    const inputs = (
        <Stack>
            <Stack spacing={0}>
                <Text
                    size="sm"
                    weight={600}
                >
                    {t("Name")}
                </Text>
                <TextInput
                    size="md"
                    classNames={classes}
                    value={fillData.name ?? ""}
                    placeholder="Alex"
                    onChange={(e) => { valueChanged(e.target.value, "name") }}
                    radius="md"
                />
            </Stack>
            <Stack spacing={0}>
                <Text
                    size="sm"
                    weight={600}
                >
                    {t("Email")}
                </Text>
                <TextInput
                    size="md"
                    classNames={classes}
                    value={fillData.email ?? ""}
                    placeholder="hello@mail.com"
                    onChange={(e) => { valueChanged(e.target.value, "email") }}
                    radius="md"
                />
            </Stack>

            <Stack spacing={0}>
                <Text
                    size="sm"
                    weight={600}
                >
                    {t("Password")}
                </Text>
                <PasswordInput
                    size="md"
                    classNames={classes}
                    value={fillData.password ?? ""}
                    placeholder="•••••••••••"
                    onChange={(e) => { valueChanged(e.target.value, "password") }}
                    radius="md"
                />
            </Stack>
            <Stack spacing={0}>
                <Text
                    size="sm"
                    weight={600}
                >
                    {t("Repeate password")}
                </Text>
                <PasswordInput
                    size="md"
                    classNames={classes}
                    value={fillData.repassword ?? ""}
                    placeholder="•••••••••••"
                    onChange={(e) => { valueChanged(e.target.value, "repassword") }}
                    radius="md"
                />
            </Stack>
            <Button onClick={buttonTapped} h={40} radius="md">{t("Sign up")}</Button>
            </Stack>
    )

    return (
        <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => <div style={{height: "100%", ...styles}}>
            <Center h={"100%"}>
            <Stack justify="center" h={"100%"} maw={400}>
                { greeting }
                { inputs }
            </Stack>
        </Center>
        </div>}
        </Transition>  
    )
}