import { Button, createStyles, Group, Loader, Paper, rem, Stack, Text } from "@mantine/core"
import { IconCircleCheckFilled, IconCircleXFilled } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { BrandDTO, ChipDTO } from "../../Domain/Pets/PetService";
import { CHoverShadow } from "../../Core/CHoverShadow/CHoverShadow";
import { CPetDetailsGlobalLoading } from "./CPetDetailsView";
import { useTranslation } from "react-i18next";

interface CPetMiniGridItemViewBuilder {
    h: number;
    dataBuilder: CPetMiniGridItemDataBuilder;
};

interface CPetMiniGridItemDataBuilder {
    loadingState: CPetDetailsGlobalLoading;
    objectID: string | undefined;
    type: "chip" | "brand";
    buttonTapped: (type: "chip" | "brand") => void;
};

function CPetMiniGridItemViewModel(builder: CPetMiniGridItemDataBuilder) {
    const { t } = useTranslation();

    const [title] = useState(() => {
        switch (builder.type) {
            case "brand":
                return t("Brand");

            case "chip":
                return t("Electronic chip");
        }
    });

    const [subtitle, _subtitle] = useState<string | null>(null);
    const [icon, _icon] = useState<"check" | "xmark" | null>(null);
    const [data, _data] = useState<ChipDTO | BrandDTO | null>(null);
    const [buttonTitle, _buttonTitle] = useState<string>("");

    useEffect(() => {
        switch (builder.loadingState) {
            case "loaded":
                updateView();
                break;

            case "loading":
                break;
        }
    }, [builder.loadingState, builder.objectID]);

    function updateView() {
        if (builder.objectID == undefined) { 
            _subtitle(t("Nonexistent"));
            _icon("xmark");
            _buttonTitle(t("Create")!);
            return;
        } else {
            _subtitle(t("Exist"));
            _icon("check");
            _buttonTitle(t("Details")!);
        }
    };

    function buttonTapped() {
        builder.buttonTapped(builder.type);
    } 

    return ({
        data,
        title,
        viewState: builder.loadingState,
        subtitle,
        icon,
        buttonTitle,
        buttonTapped
    });
};

export function CPetMiniGridItemView(builder: CPetMiniGridItemViewBuilder) {
    const viewModel = CPetMiniGridItemViewModel(builder.dataBuilder);
    
    const { classes } = createStyles((theme) => ({
        card: {
            cursor: "default",
            backgroundImage: theme.colorScheme === 'dark' ? 
                theme.fn.linearGradient(323, "rgba(2,0,36,1) 0%", "rgba(45,132,149,1) 100%") :
                theme.colors.gray[0],
            ...CHoverShadow(theme)
        },
        title: {
            lineHeight: 0.8,
            [theme.fn.smallerThan("xl")]: {
                fontSize: rem(20),
                fontWeight: 700
            },
        },

        subtitle: {
            lineHeight: 1,
            [theme.fn.smallerThan("xl")]: {
                fontSize: rem(16),
                fontWeight: 600
            },
        },
    }))();

    const icon = () => {
        if (viewModel.icon == null) {
            return <></>
        }

        switch (viewModel.icon) {
            case "check":
                return <IconCircleCheckFilled size={22} />;
            
            case "xmark":
                return <IconCircleXFilled size={22} />
        }
    }

    const activeContent = () => {
        switch (viewModel.viewState) {
            case "loaded":
                return (
                    <>
                        { icon() }
                        <Text className={classes.subtitle} weight={700} size={18}>{viewModel.subtitle}</Text>
                    </>
                );

            case "loading":
                return (
                    <>
                        <Loader size={22} color="dark" />
                    </>
                );
        }
    };

    return (
        <Paper className={classes.card} withBorder p={12} radius="lg" h={builder.h}>
            <Stack justify="space-between" h={builder.h - 24}>
                <Text className={classes.title} weight={700} size={20}>{viewModel.title}</Text>
                <Stack spacing="md">
                <Group spacing={8}>
                    {activeContent()}
                </Group>
                <Button onClick={viewModel.buttonTapped} disabled={viewModel.buttonTitle.length == 0} color={"dark"} radius="md">{viewModel.buttonTitle}</Button>
                </Stack>
            </Stack>
        </Paper>
    )
};