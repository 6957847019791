// "/api/v1"
import axios from "axios";
import { BaseURL } from "../BasicURLScheme";

export class PlaceService {
    private baseURL: BaseURL = BaseURL.shared;

    public async obtainCount() {
        const token = this.baseURL.obtainHeader();

        return axios.get<PlaceCountDTO>(
            this.baseURL.buildEndpoin(`/api/v1/zones/count`),
            {
                headers: {
                    ...token
                }
            }
        )
    }

    public async obtainPlaces(location: CLocation2DPoint, filter: PlaceFitler) {
        const token = this.baseURL.obtainHeader();

        const params = new URLSearchParams();
        params.append("latitude", location.latitude.toString())
        params.append("longitude", location.longtitude.toString())
        params.append("filter", filter.toString())
        
        return axios.get<Array<DangerAreaPoint>>(
            this.baseURL.buildEndpoin(`api/v1/zones/range?${params.toString()}`),
            {
                headers: {
                    ...token
                }
            }
        )
    }

    public async obtainOwnerPlaces(location: CLocation2DPoint, filter: PlaceFitler) {
        const token = this.baseURL.obtainHeader();

        const params = new URLSearchParams();
        params.append("latitude", location.latitude.toString())
        params.append("longitude", location.longtitude.toString())
        params.append("filter", filter.toString())
        
        return axios.get<Array<DangerAreaPoint>>(
            this.baseURL.buildEndpoin(`api/v1/zones/range/owner?${params.toString()}`),
            {
                headers: {
                    ...token
                }
            }
        )
    };

    public async createPlace(dto: PlaceClientDTO) {
        const token = this.baseURL.obtainHeader();

        return axios({
            method: "POST",
            url: this.baseURL.buildEndpoin(`api/v1/zones/`),
            headers: { ...token },
            data: { ...dto }
        });
    }

    public async obtainPlace(id: string) {
        const token = this.baseURL.obtainHeader();
        return axios.get<DangerAreaDTO>(this.baseURL.buildEndpoin(`api/v1/zones/${id}`), {
                headers: {
                    ...token
                }
            }
        )
    }

    public async deletePlace(id: string) {
        const token = this.baseURL.obtainHeader();
        return axios.delete<DangerAreaDTO>(this.baseURL.buildEndpoin(`api/v1/zones/${id}`), {
                headers: {
                    ...token
                }
            }
        )
    }
};

export type PlaceFitler = "all" | "safe" | "danger";
export type PlaceType = "safe" | "danger";

export interface PlaceCountDTO {
    all: number;
    safe: number;
    danger: number;
}

export interface PlaceClientDTO {
    latitude: number;
    longitude: number;
    messageText: string;
    type: string;
}

export interface CLocation2DPoint {
    latitude: number;
    longtitude: number;
};

export interface DangerAreaPoint {
    id: string;
    latitude: number;
    longitude: number;
    type: PlaceType;
    title: string;
}

export interface RatingDTO {
    id: string;
    forCount: number;
    againstCount: number;
}

export interface LiteUserDTO {
    id: string;
    name: string;
    avatarURL?: string;
}

export interface DangerAreaDTO {
    id: string;
    userDTO: LiteUserDTO;
    ratingDTO: RatingDTO;
    latitude: number;
    longitude: number;
    messageText?: string;
    type: PlaceType;
    isDeleteEnabled: boolean;
    isOwnerViewer: boolean;
}