import axios from "axios";
import { BaseURL } from "../BasicURLScheme";
import { DocumentLiteDTO } from "./VaccinationService";

export class TestsService {
    private baseURL: BaseURL = BaseURL.shared;
    private module: string = "/api/modern/test"

    public async fetchList(petID: string) {
        const token = this.baseURL.obtainHeader();
        return axios.get<Array<TestAPIDTO>>(
            this.baseURL.buildEndpoin(`${this.module}/pet/${petID}`),
            {
                headers: {
                    ...token
                }
            }
        );
    }

    public async fetchDetails(id: string) {
        const token = this.baseURL.obtainHeader();
        return axios.get<TestAPIDTO>(
            this.baseURL.buildEndpoin(`${this.module}/${id}`),
            {
                headers: {
                    ...token
                }
            }
        );
    }

    public async create(dto: TestCreateAPIDTO) {
        const token = this.baseURL.obtainHeader();

        return axios({
            method: "POST",
            url: this.baseURL.buildEndpoin(`${this.module}/`),
            headers: { ...token },
            data: { ...dto }
        });
    }

    public async update(dto: TestUpdateAPIDTO) {
        const token = this.baseURL.obtainHeader();

        return axios({
            method: "PUT",
            url: this.baseURL.buildEndpoin(`${this.module}/`),
            headers: { ...token },
            data: { ...dto }
        });
    }

    public async delete(id: string) {
        const token = this.baseURL.obtainHeader();

        return axios.delete(
            this.baseURL.buildEndpoin(`${this.module}/${id}`),
            {
                headers: {
                    ...token
                }
            }
        );
    }
}

export interface TestCreateAPIDTO {
    petID: string;
    name: string;
    results?: string;
    procedureDate: string;
}

export interface TestUpdateAPIDTO {
    id: string;
    name?: string;
    results?: string;
    procedureDate?: string
}

export interface TestAPIDTO {
    id: string;
    name: string;
    results?: string;
    procedureDate: string;
    documents: DocumentLiteDTO[];
    isEditable: Boolean;
}