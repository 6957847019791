// import process from "process";

import Cookies from "universal-cookie";
import { LoginResponse } from "./Authorization/AuthorizationService";
import i18next from "i18next";

const isDevelopment: boolean = false

function obtainBaseURL() {
    if (isDevelopment == true) {
        return "https://api.dev.carepets.ru"
    } else {
        return "https://api.carepets.ru"
    }
}

export class BaseURL {
    static shared = new BaseURL();

    cookies = new Cookies("carepet");
    baseURL = obtainBaseURL();

    buildEndpoin(endpoint: string) {
        return `${this.baseURL}/${endpoint}`
    }

    setToken(data: LoginResponse) {
        this.cookies.set("access-token", data.token, { path: "/", maxAge:  61536000 })
        this.cookies.set("userID", data.userID, { path: "/", maxAge: 61536000 })
    }

    obtainToken(): { token?: string, userID?: string } {
        return { 
            userID: this.cookies.get("userID"),
            token: this.cookies.get("access-token")
         }
    }

    obtainHeader() {
        return {
            "Authorization": "Bearer " + this.obtainToken().token 
        }
    }

    saveLanguage(language: string) {
        i18next.changeLanguage(language);

        this.cookies.set("language", language, { path: "/", maxAge: 61536000 });
    };

    obtainLanguage() {
        return this.cookies.get("language");
    }

    logout() {
        this.cookies.remove("access-token");
        this.cookies.remove("userID");
        window.location.reload();
        window.location.href = "/";
    }
}