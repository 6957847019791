import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { LandingModule, RootModule } from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';

import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { LandingView } from './Modules/LandingView/LandingView';
import { ErrorPageView } from './Modules/ErrorPageView/ErrorPageView';
import { AuthorizationService } from './Domain/Authorization/AuthorizationService';
import { Center, Loader } from '@mantine/core';
import { DashboardView } from './Modules/Dashboard/DashBoardViewModel';
import { PetListView } from './Modules/PetList/PetListView';
import { useEffect } from 'react';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { CPetDetailsView } from './Modules/PetDetails/CPetDetailsView';
import { MapView } from './Modules/Map/MapView';
import { ProfileView } from './Modules/Profile/ProfileView';
import { CLoginView } from './Modules/LandingView/Modern/CLoginView';

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootModule />,
    errorElement: <ErrorPageView />,
    children: [
      // {
      //   path: "/dashboard",
      //   element: <DashboardView />,
      // },
      {
        path: "/pets",
        element: <PetListView />
      },
      {
        path: "/map",
        element: <MapView />,
      },
      {
        path: "/ammunition",
        element: <div>ammunition</div>,
      },
      {
        path: "/profile",
        element: <ProfileView />,
      },
      {
        path: "/pets/:petID",
        element: <CPetDetailsView />
      }
    ]
  },
]);

const guestRouter = createBrowserRouter([
  {
    path: "/",
    element: <LandingView />,
    errorElement: <ErrorPageView />,
    children: [
      {
        path: "/login",
      },
      {
        path: "/register",
      }
    ]
  }
])

const loaderRouter = createBrowserRouter([
  {
    path: "/",
    element: <Center h={window.innerHeight}>
      <Loader size={`30px`} />
    </Center>,
    errorElement: <Center h={window.innerHeight}>
    <Loader size={`30px`} />
  </Center>
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export function chooseRouting(drop: boolean) {
};

function routeTo(modules: "landing" | "application" | "loading") {
  if (modules == "landing") {
    return(
      <RouterProvider router={guestRouter} />
    );
  }
  if (modules == "application") {
    return(
      <RouterProvider router={router} />
    )
  }
  if (modules == "loading") {
    return(
      <RouterProvider router={loaderRouter} />
    )
  }
}

function View() {
  const authService = new AuthorizationService();
  const [state, setState] = useState<"loading" | "application" | "landing">("loading");

  useEffect(() => {
    validate();
  }, []);
  
  function validate() {
    const result = authService.validate();

    result
      .catch(error => {
        console.log("need error")
      })
      .then(result => {
        if (result == true) {
          setState("application");
          return;
        } else {
          setState("landing");
        }
      })
  }
  
  const chooseView = () =>  {
    if (state == "loading") {
      return (<RouterProvider router={loaderRouter} />);
    }

    if (state == "application") {
      return (<RouterProvider router={router} />);
    }

    if (state == "landing") {
      return (<RouterProvider router={guestRouter} />)
    }

    return (<></>)
  };

  return(
     chooseView()
  )
}

root.render(
    <React.StrictMode>
      <MantineProvider withGlobalStyles withNormalizeCSS theme={{ 
        loader: 'oval',
        fontFamily: 'Montserrat, sans-serif',
      }}>
        <ModalsProvider>
        <Notifications />
        <View />
        </ModalsProvider>
      </MantineProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
