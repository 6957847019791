import { ActionIcon, Button, Group, Loader, Paper, Space, Stack, Text } from "@mantine/core";
import { IconFileFilled, IconSquareRoundedPlusFilled } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { DocumentDTO } from "../../Domain/Ambulance/AmbulanceService";
import { CDocumentViewerView } from "../CDocumentViewer/CDocumentViewerView";
import { useTranslation } from "react-i18next";
import { DocumentLiteDTO } from "../../Domain/Medical/VaccinationService";

export interface CFileListViewInterface {
    documents?: DocumentLiteDTO[];
    loading: boolean;
    update: () => void;
    addTapped: () => void;
} 

export function CFileListViewModel(infoModel: CFileListViewInterface) {
    const [viewerOpened, setViewerOpened] = useState(false);
    const [selected, setSelected] = useState<DocumentLiteDTO>();
    const { t } = useTranslation();
    const addButtonEnabled = infoModel.documents?.length != 0

    const moduleTitle = t("Documents");
    const loadingTitle = t("Loading");
    const emptyTitle = {
        title: t("No documents"),
        subtitle: t("You can add documents using the button below")
    };

    function itemTapped(documentDTO: DocumentLiteDTO) {
        setSelected(documentDTO);
        setViewerOpened(true);
    }

    return ({
        viewerOpened,
        selected,
        addButtonEnabled,
        moduleTitle,
        loadingTitle,
        emptyTitle,
        documents: infoModel.documents,
        loading: infoModel.loading,
        needUpdate: infoModel.update,
        setViewerOpened,
        addTapped: infoModel.addTapped,
        itemTapped
    });
}

export function CFileListView(infoModel: CFileListViewInterface) {
    const viewModel = CFileListViewModel(infoModel);
    const { t } = useTranslation();

    const title = (
        <Text color="dimmed" component="label" htmlFor="revaccineDateLabel" size="sm" weight={400}>
            { viewModel.moduleTitle }
        </Text>
    );

    const addButton = (
        <ActionIcon onClick={() => { viewModel.addTapped() }}>
            <IconSquareRoundedPlusFilled size={16}/>
        </ActionIcon>
    );

    const addButtonIfNeeded = () => {
        return viewModel.addButtonEnabled == true ? addButton : <></>
    };

    const loadView = (
        <Paper mb={25} p={20} pl={100} pr={100} radius={20} shadow="lg">
            <Group position="center" spacing="sm">
                <Loader size={`20px`}/>
                <Text
                    variant="gradient"
                    gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
                    sx={{ fontFamily: 'Greycliff CF, sans-serif' }}
                    ta="center"
                    fz="xl"
                    fw={900}
                >
                    { viewModel.loadingTitle } 
                </Text>
            </Group>
        </Paper>
    );

    const emptyView = (
        <Paper mb={25} p={20} pl={100} pr={100} radius={20} shadow="lg">
            <Stack spacing={12} align="center">
                <Text
                    variant="gradient"
                    gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
                    sx={{ fontFamily: 'Greycliff CF, sans-serif' }}
                    ta="center"
                    fz="xl"
                    fw={900}
                >
                    { viewModel.emptyTitle.title }
                </Text>
                <Text
                    sx={{ fontFamily: 'Greycliff CF, sans-serif' }}
                    ta="center"
                    fz="sm"
                    fw={600}
                    maw={300}
                    color="gray"
                >
                    { viewModel.emptyTitle.subtitle }
                </Text>
                <Button onClick={viewModel.addTapped} radius="md">{t("Add")}</Button>
            </Stack>
        </Paper>
    );

    const items = viewModel.documents?.map((item) => {
        return <CFFileListItemView item={item} setSelected={viewModel.itemTapped} />
    });

    const listView = (
        <>
            <Group pb={25}>{items}</Group>
            <CDocumentViewerView 
                update={viewModel.needUpdate}
                click={viewModel.viewerOpened}
                setClick={viewModel.setViewerOpened}
                file={viewModel.selected}
            />
        </>
    );

    const view = () => {
        if (viewModel.loading) {
            return loadView;
        }

        if (viewModel.documents && viewModel.documents.length == 0) {
            return emptyView;
        }

        return listView;
    };

    return (
        <>
            <Group>
                { title }
                { addButtonIfNeeded() }
            </Group>
            <Space h={8} />
            { view() }
        </>
    );
};

export interface CFFileListItemViewInterface {
    setSelected: (_: DocumentLiteDTO) => void;
    item: DocumentLiteDTO;
}

export function CFFileListItemView(infoModel: CFFileListItemViewInterface) {
    return (
        <Paper style={{cursor: "pointer"}} shadow="md" radius={20} withBorder
            onClick={() => {
                infoModel.setSelected(infoModel.item);
            }}
        >
            <Stack maw={180} p={8} pl={16} pr={16} spacing={4}>
                <IconFileFilled size={16} />
                <Text unselectable="off" align="left" truncate size="sm" weight={700}>{infoModel.item.name}</Text>
            </Stack>
        </Paper>
    );
};