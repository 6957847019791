import { useTranslation } from "react-i18next";
import { VaccineType } from "../../Domain/Ambulance/AmbulanceService";
import { DewormingAPIDTO, DewormingService } from "../../Domain/Medical/DewormingService";
import { VaccinationAPIDTO, VaccinationService } from "../../Domain/Medical/VaccinationService";
import { EctoparasitesAPIDTO, EctoparasitesService } from "../../Domain/Medical/EctoparasitesService";
import { AppointmentAPIDTO, AppointmentService } from "../../Domain/Medical/AppointmentService";
import { TestAPIDTO, TestsService } from "../../Domain/Medical/TestService";
import { NotesAPIDTO, NotesService } from "../../Domain/Medical/NotesService";
import { dateToHumanString } from "../../Core/Extensions/ConvertToDateFromTS";

export type CMedicalRepresentation = "mobile" | "desktop";
export type CMedicalTableDesktopData = {
    representation: CMedicalRepresentation;
    columns: CMedicalTableColumn[];
    rows: CMedicalTableRow[];
    isEmpty: boolean;
};
export type CMedicalTableMobileData = { representation: CMedicalRepresentation; items: CMedicalTableMobileDataItemModel[]; isEmpty: boolean; };
export type CMedicalTableMobileDataItemModel = {
    medicalID: string;
    title: string;
    content: { title: string; subtitle: string }[];
};

type CMedicalTableColumn = { title: string };
type CMedicalTableRow = { medicineID: string; rows: { value: string }[] };

interface CMedicalTableInteractorBuilder {
    petID: string
};

export function CMedicalTableInteractor(builder: CMedicalTableInteractorBuilder) {
    const vaccinationService = new VaccinationService();
    const dewormingService = new DewormingService();
    const ectoparasitesService = new EctoparasitesService();
    const appointmentService = new AppointmentService();
    const testService = new TestsService();
    const notesService = new NotesService();

    const { t } = useTranslation();

    function handleVaccineResult(result: VaccinationAPIDTO[], represent: CMedicalRepresentation) {
        switch (represent) {
            case "desktop":
                const data: CMedicalTableDesktopData = {
                    isEmpty: result.length == 0,
                    representation: represent,
                    columns: [ 
                        { title: t("Name of the vaccine") },
                        { title: t("Vaccine batch number") },
                        { title: t("Date of vaccination") },
                        { title: t("Date of revaccination") },
                    ],
                    rows: result.map((item) => {
                        const row: CMedicalTableRow = {
                            medicineID: item.id,
                            rows: [
                                { value: item.name },
                                { value: item.serial },
                                { value: dateToHumanString(item.takeover) ?? "-" },
                                { value: dateToHumanString(item.repeatVaccinationTakeover) ?? "-" },
                            ]
                        }

                        return row;
                    })
                }
                return data;

            case "mobile":
                const mobileData: CMedicalTableMobileData = {
                    representation: represent,
                    isEmpty: result.length == 0,
                    items: result.map((item) => (
                        {
                            medicalID: item.id,
                            title: item.name,
                            content: [
                                { subtitle: t("Vaccine number"), title: item.serial },
                                { subtitle: t("Date of vaccination"), title: dateToHumanString(item.takeover) ?? "-" }
                            ]
                        }
                    ))
                }
                return mobileData;
        }
    };

    function handleDewormingResult(result: DewormingAPIDTO[], representation: CMedicalRepresentation) {
        switch (representation) {
            case "desktop":
                const data: CMedicalTableDesktopData = {
                    representation: representation,
                    isEmpty: result.length == 0,
                    columns: [
                        { title: t("Name of the drug") },
                        { title: t("Date of taking") },
                        { title: t("Dosage") },
                        { title: t("Repeated medication intake") }
                    ],
                    rows: result.map((item) => {
                        const row: CMedicalTableRow = {
                            medicineID: item.id,
                            rows: [
                                { value: item.name },
                                { value: dateToHumanString(item.takeover) ?? "-" },
                                { value: item.dosage },
                                { value: dateToHumanString(item.repeatTakeover) ?? "-" }
                            ]
                        }

                        return row;
                    })
                }
                return data;

            case "mobile":
                const mobileData: CMedicalTableMobileData = {
                    representation: representation,
                    isEmpty: result.length == 0,
                    items: result.map((item) => (
                        {
                            medicalID: item.id,
                            title: item.name,
                            content: [
                                { subtitle: t("Dosage"), title: item.dosage },
                                { subtitle: t("Date of taking"), title: dateToHumanString(item.takeover) ?? "-" }
                            ]
                        }
                    ))
                }
                return mobileData;
        }
    };

    function handleEctoparasitesResult(result: EctoparasitesAPIDTO[], representation: CMedicalRepresentation) {
        switch (representation) {
            case "desktop":
                const data: CMedicalTableDesktopData = {
                    representation: representation,
                    isEmpty: result.length == 0,
                    columns: [ 
                        { title: t("Name of the drug") },
                        { title: t("Date of taking") },
                        { title: t("Validity period (in weeks)") },
                    ],
                    rows: result.map((item) => {
                        const row: CMedicalTableRow = {
                            medicineID: item.id,
                            rows: [
                                { value: item.name },
                                { value: dateToHumanString(item.takeover) ?? "-" },
                                { value: item.validWeeks.toString() },
                            ]
                        }

                        return row;
                    })
                }
                return data;

            case "mobile":
                const mobileData: CMedicalTableMobileData = {
                    representation: representation,
                    isEmpty: result.length == 0,
                    items: result.map((item) => (
                        {
                            medicalID: item.id,
                            title: item.name,
                            content: [
                                { subtitle: t("Validity period (in weeks)"), title: item.validWeeks.toString() },
                                { subtitle: t("Date of taking"), title: dateToHumanString(item.takeover) ?? "-" }
                            ]
                        }
                    ))
                }
                return mobileData;
        }
    };

    function handleExaminationResult(result: AppointmentAPIDTO[], representation: CMedicalRepresentation) {
        switch (representation) {
            case "desktop":
                const data: CMedicalTableDesktopData = {
                    representation: representation,
                    isEmpty: result.length == 0,
                    columns: [
                        { title: t("The reason for the appeal") },
                        { title: t("Date of admission") },
                    ],
                    rows: result.map((item) => {
                        const row: CMedicalTableRow = {
                            medicineID: item.id,
                            rows: [
                                { value: item.reason },
                                { value: dateToHumanString(item.appointmentDate) ?? "-" },
                            ]
                        }

                        return row;
                    })
                }
                return data;

            case "mobile":
                const mobileData: CMedicalTableMobileData = {
                    representation: representation,
                    isEmpty: result.length == 0,
                    items: result.map((item) => (
                        {
                            medicalID: item.id,
                            title: item.reason,
                            content: [
                                { subtitle: t("Date of admission"), title: dateToHumanString(item.appointmentDate) ?? "-" }
                            ]
                        }
                    ))
                }
                return mobileData;
        }
    };

    function handleTestsResult(result: TestAPIDTO[], representation: CMedicalRepresentation) {
        switch (representation) {
            case "desktop":
                const data: CMedicalTableDesktopData = {
                    representation: representation,
                    isEmpty: result.length == 0,
                    columns: [
                        { title: t("Name of the test") },
                        { title: t("Testing date") },
                    ],
                    rows: result.map((item) => {
                        const row: CMedicalTableRow = {
                            medicineID: item.id,
                            rows: [
                                { value: item.name },
                                { value: dateToHumanString(item.procedureDate) ?? "-" },
                            ]
                        }

                        return row;
                    })
                }
                return data;

            case "mobile":
                const mobileData: CMedicalTableMobileData = {
                    representation: representation,
                    isEmpty: result.length == 0,
                    items: result.map((item) => (
                        {
                            medicalID: item.id,
                            title: item.name,
                            content: [
                                { subtitle: t("Testing date"), title: dateToHumanString(item.procedureDate) ?? "-" }
                            ]
                        }
                    ))
                }
                return mobileData;
        }
    };

    function handleNotesResult(result: NotesAPIDTO[], representation: CMedicalRepresentation) {
        switch (representation) {
            case "desktop":
                const data: CMedicalTableDesktopData = {
                    representation: representation,
                    isEmpty: result.length == 0,
                    columns: [
                        { title: t("Text of the note") },
                        { title: t("Date of the note") },
                    ],
                    rows: result.map((item) => {
                        const row: CMedicalTableRow = {
                            medicineID: item.id,
                            rows: [
                                { value: item.note },
                                { value: dateToHumanString(item.created) ?? "-" },
                            ]
                        }

                        return row;
                    })
                }
                return data;

            case "mobile":
                const mobileData: CMedicalTableMobileData = {
                    representation: representation,
                    isEmpty: result.length == 0,
                    items: result.map((item) => (
                        {
                            medicalID: item.id,
                            title: item.note,
                            content: [
                                { subtitle: t("Date of the note"), title: dateToHumanString(item.created) ?? "-" }
                            ]
                        }
                    ))
                }
                return mobileData;
        }
    };

    async function obtainVaccine(vaccineType: VaccineType, represent: CMedicalRepresentation) {
        return vaccinationService.fetchList(builder.petID)
            .catch(error => {
                throw error;
            })
            .then(result => {
                if (!result) {
                    throw Error("no result");
                }

                return handleVaccineResult(result.data.filter((item) => item.type === vaccineType), represent);
            });
    };

    async function obtainDeworming(represent: CMedicalRepresentation) {
        return dewormingService.fetchList(builder.petID)
            .then((result) => { 
                if (!result) {
                    throw Error("error data");
                }
                return handleDewormingResult(result.data, represent);
             })
            .catch((error) => { throw error })
    };

    async function obtainEctoparasites(represent: CMedicalRepresentation) {
        return ectoparasitesService.fetchList(builder.petID)
            .then((result) => { 
                if (!result) {
                    throw Error("error data");
                }
                return handleEctoparasitesResult(result.data, represent);
             })
            .catch((error) => { throw error })
    };

    async function obtainTests(represent: CMedicalRepresentation) {
        return testService.fetchList(builder.petID)
            .then((result) => { 
                if (!result) {
                    throw Error("error data");
                }
                return handleTestsResult(result.data, represent);
             })
            .catch((error) => { throw error })
    };

    async function obtainExamination(represent: CMedicalRepresentation) {
        return appointmentService.fetchList(builder.petID)
            .then((result) => { 
                if (!result) {
                    throw Error("error data");
                }
                return handleExaminationResult(result.data, represent);
             })
            .catch((error) => { throw error })
    };

    async function obtainNotes(represent: CMedicalRepresentation) {
        return notesService.fetchList(builder.petID)
            .then((result) => { 
                if (!result) {
                    throw Error("error data");
                }
                return handleNotesResult(result.data, represent);
             })
            .catch((error) => { throw error })
    };

    return ({
        obtainVaccine,
        obtainDeworming,
        obtainEctoparasites,
        obtainExamination,
        obtainNotes,
        obtainTests
    })
};