import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { CareFileInputViewInterface } from "./CareFileInputView";
import { useTranslation } from "react-i18next";

export function CareFileInputViewModel(infoModel: CareFileInputViewInterface) {
    const { t } = useTranslation();
    const [files, setFiles] = useState<File[]>([]);
    const saveButtonEnabled = infoModel.saveEnabled && infoModel.saveEnabled == true;

    function filesChangeHandler(newValue: File[]) {
        const set = new Set(newValue.concat(files));
        var array = Array.from(set).slice(0, 5);

        if (infoModel.uploadedDocuments && infoModel.uploadedDocuments.length + array.length > 5) {
            notifications.show({
                title: t("Error"),
                message: t("The total number of documents exceeds 5"),
                color: "red",
                autoClose: true
            });
            return;
        }

        setFiles(array);
        infoModel.filesDidChange(array);
    };

    function buttonSavedTapped() {
        if (infoModel.saveTapped) {
            infoModel.saveTapped();
        }
    }

    return ({
        files,
        saveButtonEnabled,
        filesChangeHandler,
        buttonSavedTapped
    });
}