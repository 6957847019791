import axios from "axios";
import { BaseURL } from "../BasicURLScheme";

export class VaccinationService {
    private baseURL: BaseURL = BaseURL.shared;
    private module: string = "/api/modern/vaccination";

    public async fetchList(petID: string) {
        const token = this.baseURL.obtainHeader();
        return axios.get<Array<VaccinationAPIDTO>>(
            this.baseURL.buildEndpoin(`${this.module}/pet/${petID}`),
            {
                headers: {
                    ...token
                }
            }
        );
    }

    public async fetchDetails(id: string) {
        const token = this.baseURL.obtainHeader();
        return axios.get<VaccinationAPIDTO>(
            this.baseURL.buildEndpoin(`${this.module}/${id}`),
            {
                headers: {
                    ...token
                }
            }
        );
    }

    public async create(dto: VaccinationCreateAPIDTO) {
        const token = this.baseURL.obtainHeader();

        return axios<VaccinationAPIDTO>({
            method: "POST",
            url: this.baseURL.buildEndpoin(`${this.module}/`),
            headers: { ...token },
            data: { ...dto }
        });
    }

    public async update(dto: VaccinationUpdateAPIDTO) {
        const token = this.baseURL.obtainHeader();

        return axios({
            method: "PUT",
            url: this.baseURL.buildEndpoin(`${this.module}/`),
            headers: { ...token },
            data: { ...dto }
        });
    }

    public async delete(id: string) {
        const token = this.baseURL.obtainHeader();

        return axios.delete(
            this.baseURL.buildEndpoin(`${this.module}/${id}`),
            {
                headers: {
                    ...token
                }
            }
        );
    }
}

export interface DocumentLiteDTO {
    id: string;
    name: string;
    extension: string;
}

export interface VaccinationAPIDTO {
    id: string;
    serial: string;
    name: string;
    takeover: string;
    repeatVaccinationTakeover?: string;
    expiration?: string;
    type: string;
    documentDTOs: DocumentLiteDTO[]
    isEditable: Boolean;
};

export interface VaccinationCreateAPIDTO {
    petID: string;
    serial: string;
    name: string;
    takeover: string;
    repeatVaccinationTakeover?: string;
    expiration?: String
    type: String,
}

export interface VaccinationUpdateAPIDTO {
    id: string;
    serial?: string;
    name?: String;
    takeover?: string;
    repeatVaccinationTakeover?: string;
    expiration?: string;
    type?: string;
}