import { Box, rem, Space, Transition } from "@mantine/core";

interface CTabsListItemBuilder {
    selected: boolean;
    title: string;
    didSelected: (() => void);
};

export function CTabsListItemView(builder: CTabsListItemBuilder) {
    return (
        <Box sx={(theme) => ({
            fontWeight: 800,
            cursor: "pointer",
            paddingBottom: 0,
            paddingLeft: theme.spacing.sm,
            paddingRight: theme.spacing.sm,
            paddingTop: 0,
            borderRadius: 10,
            whiteSpace: "nowrap"
        })} onClick={builder.didSelected}>
            {builder.title}
            <Space h={2} />
            <Transition mounted={builder.selected} transition="slide-up" duration={200} timingFunction="ease">
                {(styles) => <div style={styles}><Box sx={(theme) => ({
               backgroundColor: "dark",
               marginLeft: `${rem(5)}`,
               marginRight: `${rem(5)}`,
               height: `${rem(4)}`,
               borderTopLeftRadius: `${rem(2)}`,
               borderTopRightRadius: `${rem(2)}`,
            })} bg="dark" hidden={!builder.selected}></Box></div>}
            </Transition>
            <Box sx={(theme) => ({
               backgroundColor: "dark",
               marginLeft: `${rem(5)}`,
               marginRight: `${rem(5)}`,
               height: `${rem(4)}`,
               borderTopLeftRadius: `${rem(2)}`,
               borderTopRightRadius: `${rem(2)}`,
            })} hidden={builder.selected}></Box>
        </Box>
    );
};