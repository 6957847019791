import axios from "axios";
import { BaseURL } from "../BasicURLScheme";
import { PetDTO } from "../Pets/PetService";
import { LoginResponse } from "../Authorization/AuthorizationService";

export class UserService {
    private baseURL: BaseURL = BaseURL.shared;
    
    public async obtainUser() {
        const token = this.baseURL.obtainHeader();

        return axios.get<UserDTO>(
            this.baseURL.buildEndpoin(`api/modern/user/me?withPets=true`),
            {
                headers: {
                    ...token
                }
            }
        )
    }

    public async updateUser(userDTO: UserClientDTO) {
        const token = this.baseURL.obtainHeader();

        const promise = axios.put<UserDTO>(
            this.baseURL.buildEndpoin(`api/modern/user/update`), userDTO,
            {
                headers: {
                    ...token
                }
            }
        )

        promise
        .then((result) => {
            if (!result) { return; }
            if (result.data.token) {
                const loginResponse: LoginResponse = { userID: result.data.id, token: result.data.token }
                this.baseURL.setToken(loginResponse)
            }
        })

        return promise
    }

    public async updateAvatar(file: File) {
        const token = this.baseURL.obtainHeader();

        const formData = new FormData();
        const blob = new Blob([file], {
            type: `${file.type}`,
        });

        formData.append(`avatar`, blob, "avatar");

        return axios.put(this.baseURL.buildEndpoin(`api/modern/user/update/avatar`), formData, {
            headers: { 
                ...token,
            }
        });
    };
}

export interface UserClientDTO {
    name?: string;
    phone?: string;
    email?: string;
}

export interface UserDTO {
    id: string;
    email: string;
    name?: string;
    locale?: String;
    imageID?: string;
    phone?: string;
    pets: PetDTO[];
    token?: string;
}