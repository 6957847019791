import { Center, Container, createStyles, Loader, SimpleGrid, Stack, Title, Text, Button, rem, Space, Paper, Group, ActionIcon, Transition } from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CGradientView } from "../../Core/CGradientView/CGradientView";
import { CModalProvider } from "../../Core/CModalProvider/CModalProvider";
import { DataObservable } from "../../Core/DataObservable";
import { broofa } from "../../Resources/Helpers/uuidv4";
import { ErrorPlaceholderView } from "../Placeholders/ErrorPlaceholderView/ErrorPlaceholderView";
import useWindowDimensions from "../Root/useWindowDimensions";
import { UserInfoIcons } from "./PetListItemView";
import { PetListViewModel } from "./PetListViewModel";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
    wrapper: {
      marginTop: 0,
      margin: 0,
      padding: 0
    },

    title: {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontSize: 24,
    },

    placeholderSubtitle: {
        lineHeight: 1,
        fontWeight: 800,
        fontSize: rem(20),
        [theme.fn.smallerThan("xs")]: {
            fontSize: rem(16),
        },
    },

    placeholderTitle: {
        lineHeight: 1,
        fontWeight: 800,
        fontSize: rem(24),
        [theme.fn.smallerThan("xs")]: {
            fontSize: rem(20),
        },
    },

    card: {
        width: "50%",

        [theme.fn.smallerThan("md")]: {
            width: "80%",
        },
    }
}));

const petListViewObservableID = broofa();

export function PetListView() {
    const {
        state,
        obtainPets,
        content
    } = PetListViewModel();

    const { classes } = useStyles();
    const navigate = useNavigate();
    const { height } = useWindowDimensions();
    const { t } = useTranslation();
    const modalProvider = CModalProvider();
    const [mounted, _mounted] = useState(false);
    const listMounted = state == "loaded";


    function routeToCreatePet() {
        DataObservable.getInstance().addObserver({
            observableID: petListViewObservableID,
            type: "petCreated",
            action: () => { obtainPets() }
        })
        modalProvider.openCreatePetCard();
    };

    useEffect(() => {
        obtainPets();
        _mounted(true);
    }, [])

    const items = content.map((data, index) => (
        <UserInfoIcons key={data.id} id={data.id} avatar={data.avatar} name={ data.name } weight={ data.weight } height={ data.height } birsday={ data.birsday } onTapped={ data.onTapped }></UserInfoIcons>
    ))

    const emptyContent = {
        title: t("There are no pets"),
        subtitle: t("We don't know anything about your pets. Create his profile with us.")
    };

    const errorContent = {
        title: t("Loading error"),
        subtitle: t("Failed to load your pets, try again")
    };

    const emptyStateView = (
        <Stack spacing={8} style={{borderRadius: 20}} p={20} justify={"center"} align="flex-start" mt={10} w={"100%"} h={"100%"}>
            <Text className={classes.placeholderTitle}>{emptyContent.title}</Text>
            <Text className={classes.placeholderSubtitle} color="gray">{emptyContent.subtitle}</Text>
            <Space h={6} />
            <Button color="dark" onClick={routeToCreatePet} radius="md">{t("Create a pet")}</Button>
        </Stack>
    );

    const errorStateView = (
        <Stack spacing={8} style={{borderRadius: 20}} p={20} justify={"center"} align="flex-start" mt={10} w={"100%"} h={"100%"}>
            <Text className={classes.placeholderTitle}>{errorContent.title}</Text>
            <Text className={classes.placeholderSubtitle} color="gray">{errorContent.subtitle}</Text>
            <Space h={6} />
            <Button color="dark" onClick={obtainPets} radius="md">{t("Update")}</Button>
        </Stack>
    );

    const mainView = () => {
        switch (state) {
            case "empty":
                return (
                    <Center w={"100%"} h={height - 120}>
                        <Paper withBorder radius="lg" className={classes.card}>
                            { emptyStateView }
                        </Paper>
                    </Center>
                );

            case "error":
                return (
                    <Center w={"100%"} h={height - 120}>
                        <Paper withBorder radius="lg" className={classes.card}>
                            { errorStateView }
                        </Paper>
                    </Center>
                )

            case "loaded":
                return <Transition mounted={listMounted} transition="fade" duration={400} timingFunction="ease">
                    {(styles) => <div style={{...styles}}>
                        <SimpleGrid cols={1}>
                            { items }
                        </SimpleGrid>
                    </div>}
                </Transition>

            case "loading":
                return <Transition mounted={state == "loading"} transition="fade" duration={400} timingFunction="ease">
                    {(styles) => <div style={{height: "100%", ...styles}}>
                    <Center h={"100%"}>
                        <Loader size={`30px`} />
                    </Center>
                    </div>}
                </Transition>
        }
    }

    return (
        <>
            <Container className={classes.wrapper} h={"100%"} fluid={true}>
            <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
                    {(styles) => <div style={{height: "100%", ...styles}}>
                <Group>
                    <Title className={classes.title}>{t("My pets")}</Title>
                    <ActionIcon onClick={routeToCreatePet} variant="transparent" color="blue" size="lg" radius="xl"><IconCirclePlus /></ActionIcon>
                </Group>
                <Space h={20} />
                { mainView() }
                { modalProvider.modalProvider }
                { modalProvider.drawerProvider }
                </div>}
            </Transition>  
            </Container>
        </>
    ) 
}