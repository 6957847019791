import { Avatar, Box, Button, Center, Container, Grid, Group, Loader, Paper, SimpleGrid, Space, Stack, Text, TextInput, Title, Transition, createStyles, rem } from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import { useEffect, useState } from "react";
import placeholder_image from "../../Resources/Assets/square.svg";
import { UserDTO, UserService } from "../../Domain/UserService/UserServiceDTO";
import { CHoverShadow } from "../../Core/CHoverShadow/CHoverShadow";
import { PlaceCountDTO, PlaceService } from "../../Domain/Places/PlaceService";
import { CModalProvider } from "../../Core/CModalProvider/CModalProvider";
import { FileService } from "../../Domain/FileService/FileService";
import { ToastManager } from "../Map/ToastManager";
import { t } from "i18next";

interface ProfileCardViewBuilder {
    avatar: any;
    userDTO: UserDTO | undefined;
    editButtonTapepd: () => void;
}

function ProfileCardView(builder: ProfileCardViewBuilder) {
    const imageHeight = 150;

    const { classes } = createStyles((theme) => ({
        card: {
            backgroundImage: theme.colorScheme === 'dark' ? 
                theme.fn.linearGradient(323, "rgba(2,0,36,1) 0%", "rgba(45,132,149,1) 100%") :
                theme.colors.gray[0],
            ...CHoverShadow(theme)
        },
    }))();

    return (
        <Paper p="lg" withBorder radius="lg" className={classes.card}>
            <Stack align="center" spacing={12}>
            <Avatar
                radius={imageHeight / 2}
                size={imageHeight}
                src={builder.avatar ?? placeholder_image}
            />
            <Group>
                <Text size={18} weight={600}>{builder.userDTO?.name}</Text>
            </Group>
            </Stack>
            <Space h={12} />
            <Stack spacing={4}>
                <Text
                    size="sm"
                    weight={600}
                    color="dimmed"
                >
                    {t("Email")}
                </Text>
                <Text weight={600} size={16}>{builder.userDTO?.email ?? t("Unknown")}</Text>
            </Stack>
            <Space h={12} />
            <Stack spacing={4}>
                <Text
                    size="sm"
                    weight={600}
                    color="dimmed"
                >
                    {t("Phone number")}
                </Text>
                <Text weight={600} size={16}>{builder.userDTO?.phone ?? t("Unknown")}</Text>
            </Stack>
            <Space h={12} />
            <Group position="center">
                <Button onClick={builder.editButtonTapepd} h={40} radius="md">{t("Change profile")}</Button>
            </Group>
        </Paper>
    )
}

interface ProfileViewModelBuilder {
}

function ProfileViewModel(builder: ProfileViewModelBuilder) {
    type ViewState = "loaded" | "error" | "loading";

    const userService = new UserService();
    const placeService = new PlaceService();
    const fileService = new FileService();
    const [viewState, _viewState] = useState<ViewState>("loading");

    const [avatar, _avatar] = useState<any | null>(null);

    const modalProvider = CModalProvider();

    const [userDTO, _userDTO] = useState<UserDTO>();
    const [countDTO, _countDTO] = useState<PlaceCountDTO>();

    const errorContent = {
        title: t("Loading error"),
        subtitle: t("Failed to upload your pets, try again")
    };

    function editButtonTapped() {
        modalProvider.openProfileEditCard({
            update: () => { reloadData() },
            userDTO: userDTO!,
            avatar: avatar
        });
    }

    useEffect(() => {
        reloadData();
    }, []);

    function reloadData() {
        _viewState("loading");

        userService.obtainUser()
        .then((result) => {
            if (!result.data) { return; };
            _viewState("loaded");
            obtainAvatar(result.data.imageID);
            _userDTO(result.data);
        })
        .catch((error) => {
            ToastManager.getInstance().showError(t("Failed to load profile"));
            _viewState("error");
        })

        placeService.obtainCount()
        .then((result) => {
            if (!result.data) { return; };

            _countDTO(result.data);
        })
        .catch((error) => {
            console.log(error);
        })
    }

    function obtainAvatar(imageID: string | undefined) {
        if (!imageID) { 
            return;
        }

        fileService.obtainFile(imageID)
            .then(result => {
                _avatar(result);
            })
            .catch(error => {
                _avatar(null);
            })
    };

    return ({
        userDTO,
        countDTO,
        avatar,
        viewState,
        errorContent,
        mobileView: modalProvider.drawerProvider,
        desktopView: modalProvider.modalProvider,
        editButtonTapped,
        reloadData
    })
}

export function DashboardItemView({subtitle, title}: {subtitle: string; title: string}) {
    return (
        <Stack spacing={4} bg="dark" p={8} pl={12} pr={12} style={{borderRadius: 20}}>
            <Text color="white" weight={500}>{subtitle}</Text>
            <Text size={18} color="white" weight={700}>{title}</Text>
        </Stack>
    )
}

interface ProfileDashboardViewBuilder {
    countDTO: PlaceCountDTO | undefined;
    petsCount: number;
}
export function ProfileDashboardView(builder: ProfileDashboardViewBuilder) {
    const { classes } = createStyles((theme) => ({
        wrapper: {
            padding: 0,
        },

        title: {
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontSize: 24,
            marginBottom: 20,
        },

        borders: {
            borderRadius: theme.radius.lg
        },

        card: {
            backgroundImage: theme.colorScheme === 'dark' ? 
                theme.fn.linearGradient(323, "rgba(2,0,36,1) 0%", "rgba(45,132,149,1) 100%") :
                theme.colors.gray[0],
            ...CHoverShadow(theme)
        }
    }))();

    const items = (
        <>
            <DashboardItemView subtitle={t("Pets on the platform:")} title={builder.petsCount.toString()} />
            <DashboardItemView subtitle={t("Total places created:")} title={builder.countDTO?.all.toString() ?? "-"} />
            <DashboardItemView subtitle={t("Dangerous places have been created:")} title={builder.countDTO?.danger.toString() ?? "-"} />
            <DashboardItemView subtitle={t("Safe places have been created:")} title={builder.countDTO?.safe.toString() ?? "-"} />
        </>
    )

    return (
        <Paper p="lg" withBorder radius="lg" className={classes.card}>
            <Title className={classes.title}>{t("Dashboard")}</Title>
            <Group>
                { items }
            </Group>
        </Paper>
    )
}

export function ProfileView() {
    const { classes } = createStyles((theme) => ({
        wrapper: {
            padding: 0,
        },

        title: {
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontSize: 24,
            marginBottom: 20,
        },

        borders: {
            borderRadius: theme.radius.lg
        },

        placeholderSubtitle: {
            lineHeight: 1,
            fontWeight: 800,
            fontSize: rem(20),
            [theme.fn.smallerThan("xs")]: {
                fontSize: rem(16),
            },
        },
    
        placeholderTitle: {
            lineHeight: 1,
            fontWeight: 800,
            fontSize: rem(24),
            [theme.fn.smallerThan("xs")]: {
                fontSize: rem(20),
            },
        },

        card: {
            width: "50%",
    
            [theme.fn.smallerThan("md")]: {
                width: "80%",
            },
        }
    }))();

    const viewModel = ProfileViewModel({});

    const [mounted, _mounted] = useState(false);
    useEffect(() => {
        _mounted(true);
    }, []);

    const errorStateView = (
        <Stack spacing={8} style={{borderRadius: 20}} p={20} justify={"center"} align="flex-start" mt={10} w={"100%"} h={"100%"}>
            <Text className={classes.placeholderTitle}>{viewModel.errorContent.title}</Text>
            <Text className={classes.placeholderSubtitle} color="gray">{viewModel.errorContent.subtitle}</Text>
            <Space h={6} />
            <Button color="dark" onClick={viewModel.reloadData} radius="md">{t("Update")}</Button>
        </Stack>
    );

    const mainView = () => {
        switch (viewModel.viewState) {
            case "error":
                return (
                    <Center w={"100%"} h={"100%"}>
                        <Paper withBorder radius="lg" className={classes.card}>
                            { errorStateView }
                        </Paper>
                    </Center>
                )

            case "loaded":
                return (
                    <Grid>
                    <Grid.Col md={4} sm={12}>
                        <ProfileCardView avatar={viewModel.avatar} editButtonTapepd={viewModel.editButtonTapped} userDTO={viewModel.userDTO} />
                    </Grid.Col>
                    <Grid.Col md={8} sm={12}>
                        <ProfileDashboardView countDTO={viewModel.countDTO} petsCount={viewModel.userDTO?.pets.length ?? 0} />
                    </Grid.Col>
                    </Grid>
                )

            case "loading":
                return (
                    <Center h={"100%"}>
                        <Loader size={`30px`} />
                    </Center>
                )
        }
    }

    return (
        <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
            {(styles) => 
        <div style={{height: "100%", ...styles}}>
            <Container className={classes.wrapper} pb="sm" size="lg">
                <Title className={classes.title}>{t("Profile")}</Title>
                {mainView()}
            </Container>
            { viewModel.mobileView }
            { viewModel.desktopView }
        </div>
        }
        </Transition>  
    )
}