import { DateInput, DateInputProps, DatePicker, DatePickerInput, DatePickerInputProps, DatePickerProps } from '@mantine/dates'
import React from 'react'

/**
 * Takes any datetime date and returns the date (as datetime) in UTC
 * This removes time of day and timezone data regardless of the timezone data was entered (i.e. via DatePicker)
 * @param date: Date - input datetime which may have a time or timezone set
 */
export const toUTCDate = (date: Date | null | undefined): Date | undefined => {
  if (!date) return undefined
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
}

export function fromUTCDate(date: Date): Date
export function fromUTCDate(date: null | undefined): undefined
export function fromUTCDate(date: Date | null | undefined): Date | undefined
export function fromUTCDate(date: Date | null | undefined): Date | undefined {
  if (!date) return undefined
  // some dates were set in PT and ET midnight
  // this will still work for those dates because their midnights occur after GMT
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
}

export const DateLargePickerUTC = React.forwardRef<HTMLInputElement, DatePickerProps>(
  ({ onChange, value, ...props }: DatePickerProps, ref) => {
    return (
      <DatePicker
        {...props}
        ref={ref}
        onChange={
          onChange &&
          ((v) => {
            onChange(toUTCDate(v) ?? null)
          })
        }
        value={fromUTCDate(value)}
      />
    )
  }
)