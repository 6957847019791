import { Button, Group, Text, Image, rem, Title, Stack, SimpleGrid, Paper, Grid, MediaQuery, BackgroundImage, Container, Space, Transition, Center, Card, Box, Overlay, createStyles } from "@mantine/core";
import header_logo from "../../../Resources/Assets/web_logo.svg";
import header_main_photo from "../../../Resources/Assets/ic_doggo.jpg";
import app_store from "../../../Resources/Assets/app_store.svg";
import { Route, Routes, useNavigate } from "react-router-dom";
import { CLoginView } from "./CLoginView";
import { useEffect, useState } from "react";
import { CRegisterView } from "./CRegisterView";
import { useTranslation } from "react-i18next";
import { LanguagePicker } from "../../../Core/CLanguageSelect/CLanguageSelect";

export const CLandingHeader: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
      <Group p={8} pt={20} pb={8} noWrap position="apart">
        <Group spacing={rem(60)}>
            <Image src={header_logo} width={60}></Image>
        </Group>
        <Group noWrap>
            <Button onClick={() => { navigate("/login") }} radius="md">{t("Login")}</Button>
            <Button onClick={() => { navigate("/register") }} radius="md">{t("Register")}</Button>
            <LanguagePicker />
        </Group>
      </Group>
    )
};

export const CLandingSlognView: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [mounted, _mounted] = useState(false);
    useEffect(() => { 
        _mounted(true);
    }, []);

    const { classes } = createStyles((theme) => ({
        title: {
            fontSize: 200,

            [theme.fn.smallerThan("xs")]: {
                fontSize: rem(100),
            },
        }, 

        subtitle: {
            fontSize: 48,

            [theme.fn.smallerThan("xs")]: {
                fontSize: rem(32),
            },
        }, 
    }))();

    const slogan = (
        <Stack h={"100%"} justify="center" spacing={0}>
                <Text
                    className={classes.title}
                    style={{lineHeight: 1}}
                    weight={900}
                    color={"#3A9C71"}
                    >
                        {"Care"}
                    </Text>
                <Text
                    className={classes.subtitle}
                    weight={800}
                    style={{lineHeight: 1.1}}
                    size={48}>{t("Keep your furry friends safe and healthy with Care!")}
                </Text>
                <Space h={20}/>
                <Group grow={false} position="left">
                    <Image style={{cursor: "pointer"}} onClick={() => {
                          window.open('https://apps.apple.com/ru/app/care-your-pets/id1585243155?l=en', '_blank');
                    }} height={38} width="auto" fit="contain" src={app_store} />
                    <Text
                        weight={900}
                        style={{lineHeight: 1.1}}
                        size={20}
                        color="dimmed">{t("or")}
                    </Text>
                    <Button h={38} onClick={() => { 
                        navigate("/register")
                     }} variant="default" color="dark">{t("Continue on web")}</Button>
                </Group>
            </Stack>
    );

    return (
        <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => <div style={{height: "100%", ...styles}}>
            { slogan }
        </div>}
        </Transition>  
    )
}

export const CLandingMainSection: React.FC = () => {
    const regularView = (
            <Grid columns={12} h={"100%"} pl={20} pr={20}>
            <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Routes>
                    <Route path="/" element={<CLandingSlognView />} />
                    <Route path="/login" element={<CLoginView />} />
                    <Route path="/register" element={<CRegisterView />} />
                </Routes>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Group position="right">
                <Image radius={20} fit="contain" src={header_main_photo} />
                </Group>
            </Grid.Col>
        </Grid>
    );

    const { classes } = createStyles((theme) => ({
        mainHeight: {
            height: 900,

            [theme.fn.smallerThan("xs")]: {
                height: 600
            },
        } 
    }))();

    const compactView = (
        <BackgroundImage ml={2} mr={2} p={12} radius={20} className={classes.mainHeight} src={header_main_photo}>
            <Stack align="center" justify="flex-end" h={"100%"}>
                <Stack>
                <Routes>
                    <Route path="/" element={<CLandingSlognView />} />
                    <Route path="/login" element={
                        <Paper bg="white" w={"100%"} radius={20} p={10}>
                            <CLoginView />
                        </Paper>
                    } />
                    <Route path="/register" element={
                        <Paper bg="white" w={"100%"} radius={20} p={10}>
                            <CRegisterView />
                        </Paper>
                    } />
                </Routes>
                </Stack>
            </Stack>
        </BackgroundImage>
    );

    return (
        <Group w={"100%"} noWrap position="apart" pt={20} pb={20}>
             <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
                { regularView }
             </MediaQuery>
             <MediaQuery largerThan="md" styles={{ display: 'none' }}>
                { compactView }
             </MediaQuery>
        </Group>
    )
}