import { createStyles, Image, Container, Title, Text, Button, SimpleGrid, Center } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import image from '../../Resources/Assets/s-4-img-2@2x.png';
import useWindowDimensions from '../Root/useWindowDimensions';

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },

  title: {
    fontWeight: 900,
    fontSize: 34,
    marginBottom: theme.spacing.md,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  control: {
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },

  mobileImage: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  desktopImage: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 18,
  }
}));

export function ErrorPageView() {
  const { classes } = useStyles();
  const { height } = useWindowDimensions();
  const { t } = useTranslation();
  const navigate  = useNavigate();

  const buttonTapped = () => {
    navigate("/");
  };

  return (
    <Container className={classes.root} h={height}>
        <Center h={height}>
      <SimpleGrid spacing={80} cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1, spacing: 40 }]}>
        <Image src={image} className={classes.mobileImage} />
        <Center>
        <div>
          <Title className={classes.title}>404</Title>
          <Text color="dimmed" size="lg">
            {t("There is no such page :(")}
          </Text>
          <Text color="dimmed" size="lg">
            {t("If you think it should definitely be, write to us:")} {" "}
            <a className={classes.link} href="https://t.me/carepetsapp">t.me/carepetsapp</a>
          </Text>
          <Button onClick={buttonTapped} variant="outline" size="md" mt="xl" className={classes.control}>
            {t("Back home")}
          </Button>
        </div>
        </Center>
        <Center>
            <Image src={image} className={classes.desktopImage} />
        </Center>
      </SimpleGrid>
      </Center>
    </Container>
  );
}