import { Button, FileInput, Space } from "@mantine/core";
import { IconUpload } from '@tabler/icons-react';
import { CareFileInputViewModel } from "./CareFileInputViewModel";
import { CareFileInputItemViewComponent } from "./CareFileInputItemViewComponent";
import { useTranslation } from "react-i18next";
import { DocumentLiteDTO } from "../../Domain/Medical/VaccinationService";

export interface CareFileInputViewInterface {
    accept: string;
    description: string;
    placeholder: string;
    saveEnabled?: boolean;
    uploadedDocuments?: DocumentLiteDTO[];
    filesDidChange: (files: File[]) => void;
    saveTapped?: () => void;
}; 

export function CareFileInputView(infoModel: CareFileInputViewInterface) {
    const { t } = useTranslation();
    
    const viewModel = CareFileInputViewModel(infoModel);

    const buttonIfNeeded = () => {
        if (viewModel.saveButtonEnabled) {
            return (
                <>
                    <Space h={12}/>
                    <Button radius="md" onClick={() => { viewModel.buttonSavedTapped() }}>{t("Save")}</Button>
                </>
            )
        }
  
        return <></>
    }  

    return (
        <>
        <FileInput
            value={viewModel.files}
            onChange={(files) => { viewModel.filesChangeHandler(files) }}
            valueComponent={CareFileInputItemViewComponent}
            accept={infoModel.accept}
            description={infoModel.description}
            placeholder={infoModel.placeholder}
            icon={<IconUpload size={"14px"} />}
            radius="md"
            size="sm"
            multiple
        />

        { buttonIfNeeded() }
        </>
    );
};