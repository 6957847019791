import { Container, TextInput, Stack, Text, Button, Group } from "@mantine/core";
import { useState } from "react";
import { DateLargePickerUTC } from "../../../Core/DateLargePickerUTC";
import { notifications } from "@mantine/notifications";
import { CareFileInputView } from "../../../Core/FileInputComponent/CareFileInputView";
import { useTranslation } from "react-i18next";
import { AppointmentCreateAPIDTO, AppointmentService } from "../../../Domain/Medical/AppointmentService";
import { FileService, MedicalDocumentTypeDTO } from "../../../Domain/FileService/FileService";

export interface ExaminationCardCreateInterface {
    petID: string;
    moduleDidCloseAfterSuccess: () => void;
}

export function ExaminationCardCreateViewModel(infoModel: ExaminationCardCreateInterface) {
    type ExaminationPole = "admissionText" | "admissionDate";

    interface ExaminationFillDataInterface {
        admissionText?: string;
        admissionDate?: Date;
    };

    const { t } = useTranslation();
    const [data, setData] = useState<ExaminationFillDataInterface>({});
    const [files, setFiles] = useState<File[]>([]);
    const [isActionButtonEnabled, setIsActionButtonEnabled] = useState(false);
    const actionButtonTitle = t("Create");
    const appointmentService = new AppointmentService();
    const fileService = new FileService();

    const [admissionName] = useState({
        label: t("Appointment reason"),
        placeholder: t("Abdominal ultrasound")
    });

    const [admissionDate] = useState({
        label: t("Date of appointment"),
        placeholder: t("Date of taking")
    });

    const [vaccineFilesValidLabel] = useState({
        label: t("You can attach files"),
        accept: "image/png,image/jpeg",
        description: t("Maximum number of files - 5"),
        placeholder: t("Select files. For now, png/jpeg files are accepted")
    });

    function changedValue(value: any, prop: ExaminationPole) {
        setData(
            { ...data, [prop]:value }
        );
    };

    function buttonTapped() {
        setIsActionButtonEnabled(true);

        if (!data) { return; }

        if (!data.admissionText || data.admissionText.length === 0) {
            notificateError(t("You did not fill out the reason for the doctor's appointment"));
            setIsActionButtonEnabled(false);
            return;
        }
        if (!data.admissionDate) {
            notificateError(t("You didn't fill in the admission date"));
            setIsActionButtonEnabled(false);
            return;
        }

        const dto: AppointmentCreateAPIDTO = {
            petID: infoModel.petID,
            appointmentDate: data.admissionDate.toISOString(),
            reason: data.admissionText
        };

        appointmentService.create(dto)
        .catch((error) => { 
            notificateErrorCreation();
            console.log(error);
            setIsActionButtonEnabled(false);
        })
        .then((result) => {
            console.log(result);

            if (!result || !result.data) { return; }

            if (files.length > 0) {
                fileService.uploadMedicalFiles(result.data.id, MedicalDocumentTypeDTO.examination, files)
                .catch(error => {
                    console.log(error);
                })
                .then(result => {
                    if (!result) { return }
        
                    if (result.status == 200) {
                        close();
                    }
                });        
            } else {
                close();
            }
        });
    };

    function close() {
        notificateCreateSuccess();
        setIsActionButtonEnabled(false);
        infoModel.moduleDidCloseAfterSuccess();
    }

    function notificateError(message: string) {
        notifications.show({
            title: t("Incorrect data"),
            message: message,
            autoClose: 5000,
            color: "red"  
        })
    };

    function notificateCreateSuccess() {
        notifications.show({
            title: t("Success"),
            message: t("A doctor's appointment has been successfully established."),
            autoClose: 5000,
            color: "green"
        });
    };

    function notificateErrorCreation() {
        notifications.show({
            title: t("Error("),
            message: t("Error creating a doctor's appointment, try again later, please"),
            autoClose: 5000,
            color: "red"
        });
    };

    return({
        data,
        admissionName,
        admissionDate,
        vaccineFilesValidLabel,
        isActionButtonEnabled,
        actionButtonTitle,
        changedValue,
        setFiles,
        buttonTapped
    })
}

export function ExaminationCardCreateView(infoModel: ExaminationCardCreateInterface) {
    const viewModel = ExaminationCardCreateViewModel(infoModel);

    const mainView = (
        <>
            <Stack>
                <Stack spacing={6}>
                    <Text component="label" htmlFor="nameDrug" size="sm" weight={500}>
                        { viewModel.admissionName.label }
                    </Text>
                    <TextInput
                        value={viewModel.data.admissionText ?? ""}
                        onChange={e => { viewModel.changedValue(e.target.value.toString(), "admissionText")}}
                        placeholder={ viewModel.admissionName.placeholder }
                        size="sm"
                        radius="md"
                    />
                    <Text component="label" htmlFor="takeDate" size="sm" weight={500}>
                        { viewModel.admissionDate.label }
                    </Text>
                    <Group position="center" align="center">
                    <DateLargePickerUTC
                        value={viewModel.data.admissionDate}
                        onChange={e => { viewModel.changedValue(e, "admissionDate")} }
                        size="sm"
                        placeholder={viewModel.admissionDate.placeholder}
                    />
                    </Group>
                <Stack spacing={6}>
                    <Text component="label" htmlFor="revaccinationDate" size="sm" weight={500}>
                        { viewModel.vaccineFilesValidLabel.label }
                    </Text>
                    <CareFileInputView
                        description={viewModel.vaccineFilesValidLabel.description}
                        placeholder={viewModel.vaccineFilesValidLabel.placeholder}
                        accept={viewModel.vaccineFilesValidLabel.accept}
                        filesDidChange={viewModel.setFiles}
                    />
                </Stack>
                </Stack>
                <Button 
                    radius="md" 
                    onClick={() => { viewModel.buttonTapped() }}
                    loading={viewModel.isActionButtonEnabled}
                >
                    {viewModel.actionButtonTitle}
                </Button>
            </Stack>
        </>
    );

    return (
        <Container p={0}>
            { mainView }
        </Container>
    );
}