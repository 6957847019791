import { ActionIcon, Avatar, createStyles, Divider, Group, MantineNumberSize, Paper, rem, Skeleton, Stack, Text } from "@mantine/core";
import { IconCircleArrowRightFilled } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CGradientView } from "../../Core/CGradientView/CGradientView";
import { CTitleWithSubtitleView } from "../../Core/CTitleWithSubtitleView/CTitleWithSubtitleView";
import { FileService } from "../../Domain/FileService/FileService";
import { PetDTO, PetListService } from "../../Domain/Pets/PetService";
import placeholder_image from "../../Resources/Assets/square.svg";
import { makeGenderEnum, obtainGenderReadble } from "../../Resources/Helpers/genderHelper";
import { makeKindEnum, obtainKindReadble } from "../../Resources/Helpers/kindHelper";
import { CHoverShadow } from "../../Core/CHoverShadow/CHoverShadow";
import { CPetEditCardInfoModel } from "../PetEditCard/CPetEditCardView";
import { t } from "i18next";
import { dateToHumanString, stringToDate } from "../../Core/Extensions/ConvertToDateFromTS";

export interface CPetInfoViewInfoModel {
    petID: string;
    petDidUpdated: (petDTO: PetDTO) => void;
    detailsTapped: (infoModel: CPetEditCardInfoModel) => void;
}

export interface CPetInfoViewModelBuilder {
    petID: string;
    petDidUpdated: (petDTO: PetDTO) => void;
    detailsTapped: (infoModel: CPetEditCardInfoModel) => void;
};

export function CPetInfoViewModel(builder: CPetInfoViewModelBuilder) {
    type ViewState = "data" | "error" | "loading" | "placeholder";

    const petService = new PetListService();
    const fileService = new FileService();

    const { t } = useTranslation();
    const [viewState, _viewState] = useState<ViewState>("loading");
    const [avatarViewState, _avatarViewState] = useState<ViewState>("loading");
    const [petDetails, _petDetails] = useState<PetDTO | undefined>(undefined);

    const [avatar, _avatar] = useState<any | null>(null);

    const [errorContent] = useState({
        title: t("An error has occurred"),
        subtitle: t("Unable to load pet card, try again later.")
    });

    const descriprionContent = [
        { subtitle: t("Weight"), title: `${petDetails?.weight ?? "-"} ${t("kg")}`},
        { subtitle: t("Height at the withers"), title: `${petDetails?.height ?? "-"} ${t("cm")}` },
        { subtitle: t("Date of birth of D.M.G."), title: `${dateToHumanString(petDetails?.birthday)}` },
        { subtitle: t("Gender"), title: `${t(obtainGenderReadble(makeGenderEnum(petDetails?.genderType)) ?? "-")}` },
        { subtitle: t("Colour"), title: `${petDetails?.colour ?? "-"}`},
        { subtitle: t("Breed"), title: `${petDetails?.breed ?? "-"}`},
    ];

    useEffect(() => {
        obtainPet();
    }, []);

    function obtainPet() {
        _viewState("loading");
        petService.obtainPetDetails(builder.petID)
            .then(result => {
                _petDetails(result.data);
                _viewState("data");
                obtainAvatar(result.data.imageID);
                builder.petDidUpdated(result.data);
            })
            .catch(error => {
                _viewState("error");
            });
    };

    function obtainAvatar(path: string | undefined) {
        if (!path) { 
            _avatarViewState("placeholder");
            return;
        }

        _viewState("data");
        fileService.obtainFile(path)
            .then(result => {
                _avatar(result);
                _avatarViewState("data");
            })
            .catch(error => {
                _avatar(null);
                _avatarViewState("error");
            })
    };

    function detailsTapped() {
        if (!petDetails) {
            return;
        }
        const model: CPetEditCardInfoModel = {
            avatar: avatar,
            petDTO: petDetails,
            update: obtainPet
        }

        builder.detailsTapped(model);
    }

    return ({
        viewState,
        avatarViewState,
        petDetails,
        avatar,
        descriprionContent,
        errorContent,
        detailsTapped,
        obtainPet
    });
};

export function CPetInfoView(model: CPetInfoViewInfoModel) {
    const { classes } = createStyles((theme) => ({
        card: {
            backgroundImage: theme.colorScheme === 'dark' ? 
                theme.fn.linearGradient(323, "rgba(2,0,36,1) 0%", "rgba(45,132,149,1) 100%") :
                theme.colors.gray[0],
            ...CHoverShadow(theme)
        },
        
        petName: {
            wordWrap: "break-word",
            wordBreak: "break-word",
            lineHeight: 1,
            [theme.fn.smallerThan("xs")]: {
                fontSize: rem(25),
            },
            [theme.fn.largerThan("lg")]: {
                fontSize: rem(30),
            },
        }
    }))();

    const viewModel = CPetInfoViewModel({...model});
    const borderRadius: MantineNumberSize = "lg";
    const imageHeight = 120;

    const loadingView = (
        <Skeleton animate={false} radius={borderRadius} h={350}></Skeleton>
    );

    const infoItems = viewModel.descriprionContent.map((data, index) => (
        <CTitleWithSubtitleView key={data.subtitle} title={data.title} subtitle={data.subtitle} />
    ));

    const contentView = (
        <>
            <Group h={imageHeight} noWrap position="apart">
                <Group noWrap>
                <Avatar
                    radius={imageHeight / 2}
                    size={imageHeight}
                    src={viewModel.avatar ?? placeholder_image}
                />
                <Stack h={imageHeight} spacing={4} justify="space-between" align="flex-start">
                    <Text className={classes.petName} lineClamp={2} weight={900} size={28}>{viewModel.petDetails?.name}</Text>
                    <Stack spacing={0} justify="flex-end">
                        <Text weight={800} m={0} p={0} size="lg">{obtainKindReadble(makeKindEnum(viewModel.petDetails?.biologicalKindType))}</Text>
                        <Text weight={800} m={0} p={0} size="lg" color="dimmed">{ monthDiff(viewModel.petDetails?.birthday) }</Text>
                    </Stack>
                </Stack>
                </Group>
                <Group position="right">
                    <ActionIcon onClick={viewModel.detailsTapped} color="dark"><IconCircleArrowRightFilled color="dark" size={26} /></ActionIcon>
                </Group>
            </Group>
            <Divider my="sm" />
            <Stack spacing={4}>
                { infoItems }
            </Stack>
        </>
    );

    function activeView() {
        switch (viewModel.viewState) {
            case "data":
                return <Paper p="lg" withBorder radius={borderRadius} className={classes.card}>{contentView}</Paper>

            case "loading":
                return loadingView;

            case "placeholder":
                return <></>;

            case "error":
                return <CGradientView
                    h={350} p={40}
                    color="white"
                    radius={borderRadius}
                    imageAsset="random_gradient"
                    content={{title: viewModel.errorContent.title, subtitle: viewModel.errorContent.subtitle}}
                />;
        };
    }

    const view = activeView();

    function monthDiff(d1?: string): string {
        const date = stringToDate(d1);

        if (!date) {
            return "-"
        }

        const now = new Date;

        var months;
        months = (now.getFullYear() - date.getFullYear()) * 12;
        months -= date.getMonth();
        months += now.getMonth();

        var years: number;
        years = months / 12;

        var monthsInYear: number;
        monthsInYear = months - parseInt(years.toString()) * 12

        var resultString: string;
        if (years > 0) {
            const intYears = parseInt(years.toString())
            resultString = `${intYears} ${t(num_word(intYears, ["years_one", "years_two", "years_other"]))}`;
        } else {
            resultString = "";
        }
        if (monthsInYear > 0) {
            const intMonths = parseInt(monthsInYear.toString())
            resultString += ` ${intMonths} ${t(num_word(intMonths, ["months_one", "months_two", "months_other"]))}`;
        }
        if (monthsInYear < 1 && years < 1) {
            const oneDay = 1000 * 60 * 60 * 24;
            const diffInTime = now.getTime() - date.getTime();
            const diffInDays = Math.round(diffInTime / oneDay);

            resultString = `${parseInt(diffInDays.toString())} ${t("d")}`
        }

        return resultString;
    };

    function num_word(value: number, words: string[]){  
        value = Math.abs(value) % 100; 
        var num = value % 10;
        if(value > 10 && value < 20) return words[2]; 
        if(num > 1 && num < 5) return words[1];
        if(num == 1) return words[0]; 
        return words[2];
    };

    return ({
        view: view,
        obtainPet: viewModel.obtainPet
    })
}