import { ActionIcon, Container, createStyles, Group, ScrollArea } from "@mantine/core";
import { IconCircleArrowRightFilled, IconCirclePlus } from "@tabler/icons-react";
import { useState } from "react";
import { useStyles } from "../../Modules/Root/Styles";
import { CTabsListItemView } from "./CTabsListItemView";

interface CTabsListBuilder {
    values: { title: string }[];
    didSelect: ((index: number) => void);
    addTapped: () => void;
};

export function CTabsListView(builder: CTabsListBuilder) {
    const [selected, _selected] = useState<number>(0);

    const { classes } = createStyles((theme) => ({
        mobileButton: {
            [theme.fn.largerThan("sm")]: {
                display: "none"
            }
        }
    }))();

    const items = () => {
        return builder.values.map((item, index) => {
            return <CTabsListItemView
                key={index}
                selected={index == selected}
                title={item.title}
                didSelected={() => {
                    _selected(index);
                    builder.didSelect(index);
                }}
            />
        })
    }

    return (
        <Container p={0} fluid>
            <Group noWrap spacing={5}>
            <ScrollArea w={"100%"} type="never">
            <Group noWrap spacing={4}>
                { items() }
            </Group>
            </ScrollArea>
                <ActionIcon 
                    className={classes.mobileButton} color="dark" pb={4}
                    onClick={builder.addTapped}
                >
                        <IconCirclePlus />
                </ActionIcon>
            </Group>
        </Container>
    );
};