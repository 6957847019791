import { Container, createStyles, Title, Select, TextInput, Text, Button, Stack, Center, Space, LoadingOverlay, Group } from "@mantine/core";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../Root/useWindowDimensions";
import { showNotification } from "@mantine/notifications";
import { PetListService } from "../../../Domain/Pets/PetService";
import { DateLargePickerUTC } from "../../../Core/DateLargePickerUTC";
import { DataObservable } from "../../../Core/DataObservable";
import { useTranslation } from "react-i18next";

interface CreatePetFormViewModelProps {
    name: string;
    kind: string;
    gender: string;
    date?: Date
}

export function CreatePetFormViewModel(close: () => void) {
    const petService = new PetListService();

    const [data, setData] = useState<CreatePetFormViewModelProps>(
        {
            name: "",
            kind: "",
            gender: ""
        }
    );

    useEffect(() => {}, []);

    const [loading, _loading] = useState(false);
    const { t } = useTranslation();

    function changedValue(value: any, prop: "name" | "kind" | "gender" | "date") {
        setData(
            { ...data, [prop]:value }
        )
    }

    function create() {
        const name = data.name;
        const kind = data.kind;
        const gender = data.gender;
        const date = data.date;

        if (name == undefined || name.length == 0) {
            showNotification({
                title: t("Empty pet name"),
                message: t("To create a pet profile, we need its name"),
                autoClose: 5000,
                color: "red"
            })
            return;
        }

        if (date == undefined) {
            showNotification({
                title: t("Date of birth not specified"),
                message: t("To create a pet profile, we need to know its date of birth"),
                autoClose: 5000,
                color: "red"
            })
            return;
        }

        if (gender == undefined || gender.length == 0) {
            showNotification({
                title: t("The gender of the pet is not selected"),
                message: t("To create a pet profile, we need to know what gender it is"),
                autoClose: 5000,
                color: "red"
            })
            return;
        }

        if (kind == undefined || kind.length == 0) {
            showNotification({
                title: t("The type of pet is not selected"),
                message: t("To create a pet profile, we need to know what kind of pet it is"),
                autoClose: 5000,
                color: "red"
            })
            return;
        }

        _loading(true);
        const result = petService.createPet(
            { name: name, birthday: date.toISOString(), gender: gender, kind: kind }
        )

        result
        .catch((error: Error) => {
            showNotification({
                title: t("Error when creating a pet"),
                message: error.message,
                autoClose: 5000,
                color: "red"
            });
            _loading(false);
            return;
        })
        .then(result => {
            if (result == undefined) { 
                return;
            }
            showNotification({
                title: t("The pet was successfully created!"),
                message: "",
                autoClose: 5000,
                color: "green"
            });
            _loading(false);

            DataObservable.getInstance().notifyObservers("petCreated");
            close();
        });
    }

    return {
        ...data,
        changedValue,
        create,
        loading
    }
}

interface CreatePetFormViewBuilder {
    onClose: () => void;
}

export function CreatePetFormView(builder: CreatePetFormViewBuilder) {
    const useStyles = createStyles((theme) => ({
        input: {
            fontWeight: 600,
        }}
        )
    );
    const { classes } = useStyles();

    const { t } = useTranslation();

    const {
        name, kind, gender, date,
        changedValue,
        create,
        loading
    } = CreatePetFormViewModel(builder.onClose);
    const { height, width } = useWindowDimensions();

    return(
        <Container p={0}>
                <Stack>
                    <Stack spacing={8}>
                        <Text
                            size="sm"
                            weight={600}
                            color="dimmed"
                        >
                            {t("Pet name")}
                        </Text>
                        <TextInput
                            size="sm"
                            classNames={classes}
                            value={name ?? ""}
                            placeholder={t("Timon")!}
                            onChange={(e) => { changedValue(e.target.value, "name") }}
                            radius="md"
                        />
                    </Stack>
                    <Stack spacing={8}>
                        <Text
                            size="sm"
                            weight={600}
                            color="dimmed"
                        >
                            {t("Date of birth")}
                        </Text>
                        <Group position="center" align="center">
                        <DateLargePickerUTC
                            size="sm"
                            value={date}
                            placeholder="30.11.2018"
                            onChange={(e) => { changedValue(e, "date") }}
                        />
                        </Group>
                    </Stack>
                    <Stack spacing={8}>
                        <Text
                            size="sm"
                            weight={600}
                            color="dimmed"
                        >
                            {t("Gender of the pet")}
                        </Text>
                        <Select
                            size="sm"
                            classNames={classes}
                            value={gender ?? ""}
                            placeholder={t("Choose a gender")!}
                            onChange={e => changedValue(e, "gender")}
                            radius="md"
                            data={[
                                { value: "boy", label: t("Boy")! },
                                { value: "girl", label: t("Girl")! },
                            ]}
                        />
                    </Stack>
                    <Stack spacing={8}>
                        <Text
                            size="sm"
                            weight={600}
                            color="dimmed"
                        >
                            {t("Type of pet")}
                        </Text>
                        <Select
                            size="sm"
                            classNames={classes}
                            value={kind ?? ""}
                            placeholder={t("Select a type")!}
                            onChange={e => changedValue(e, "kind")}
                            radius="md"
                            data={[
                                { value: "cat", label: t("Cat")! },
                                { value: "dog", label: t("Dog")! },
                            ]}
                        />
                    </Stack>
                    <Space />
                    <Button onClick={create} radius="md">{t("Create")}</Button>
                    <LoadingOverlay visible={loading} overlayBlur={2} />
                </Stack>
            </Container>
    )
}