import { Container, Stack, TextInput, Text, Button, Select } from "@mantine/core";
import { DatePickerUTC } from "../../../Core/DatePickerUTC";
import { CareFileInputView } from "../../../Core/FileInputComponent/CareFileInputView";
import { VaccineType } from "../../../Domain/Ambulance/AmbulanceService";
import { VaccineCardCreateViewModel } from "./VaccineCardCreateViewModel";

export interface VaccineCardCreateViewInterface {
    vaccineType: VaccineType;
    petID: string;
    moduleDidCloseAfterSuccess: () => void;
}
export function VaccineCardCreateView(infoModel: VaccineCardCreateViewInterface) {
    const { 
        isActionButtonEnabled,
        data,
        vaccineNameLabel,
        vaccineNumberLabel,
        vaccineDateLabel,
        reVaccineDateLabel,
        vaccineDateValidLabel,
        vaccineFilesValidLabel,
        vaccineTypeSelect,
        actionButtonState,
        actionButtonTitle,
        changedValue,
        filesChanged,
        buttonTapped
     } = VaccineCardCreateViewModel({ petID: infoModel.petID, vaccineType: infoModel.vaccineType, moduleDidCloseAfterSuccess: infoModel.moduleDidCloseAfterSuccess});

    const mainView = (
        <>
            <Stack>
                <Stack spacing={6}>
                    <Text component="label" htmlFor="vaccineType" size="sm" weight={500}>
                        { vaccineTypeSelect.label }
                    </Text>
                    <Select
                        value={data.vaccineType}
                        onChange={e => changedValue(e, "vaccineType")}
                        style={{ zIndex: 3 }}
                        data={ vaccineTypeSelect.data }
                        placeholder={vaccineTypeSelect.placeholder}
                        radius="md"
                    />
                </Stack>
                <Stack spacing={6}>
                    <Text component="label" htmlFor="vaccineName" size="sm" weight={500}>
                        { vaccineNameLabel.label }
                    </Text>
                    <TextInput
                        value={data.vaccineName ?? ""}
                        onChange={e => { changedValue(e.target.value.toString(), "vaccineName")}}
                        placeholder={ vaccineNameLabel.placeholder }
                        size="sm"
                        radius="md"
                    />
                </Stack>
                <Stack spacing={6}>
                    <Text component="label" htmlFor="vaccineNumber" size="sm" weight={500}>
                        { vaccineNumberLabel.label }
                    </Text>
                    <TextInput
                        value={data.vaccineNumber ?? ""}
                        onChange={e => { changedValue(e.target.value, "vaccineNumber")}}
                        placeholder={ vaccineNumberLabel.placeholder }
                        size="sm"
                        radius="md"
                    />
                </Stack>
                <Stack spacing={6}>
                    <Text component="label" htmlFor="vaccineDate" size="sm" weight={500}>
                        { vaccineDateLabel.label }
                    </Text>
                    <DatePickerUTC 
                        value={data.vaccineDate}
                        onChange={e => { changedValue(e, "vaccineDate")} }
                        radius="md"
                        size="sm"
                        placeholder={vaccineDateLabel.placeholder}
                    />
                </Stack>
                <Stack spacing={6}>
                    <Text component="label" htmlFor="revaccinationDate" size="sm" weight={500}>
                        { reVaccineDateLabel.label }
                    </Text>
                    <DatePickerUTC 
                        value={data.revaccinationDate}
                        onChange={e => { changedValue(e, "revaccinationDate")} }
                        radius="md"
                        size="sm"
                        description={reVaccineDateLabel.description}
                        placeholder={reVaccineDateLabel.placeholder}
                    />
                </Stack>
                <Stack spacing={6}>
                    <Text component="label" htmlFor="revaccinationDate" size="sm" weight={500}>
                        { vaccineDateValidLabel.label }
                    </Text>
                    <DatePickerUTC
                        value={data.vaccineValidDate}
                        onChange={e => { changedValue(e, "vaccineValidDate")} }
                        radius="md"
                        size="sm"
                        description={vaccineDateValidLabel.description}
                        placeholder={vaccineDateValidLabel.placeholder}
                    />
                </Stack>
                <Stack spacing={6}>
                    <Text component="label" htmlFor="revaccinationDate" size="sm" weight={500}>
                        { vaccineFilesValidLabel.label }
                    </Text>
                    <CareFileInputView
                        description={vaccineFilesValidLabel.description}
                        placeholder={vaccineFilesValidLabel.placeholder}
                        accept={vaccineFilesValidLabel.accept}
                        filesDidChange={filesChanged}
                    />
                </Stack>
                <Button bg={actionButtonState === "blank" ? "blue" : "green"} radius="md" onClick={() => { buttonTapped() }} loading={isActionButtonEnabled}>{actionButtonTitle}</Button>
            </Stack>
        </>        
    )

    return (
        <Container p={0}>
            { mainView }
        </Container>
    )
};