import React, { useState } from 'react';
import './../../index.css'
import { AppShell, Aside, Burger, Header, MediaQuery, useMantineTheme } from '@mantine/core';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { RootNavigationBarView } from './RootNavigationBarView';
import { useEffect } from 'react';

export const RootView: React.FC = () => {
    const theme = useMantineTheme();
    const [opened, setOpened] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      if (location.pathname == "/") {
      navigate("/pets");
    }
    }, [])

    console.log(location);
    return (
      <>
      <AppShell
        bg="gray"
        styles={(theme) => ({
          main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0], paddingRight: 16 },
        })}
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"  
        navbar={<RootNavigationBarView opened={ opened } _opened={ setOpened } />} 
        header={
          <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
            <Header height={{ base: 50, sm: 0 }} p="md">
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  color={theme.colors.gray[6]}
                  mr="xl"
                />
              </MediaQuery>
            </div>
          </Header>
          </MediaQuery>
        }  
      >
      <Outlet />
      </AppShell>
      </>
    )
};