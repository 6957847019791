export const RootNavigationDataSource = {
    // dashboardRoot: {
    //     name: "Dashboard",
    //     link: "/dashboard"
    // },
    petsRoot: {
        name: "Pets",
        link: "/pets"
    },
    mapRoot: {
        name: "Public map",
        link: "/map"
    },
    // ammunitionRoot: {
    //     name: "Ammunition",
    //     link: "/ammunition"
    // },
    profileRoot: {
        name: "Profile",
        link: "/profile"
    }
}