import { ActionIcon, Badge, Button, Center, Container, createStyles, Group, Text, Stack, Title, Transition, Space, Textarea, LoadingOverlay } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import mapboxgl, { Marker } from "mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import { IconCircleX, IconLocationFilled } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { CLocation2DPoint, DangerAreaDTO, DangerAreaPoint, PlaceClientDTO, PlaceFitler, PlaceService, PlaceType } from "../../Domain/Places/PlaceService";
import geolocation_marker from "../../Resources/Assets/geolocation.svg";
import ic_danger_place from "../../Resources/Assets/ic_danger_place.svg";
import ic_place from "../../Resources/Assets/ic_place.svg";
import ic_safe_place from "../../Resources/Assets/ic_safe_place.svg";

import { ReactElement } from "react";
import { useCallback } from "react";
import { error } from "console";
import { MapInteractor } from "./MapInteractor";
import { MapViewModelModern, makeLocation } from "./MapViewModel";

export const MapObjectFabric = () => {
    const [locationMarker, _locationMarker] = useState<Marker | null>(null);
    const createPlaceMarker = useRef<Marker | null>(null);
    const allPlaces = useRef<Array<Marker>>([]); 

    const [newPlaceMarkerLocation, _newPlaceMarkerLocation] = useState<CLocation2DPoint | null>(null);

    function makeCreatePlaceMarker() {
        const element = new Image();

        element.width = 32
        element.height = 32
        element.src = ic_place;

        return new mapboxgl.Marker(element)
    };

    function makeGeolocationMarker() {
        const element = new Image();

        element.width = 24
        element.height = 24
        element.src = geolocation_marker;

        return element;
    };

    function makeplaceMarker(elementID: string, type: PlaceType, tapObjectHandler: (elemetID: string) => void) {
        const element = new Image();

        element.width = 38
        element.height = 38
        element.onclick = () => {
            tapObjectHandler(elementID);
        };

        if (type == "safe") {
            element.src = ic_safe_place
        }
        if (type == "danger") {
            element.src = ic_danger_place
        }

        const marker = new mapboxgl.Marker(element);
        return marker;
    };

    function markerLocationChanged() {
        if (!createPlaceMarker.current) {
            return
        }

        const location: CLocation2DPoint = {
            latitude: createPlaceMarker.current.getLngLat().lat,
            longtitude: createPlaceMarker.current.getLngLat().lng
        }

        _newPlaceMarkerLocation(location);
    }

    function addCreatePlaceMarker(location: CLocation2DPoint, map: mapboxgl.Map) {
        const marker = makeCreatePlaceMarker();

        if (createPlaceMarker.current) {
            createPlaceMarker.current.off("drag", markerLocationChanged);
        }

        marker
            .setDraggable(true)
            .setLngLat(makeLocation(location))
            .addTo(map)

        marker
            .on("drag", markerLocationChanged);

        createPlaceMarker.current = marker;
        _newPlaceMarkerLocation(location);
    };

    function removeCreatePlaceMarker() {
        createPlaceMarker.current?.remove();
        createPlaceMarker.current = null;
    }

    function addPlaceMarkers(places: DangerAreaPoint[], map: mapboxgl.Map, tapObjectHandler: (elementID: string) => void) {
        allPlaces.current.forEach((item) => {
            item.remove();
        })
        allPlaces.current = [];

        places.forEach((item) => {
            const marker = makeplaceMarker(item.id, item.type, tapObjectHandler);

            marker
                .setDraggable(false)
                .setLngLat([item.longitude, item.latitude])
                .addTo(map)

            allPlaces.current.push(marker);
        })
    }

    function addGeolocationMarker(location: CLocation2DPoint, map: mapboxgl.Map) {
        if (locationMarker) {
            locationMarker.setLngLat(makeLocation(location));
            return;
        }

        const element = makeGeolocationMarker();
        const marker = new mapboxgl.Marker({
            element: element
        })

        marker
        .setLngLat(makeLocation(location))
        .addTo(map)

        _locationMarker(marker);
    };

    // REFACTORING
    function makeNewPlaceMarker(location: CLocation2DPoint) {
        const element = new Image();

        element.width = 32
        element.height = 32
        element.src = ic_place;

        const marker = new mapboxgl.Marker(element)
                        .setDraggable(true)
                        .setLngLat(makeLocation(location))

        return marker;
    }

    function drawNewPlaceMarker(location: CLocation2DPoint,
                                mapView: mapboxgl.Map,
                                didMarkerChangedPositionHandler: (location: CLocation2DPoint) => void) {
        const marker = makeNewPlaceMarker(location);

        marker.on("drag", (() => {
            if (!createPlaceMarker.current) { return; };

            const location: CLocation2DPoint = {
                latitude: createPlaceMarker.current.getLngLat().lat,
                longtitude: createPlaceMarker.current.getLngLat().lng
            }
    
            didMarkerChangedPositionHandler(location);
        }));

        createPlaceMarker.current = marker.addTo(mapView);
    };

    function removeNewPlaceMarker() {
        createPlaceMarker.current?.remove();
        createPlaceMarker.current = null;
    }

    function removeAllPlaces() {
        allPlaces.current.forEach((item) => {
            item.remove();
        })
        allPlaces.current = [];
    };

    function addPlaces(places: DangerAreaPoint[],
                       mapView: mapboxgl.Map,
                       didTapObjectHandler: (placeID: string) => void) {
        removeAllPlaces();

        places.forEach((item) => {
            const marker = makeplaceMarker(item.id, item.type, didTapObjectHandler);

            marker
                .setDraggable(false)
                .setLngLat([item.longitude, item.latitude])
                .addTo(mapView)

            allPlaces.current.push(marker);
        })
    }

    return ({
        locationMarker, _locationMarker,
        addGeolocationMarker,
        addCreatePlaceMarker, 
        removeCreatePlaceMarker,
        newPlaceMarkerLocation,
        addPlaceMarkers,
        // refactor
        drawNewPlaceMarker,
        removeNewPlaceMarker,
        removeAllPlaces,
        addPlaces
    })
}
