import { MantineTheme } from "@mantine/core"

export const CHoverShadow = (theme: MantineTheme) => {
    return {
        transition: 'transform 100ms ease, box-shadow 80ms ease',
        '&:hover': {
            boxShadow: theme.shadows.md,
            transform: 'scale(1.01)',
        },
    }
};