import { Button, Container, createStyles, Group, LoadingOverlay, rem, Space, Stack, Text, TextInput } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { DateLargePickerUTC } from "../../Core/DateLargePickerUTC";
import { ChipClientDTO, ChipDTO, PetListService } from "../../Domain/Pets/PetService";
import { useTranslation } from "react-i18next";
import { dateToHumanString } from "../../Core/Extensions/ConvertToDateFromTS";

export interface CChipDetailsViewBuilder {
    petID: string;
    chipID?: string;
    needUpdate: () => void;
};

interface CChipCreateViewModelInterface {
    petID: string;
    onCreate: ((chipID: string) => void);
}

function CChipCreateViewModel(builder: CChipCreateViewModelInterface) {
    type ChipPole = "number" | "date" | "place";
    interface ChipFillData {
        number?: string;
        date?: Date;
        place?: string;
    };

    const petService = new PetListService();
    const { t } = useTranslation();
    const [fillData, _data] = useState<ChipFillData>({});

    function valueChanged(value: any, pole: ChipPole) {
        _data({ ...fillData, [pole]:value });
    };

    function createTapped() {
        if (fillData.number == null || fillData.number.length == 0) {
            showNotification(t("The chip number is not filled in"), true);
            return;
        };
        if (fillData.date == null) {
            showNotification(t("The date of chipping is not filled in"), true);
            return;
        };
        if (fillData.place == null || fillData.number.length == 0) {
            showNotification(t("The implant site is unfilled"), true);
            return;
        }
        const chipDTO: ChipClientDTO = {
            petID: builder.petID,
            number: fillData.number,
            implantingDate: fillData.date.toISOString(),
            location: fillData.place,
        };

        petService.createChip(chipDTO)
        .then((result) => {
            if (!result.data) { return; }

            builder.onCreate(result.data.id);
            showNotification(t("The chip was successfully created!"), false);
        })
        .catch((error) => {
            console.log(error);
            showNotification(t("An error occurred during creation, try again"), true);
        })
    };

    function showNotification(message: string, isError: boolean) {
        notifications.show({
            title: isError ? t("Error") : t("Success!"),
            message: message,
            autoClose: 5000,
            color: isError ? "red" : "green"
        })
    };

    return ({
        fillData,
        valueChanged,
        createTapped
    });
}

function CChipCreateView(builder: CChipCreateViewModelInterface) {
    const { classes } = createStyles((theme) => ({
        placeholderSubtitle: {
            lineHeight: 1,
            fontWeight: 800,
            fontSize: rem(24),
            [theme.fn.smallerThan("xs")]: {
                fontSize: rem(16),
            },
        },

        placeholderTitle: {
            lineHeight: 1,
            fontWeight: 800,
            fontSize: rem(28),
            [theme.fn.smallerThan("xs")]: {
                fontSize: rem(20),
            },
        },

        input: {
            fontWeight: 600,
        }
    }))();

    const viewModel = CChipCreateViewModel(builder);
    const { t } = useTranslation();

    const view = (
        <Stack justify="space-around">
        <Stack spacing={8}>
            <Text
                size="sm"
                weight={600}
                color="dimmed"
            >
                {t("Chip number")}
            </Text>
            <TextInput
                size="sm"
                classNames={classes}
                value={viewModel.fillData.number ?? ""}
                placeholder="DVJNSCQ15"
                onChange={(e) => { viewModel.valueChanged(e.target.value, "number") }}
                radius="md"
            />
        </Stack>

        <Stack spacing={8}>
            <Text
                size="sm"
                weight={600}
                color="dimmed"
            >
                {t("Date of implantation")}
            </Text>
            <Group position="center" align="center">
            <DateLargePickerUTC
                size="sm"
                value={viewModel.fillData.date}
                placeholder="30.11.2018"
                onChange={(e) => { viewModel.valueChanged(e, "date") }}
            />
            </Group>
        </Stack>

        <Stack spacing={8}>
            <Text
                size="sm"
                weight={600}
                color="dimmed"
            >
                {t("Place of implantation")}
            </Text>
            <TextInput
                size="sm"
                classNames={classes}
                value={viewModel.fillData.place ?? ""}
                placeholder={t("Rear left paw")!}
                onChange={(e) => { viewModel.valueChanged(e.target.value, "place") }}
                radius="md"
            />
        </Stack>

        <Space h={12} />
        <Button onClick={viewModel.createTapped} color="dark" radius="md">{t("Create")}</Button>
    </Stack>            
    );

    return (
        view
    );
};

interface CChipDetailsViewModelInterface {
    chipID: string;
    _loading: (_: boolean) => void;
    onError: () => void;
    onDelete: (() => void);
};

function CChipDetailsViewModel(builder: CChipDetailsViewModelInterface) {
    const petService = new PetListService();    
    const [remoteData, _remoteData] = useState<ChipDTO>();
    const { t } = useTranslation();

    useEffect(() => {
        obtainData();
    }, []);

    function obtainData() {
        builder._loading(true);
        petService.obtainChipDetails(builder.chipID)
        .then((result) => {
            if (!result.data) { return; }

            _remoteData(result.data);
            builder._loading(false);
        })  
        .catch((error) => {
            console.log(error);
            builder.onError();
            builder._loading(false);
        })
    };

    function deleteChip() {
        modals.openConfirmModal({
            title: t("Please confirm the action"),
            children: (
              <Text size="sm">
                {t("This action is important that you need to confirm it. Please press one of these buttons to continue.")}
              </Text>
            ),
            labels: { confirm: t("Yes, remove"), cancel: t("No, leave it") },
            onCancel: () => {},
            onConfirm: () => {
                builder._loading(true);
                petService.deleteChip(builder.chipID)
                    .then((result) => {
                        if (result.status != 200) { return; }

                        builder._loading(false);
                        showNotification(t("Deletion successful"), false);
                        builder.onDelete();
                    })  
                    .catch((error) => {
                        showNotification(t("Failed to remove the chip"), true);
                        builder._loading(false);
                    })
                }
          });        
    };

    function showNotification(message: string, isError: boolean) {
        notifications.show({
            title: isError ? t("Error") : t("Success!"),
            message: message,
            autoClose: 5000,
            color: isError ? "red" : "green"
        })
    };

    return ({
        remoteData,
        deleteChip
    });
};

function CChipDetailsView(builder: CChipDetailsViewModelInterface) {
    const viewModel = CChipDetailsViewModel(builder);
    const { t } = useTranslation();

    function subtitleStyled(text: string) {
        return <Text weight={600} size={16} color="dimmed">{text}</Text>
    };

    function titleStyled(text: string) {
        return <Text weight={800} size={18}>{text}</Text>
    };

    return (
        <Stack>
        <Stack spacing={0}>
            { subtitleStyled(t("Chip number")) }
            { titleStyled(viewModel.remoteData?.number ?? "-") }
        </Stack>
        <Stack spacing={0}>
            { subtitleStyled(t("Date of implantation")) }
            { titleStyled(dateToHumanString(viewModel.remoteData?.implantingDate) ?? "-") }
        </Stack>
        <Stack spacing={0}>
            { subtitleStyled(t("Place of implantation")) }
            { titleStyled(viewModel.remoteData?.location ?? "-") }
        </Stack>
        <Group position="right">
            <Button onClick={ viewModel.deleteChip } color="red">{t("Delete")}</Button>
        </Group>
    </Stack>
    );
};

function CChipViewModel(builder: CChipDetailsViewBuilder, needClose: () => void) {
    type ViewState = "loading" | "error" | "new" | "showData";
    const [viewState, _viewState] = useState<ViewState>("loading");
    const [chipID, _setChipID] = useState<string | undefined>(builder.chipID);
    const [loading, _loading] = useState(false);
    const { t } = useTranslation();

    const errorContent = {
        title: t("Error"),
        subtitle: t("We could not get information about the chip, please try again later")
    };

    useEffect(() => {
        chooseState();
    }, [chipID]);

    function chooseState() {
        if (chipID == null) {
            _viewState("new");
        }
        if (chipID != null) {
            _viewState("showData");
        }
    };

    function onError() {
        console.log(viewState);
        _viewState("error");
    };

    function onCreate(chipID: string) {
        _setChipID(chipID);
        builder.needUpdate();
    };

    function onDelete() {
        builder.needUpdate();
        needClose();
    }

    return ({
        viewState,
        chipID: chipID,
        errorContent,
        loading, _loading,
        onError,
        onCreate,
        chooseState,
        onDelete
    })
};

interface CloseFromChildInterface {
    needClose: () => void
}

export function CChipView(builder: CChipDetailsViewBuilder & CloseFromChildInterface) {
    const { t } = useTranslation();
    
    useEffect(() => {}, []);

    const { classes } = createStyles((theme) => ({
        placeholderSubtitle: {
            lineHeight: 1,
            fontWeight: 800,
            fontSize: rem(24),
            [theme.fn.smallerThan("xs")]: {
                fontSize: rem(16),
            },
        },

        placeholderTitle: {
            lineHeight: 1,
            fontWeight: 800,
            fontSize: rem(28),
            [theme.fn.smallerThan("xs")]: {
                fontSize: rem(20),
            },
        },

        input: {
            fontWeight: 600,
        }
    }))();

    const viewModel = CChipViewModel(builder, builder.needClose);

    const dataView = (
        <CChipDetailsView chipID={viewModel.chipID ?? ""} onDelete={viewModel.onDelete} onError={viewModel.onError} _loading={viewModel._loading} />
    );

    const errorView = (
        <Stack spacing={8} style={{borderRadius: 20}} p={0} justify={"center"} align="flex-start" mt={10} w={"70%"} h={"100%"}>
            <Text className={classes.placeholderTitle}>{viewModel.errorContent.title}</Text>
            <Text className={classes.placeholderSubtitle} color="gray">{viewModel.errorContent.subtitle}</Text>
            <Space h={6} />
            <Button color="dark" onClick={viewModel.chooseState} radius="md">{t("Repeat")}</Button>
        </Stack>
    );

    const loadingView = (
        <></>
    );

    const createView = (
        <CChipCreateView  petID={builder.petID} onCreate={viewModel.onCreate} />
    );

    function view() {
        switch (viewModel.viewState) {
            case "new":
                return createView;
    
            case "showData":
                return dataView;
    
            case "loading":
                return loadingView;
    
            case "error":
                return errorView;
        }
    };

    return (
        <Container p={0}>
            { view() }
            <LoadingOverlay visible={viewModel.loading}></LoadingOverlay>
        </Container>
    )
};