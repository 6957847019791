import { Container } from '@mantine/core';
import mapboxgl from 'mapbox-gl';
import './App.css';
import { LandingView } from './Modules/LandingView/LandingView';

import { RootView } from './Modules/Root/RootView';

export function LandingModule() {
  return (
    <>
      <LandingView></LandingView>
    </>
  );
};

export function RootModule() {
  mapboxgl.accessToken = 'pk.eyJ1IjoiY2FyZWRldmVsb3AiLCJhIjoiY2xmemdxbnA3MHI0NTNmbW1yYjVwOGxoZiJ9.UoBUqxtlvIXXCu8mnSfrPg';

  return (
    <>
      <RootView />
    </>
  );
}
