import { Container, LoadingOverlay, Stack, Text } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { CareFileInputView } from "../../../Core/FileInputComponent/CareFileInputView";
import { CFileListView } from "../../../Core/FileListComponent/CFileListView";
import { vaccineTypeHelper } from "../../../Resources/Helpers/vaccineTypeHelper";
import { useTranslation } from "react-i18next";
import { VaccinationAPIDTO, VaccinationService } from "../../../Domain/Medical/VaccinationService";
import { dateToHumanString } from "../../../Core/Extensions/ConvertToDateFromTS";
import { FileService, MedicalDocumentTypeDTO } from "../../../Domain/FileService/FileService";

export interface VaccineDetailsInfoModel {
    petID: string;
    medicineID: string;
}

export function VaccineDetailsViewModel(infoModel: VaccineDetailsInfoModel) {
    const vaccinationService = new VaccinationService();
    const fileService = new FileService();

    const { t } = useTranslation();

    const [addTappedState, setAddTappedState] = useState(false);
    const [files, setFiles] = useState<File[]>([]);

    const [vaccineTypeSelect] = useState({
        label: t("Vaccine type")
    });

    const [vaccineNameLabel] = useState({
        label: t("Vaccine name")
    });
    const [vaccineNumberLabel] = useState({
        label: t("Vaccine number")
    });
    const [vaccineDateLabel] = useState({
        label: t("Date of vaccination"),
    });

    const [revaccineDateLabel] = useState({
        label: t("Date of revaccination"),
    });

    const [vaccineDateValidLabel] = useState({
        label: t("Vaccine expiration date"),
    });

    const [vaccineFilesValidLabel] = useState({
        label: t("You can attach files"),
        accept: "image/png,image/jpeg",
        description: t("Maximum number of files - 5"),
        placeholder: t("Select files. For now, png/jpeg files are accepted")
    });

    const [data, setData] = useState<VaccinationAPIDTO | undefined>()

    const [loading, setLoading] = useState<boolean>(true);

    function obtainVaccine() {
        setLoading(true);

        vaccinationService.fetchDetails(infoModel.medicineID)
        .catch((error) => {
            notifications.show({
                title: t("Error"),
                message: t("Unable to upload vaccination details, please try again later"),
                color: "red",
                autoClose: true
            })
            return;
        })
        .then((response) => {
            if (!response || !response.data) {
                return;
            }

            setData(response.data);
            setLoading(false);
        });
    }

    useEffect(() => {
        obtainVaccine();
    }, [])

    function update() {
        obtainVaccine();
    }

    function saveDocumentsTapped() {
        if (!data) { return; };
        
        fileService.uploadMedicalFiles(data.id, MedicalDocumentTypeDTO.vaccination, files)
        .catch(error => {
            console.log(error);
        })
        .then(result => {
            if (!result) { return }

            if (result.status == 200) {
                update();
                setAddTappedState(false);
            }
        });
    }

    return ({
        vaccineTypeSelect,
        vaccineNameLabel,
        vaccineDateLabel,
        vaccineNumberLabel,
        revaccineDateLabel,
        vaccineDateValidLabel,
        loading,
        vaccineFilesValidLabel,
        data,
        addTappedState,
        files,
        update,
        setAddTappedState,
        setFiles,
        saveDocumentsTapped,
    });
}

export function VaccineDetailsView(infoModel: VaccineDetailsInfoModel) {
    const viewModel = VaccineDetailsViewModel(infoModel);

    const fileView = () => {
        if (!viewModel.addTappedState) {
            return <CFileListView addTapped={() => { viewModel.setAddTappedState(true) }} update={viewModel.update} loading={viewModel.loading} documents={viewModel.data?.documentDTOs ?? []}/>
        }

        return (
            <>
                <Text component="label" htmlFor="revaccinationDate" size="sm" weight={500}>
                    { viewModel.vaccineFilesValidLabel.label }
                </Text>
                <CareFileInputView
                    description={viewModel.vaccineFilesValidLabel.description}
                    placeholder={viewModel.vaccineFilesValidLabel.placeholder}
                    accept={viewModel.vaccineFilesValidLabel.accept}
                    filesDidChange={viewModel.setFiles}
                    saveEnabled={true}
                    saveTapped={viewModel.saveDocumentsTapped}
                    uploadedDocuments={viewModel.data?.documentDTOs ?? []}
                />
            </>
        )
    }

    const mainView = (
        <Stack>
            <Stack spacing={2}>
                <Text color="dimmed" component="label" htmlFor="vaccineType" size="sm" weight={400}>
                    { viewModel.vaccineTypeSelect.label }
                </Text>
                <Text
                    size="md"
                    weight={600}
                >
                    { vaccineTypeHelper(viewModel.data?.type ?? "") }
                </Text>
            </Stack>
            <Stack spacing={2}>
                <Text color="dimmed" component="label" htmlFor="vaccineName" size="sm" weight={400}>
                    { viewModel.vaccineNameLabel.label }
                </Text>
                <Text
                    size="md"
                    weight={600}
                >
                    {viewModel.data?.name ?? ""}
                </Text>
            </Stack>
            <Stack spacing={2}>
                <Text color="dimmed" component="label" htmlFor="vaccineNumber" size="sm" weight={400}>
                    { viewModel.vaccineNumberLabel.label }
                </Text>
                <Text
                    size="md"
                    weight={600}
                >
                    {viewModel.data?.serial ?? ""}
                </Text>
            </Stack>
            <Stack spacing={2}>
                <Text color="dimmed" component="label" htmlFor="vaccineDateLabel" size="sm" weight={400}>
                    { viewModel.vaccineDateLabel.label }
                </Text>
                <Text
                    size="md"
                    weight={600}
                >
                    { dateToHumanString(viewModel.data?.takeover) ?? ""}
                </Text>
            </Stack>
            <Stack spacing={2}>
                <Text color="dimmed" component="label" htmlFor="revaccineDateLabel" size="sm" weight={400}>
                    { viewModel.revaccineDateLabel.label }
                </Text>
                <Text
                    size="md"
                    weight={600}
                >
                    { dateToHumanString(viewModel.data?.repeatVaccinationTakeover) ?? "" }
                </Text>
            </Stack>
            <Stack spacing={2}>
                <Text color="dimmed" component="label" htmlFor="vaccineDeadline" size="sm" weight={400}>
                    { viewModel.vaccineDateValidLabel.label }
                </Text>
                <Text
                    size="md"
                    weight={600}
                >
                    { dateToHumanString(viewModel.data?.expiration) ?? "" }
                </Text>
            </Stack>
            <Stack spacing={2}>
                { fileView() }
            </Stack>
        </Stack>
    );

    return (
        <>
        <Container p={0}>
            { mainView }
            <LoadingOverlay visible={viewModel.loading} overlayBlur={5} />
        </Container>
        </>
    )
}