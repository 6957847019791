import axios from "axios";
import { BaseURL } from "../BasicURLScheme";
import { DocumentLiteDTO } from "./VaccinationService";

export class EctoparasitesService {
    private baseURL: BaseURL = BaseURL.shared;
    private module: string = "/api/modern/ectoparasites"

    public async fetchList(petID: string) {
        const token = this.baseURL.obtainHeader();
        return axios.get<Array<EctoparasitesAPIDTO>>(
            this.baseURL.buildEndpoin(`${this.module}/pet/${petID}`),
            {
                headers: {
                    ...token
                }
            }
        );
    }

    public async fetchDetails(id: string) {
        const token = this.baseURL.obtainHeader();
        return axios.get<EctoparasitesAPIDTO>(
            this.baseURL.buildEndpoin(`${this.module}/${id}`),
            {
                headers: {
                    ...token
                }
            }
        );
    }

    public async create(dto: EctoparasitesCreateAPIDTO) {
        const token = this.baseURL.obtainHeader();

        return axios({
            method: "POST",
            url: this.baseURL.buildEndpoin(`${this.module}/`),
            headers: { ...token },
            data: { ...dto }
        });
    }

    public async update(dto: EctoparasitesUpdateAPIDTO) {
        const token = this.baseURL.obtainHeader();

        return axios({
            method: "PUT",
            url: this.baseURL.buildEndpoin(`${this.module}/`),
            headers: { ...token },
            data: { ...dto }
        });
    }

    public async delete(id: string) {
        const token = this.baseURL.obtainHeader();

        return axios.delete(
            this.baseURL.buildEndpoin(`${this.module}/${id}`),
            {
                headers: {
                    ...token
                }
            }
        );
    }
}

export interface EctoparasitesCreateAPIDTO {
    petID: string;
    name: string;
    validWeeks: number;
    takeover: string;
}

export interface EctoparasitesUpdateAPIDTO {
    id: string;
    name?: string;
    validWeeks?: number;
    takeover?: string;
}

export interface EctoparasitesAPIDTO {
    id: string;
    name: string;
    validWeeks: number;
    takeover: string;
    documentDTOs: DocumentLiteDTO[];
    isEditable: Boolean;
}