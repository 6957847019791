import { createStyles, Paper, Text, rem, Stack, Group, ActionIcon } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
  card: {
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'transform 150ms ease, box-shadow 100ms ease',
    padding: theme.spacing.xl,
    paddingLeft: `calc(${theme.spacing.xl} * 1.4)`,

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: rem(6),
      backgroundImage: theme.fn.linearGradient(0, theme.colors.dark[4], theme.colors.green[6]),
    },
  },
}));

export interface MedicalItemInfoModel {
  medicalID: string;
  type: string;
  content: { title: string; subtitle: string }[];
};

interface CardGradientProps {
  title: string;
  infoModel: MedicalItemInfoModel;
  onClick: (medicalID: string) => void;
}

export function CardGradient({ title, infoModel, onClick }: CardGradientProps) {
  const { classes } = useStyles();

  const content = infoModel.content.map((item) => {
    return (
        <Stack spacing={0} mb={0}>      
            <Text size="sm" color="dimmed" weight={500}>
                {item.subtitle}
            </Text>
            <Text size="sm" weight={600}>
                {item.title}
            </Text>
        </Stack>
    );
  });

  return (
    <Paper mih={128} onClick={() => { onClick(infoModel.medicalID) }} withBorder radius="lg" className={classes.card}>
        <Group position="apart" noWrap>
        <Stack spacing={0}>
        <Group mt={2} mb={5}>
            <Text size="xl" weight={700} lineClamp={1}>
                {title}
            </Text>
        </Group>
        <Group noWrap spacing={12}>
            { content }
        </Group>
        </Stack>
        <ActionIcon><IconChevronRight /></ActionIcon>
        </Group>
    </Paper>
  );
}