import { Container, TextInput, Stack, Text, Button, Group } from "@mantine/core";
import { useEffect, useState } from "react";
import { DateLargePickerUTC } from "../../../Core/DateLargePickerUTC";
import { notifications } from "@mantine/notifications";
import { CareFileInputView } from "../../../Core/FileInputComponent/CareFileInputView";
import { useTranslation } from "react-i18next";
import { TestCreateAPIDTO, TestsService } from "../../../Domain/Medical/TestService";
import { FileService, MedicalDocumentTypeDTO } from "../../../Domain/FileService/FileService";

export interface TestCardCreateInterface {
    petID: string;
    moduleDidCloseAfterSuccess: () => void;
}

export function TestCardCreateViewModel(infoModel: TestCardCreateInterface) {
    type TestPole = "testName" | "testingDate";

    interface TestFillDataInterface {
        testName?: string;
        testingDate?: Date;
    }    

    const { t } = useTranslation();
    const [data, setData] = useState<TestFillDataInterface>({});
    const [files, setFiles] = useState<File[]>([]);
    const [isActionButtonEnabled, setIsActionButtonEnabled] = useState(false);
    const actionButtonTitle = t("Create");
    const testService = new TestsService();
    const fileService = new FileService();

    const [testName] = useState({
        label: t("Test name"),
        placeholder: t("Rabies test")
    });

    const [testDate] = useState({
        label: t("Test date"),
        placeholder: t("Choose a date")
    });

    const [vaccineFilesValidLabel] = useState({
        label: t("You can attach files"),
        accept: "image/png,image/jpeg",
        description: t("Maximum number of files - 5"),
        placeholder: t("Select files. For now, png/jpeg files are accepted")
    });

    function changedValue(value: any, prop: TestPole) {
        setData(
            { ...data, [prop]:value }
        );
    };

    useEffect(() => {}, []);

    function buttonTapped() {
        setIsActionButtonEnabled(true);

        if (!data) { return; }

        if (!data.testName || data.testName.length === 0) {
            notificateError(t("You did not fill in the name of the test"));
            setIsActionButtonEnabled(false);
            return;
        }
        if (!data.testingDate) {
            notificateError(t("You did not fill in the test date"));
            setIsActionButtonEnabled(false);
            return;
        }

        const dto: TestCreateAPIDTO = {
            petID: infoModel.petID,
            name: data.testName,
            results: undefined,
            procedureDate: data.testingDate.toISOString()
        };

        testService.create(dto)
        .catch((error) => { 
            notificateErrorCreation();
            console.log(error);
            setIsActionButtonEnabled(false);
        })
        .then((result) => {
            console.log(result);

            if (!result || !result.data) { return; }

            if (files.length > 0) {
                fileService.uploadMedicalFiles(result.data.id, MedicalDocumentTypeDTO.test, files)
                .catch(error => {
                    console.log(error);
                })
                .then(result => {
                    if (!result) { return }
        
                    if (result.status == 200) {
                        close();
                    }
                });        
            } else {
                close();
            }
        });
    };

    function close() {
        notificateCreateSuccess();
        setIsActionButtonEnabled(false);
        infoModel.moduleDidCloseAfterSuccess();
    }

    function notificateError(message: string) {
        notifications.show({
            title: t("Incorrect data"),
            message: message,
            autoClose: 5000,
            color: "red"  
        })
    };

    function notificateCreateSuccess() {
        notifications.show({
            title: t("Success!"),
            message: t("Testing has been successfully created"),
            autoClose: 5000,
            color: "green"
        });
    };

    function notificateErrorCreation() {
        notifications.show({
            title: t("Error("),
            message: t("Test creation error, try again later, please"),
            autoClose: 5000,
            color: "red"
        });
    };

    return({
        data,
        testName,
        testDate,
        vaccineFilesValidLabel,
        isActionButtonEnabled,
        actionButtonTitle,
        changedValue,
        setFiles,
        buttonTapped
    })
}

export function TestCardCreateView(infoModel: TestCardCreateInterface) {
    const {
        data,
        testName,
        testDate,
        vaccineFilesValidLabel,
        isActionButtonEnabled,
        actionButtonTitle,
        changedValue,
        setFiles,
        buttonTapped
    } = TestCardCreateViewModel(infoModel);

    useEffect(() => {}, []);

    const mainView = (
        <>
            <Stack>
                <Stack spacing={6}>
                    <Text component="label" htmlFor="nameDrug" size="sm" weight={500}>
                        { testName.label }
                    </Text>
                    <TextInput
                        value={data.testName ?? ""}
                        onChange={e => { changedValue(e.target.value.toString(), "testName")}}
                        placeholder={ testName.placeholder }
                        size="sm"
                        radius="md"
                    />
                    <Text component="label" htmlFor="takeDate" size="sm" weight={500}>
                        { testDate.label }
                    </Text>
                    <Group position="center" align="center">
                    <DateLargePickerUTC
                        value={ data.testingDate}
                        onChange={e => { changedValue(e, "testingDate")} }
                        size="sm"
                        placeholder={ testDate.placeholder}
                    />
                    </Group>
                <Stack spacing={6}>
                    <Text component="label" htmlFor="revaccinationDate" size="sm" weight={500}>
                        { vaccineFilesValidLabel.label }
                    </Text>
                    <CareFileInputView
                        description={vaccineFilesValidLabel.description}
                        placeholder={vaccineFilesValidLabel.placeholder}
                        accept={vaccineFilesValidLabel.accept}
                        filesDidChange={setFiles}
                    />
                </Stack>
                </Stack>
                <Button 
                    radius="md" 
                    onClick={() => { buttonTapped() }}
                    loading={isActionButtonEnabled}
                >
                    {actionButtonTitle}
                </Button>
            </Stack>
        </>
    );

    return (
        <Container p={0}>
            { mainView }
        </Container>
    );
}