import { ActionIcon, AspectRatio, Container, Group, Modal, Space, Title, Text, LoadingOverlay, Image, Center, Button } from "@mantine/core";
import { IconSquareRoundedXFilled } from "@tabler/icons-react";
import FileSaver from "file-saver";
import { useEffect, useState } from "react";
import { DocumentDTO } from "../../Domain/Ambulance/AmbulanceService";
import { modals } from '@mantine/modals';
import { FileService } from "../../Domain/FileService/FileService";
import { useTranslation } from "react-i18next";
import { DocumentLiteDTO } from "../../Domain/Medical/VaccinationService";
import { MedicalDocumentsService } from "../../Domain/MedicalDocumentsService";
import { error } from "console";
import { ToastManager } from "../../Modules/Map/ToastManager";

export interface DocumentViewerInterface {
    file?: DocumentLiteDTO,
    click: boolean,
    setClick: (_: boolean) => void;
    update: () => void;
};

export function CDocumentViewerViewModel(infoModel: DocumentViewerInterface) {
    const fileService = new FileService();
    const medicalDocumentService = new MedicalDocumentsService();

    const file = infoModel.file;
    var currentFile: DocumentLiteDTO | undefined;
    const { t } = useTranslation();

    const [fileImage, setFileImage] = useState<Blob | null>();
    const [loading, setLoading] = useState<boolean>(true);

    const openModal = (fileName: string, onCancel: () => void, onConfirm: () => void) => modals.openConfirmModal({
        title: <Title order={5}>{t("Confirm action")}</Title>,
        children: (
          <Text size="sm">
            {`${t("Are you sure you want to delete the file")} ${fileName}? ${t("A deleted file cannot be returned.")}`}
          </Text>
        ),
        labels: { confirm: t('Delete'), cancel: t('Cancel') },
        onCancel: onCancel,
        onConfirm: onConfirm,
    });

    useEffect(() => {
        if (currentFile != infoModel.file && infoModel.file) {
            currentFile = infoModel.file;

            setLoading(true);
            setFileImage(null);

            medicalDocumentService.obtainDetalis(infoModel.file.id)
            .catch((error) => {
                console.log(error);
                setLoading(false);
                ToastManager.getInstance()
                    .showError(t("There are no coordinates of the place you want to create. Reload the page and try again"));
                return
            })
            .then((response) => {
                if (!response || !response.data) {
                    return;
                }

                fileService.obtainFileData(response.data.fileID)
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    return
                })
                .then((file) => {
                    if (!file) { return }

                    setFileImage(file);
                    setLoading(false);
                })
            })
        }
    }, [infoModel.file]);

    function openModelAlert() {
        openModal(infoModel.file?.name ?? "", () => {} , deleteFile);
    }

    function saveFile() {
        if (!fileImage) { return }
        FileSaver.saveAs(fileImage, infoModel.file?.name ?? "some_file");
    }

    function deleteFile() {
        if (!infoModel.file?.id) { return; }

        medicalDocumentService.remove(infoModel.file.id)
            .catch(error => {
                console.log(error);
                return
            })
            .then(result => {
                if (!result) {
                    return;
                }

                if (result) {
                    infoModel.setClick(false);
                    infoModel.update();
                }
            }
        )
    };

    return ({
        file,
        fileImage,
        loading,
        opened: infoModel.click,
        setOpened: infoModel.setClick,
        openModal: openModelAlert,
        saveFile: saveFile,
    })
}

export function CDocumentViewerView(infoModel: DocumentViewerInterface) {
    const viewModel = CDocumentViewerViewModel(infoModel);
    const { t } = useTranslation();

    return (
        <Modal
            padding="lg" centered radius="lg" size="lg" withCloseButton={false}
            opened={ viewModel.opened }
            onClose={() => viewModel.setOpened(false)}
        >
            <Container>
                <Group position="apart" noWrap>
                    <Title truncate order={1} size="h4">{ t(`Viewing document`)}</Title>
                    <ActionIcon onClick={() => { viewModel.setOpened(false) }}>
                        <IconSquareRoundedXFilled />
                    </ActionIcon>
                </Group>
                <Space h={6} />
                <Text lineClamp={4} weight={500}>{ `${viewModel.file?.name ?? ""}`}</Text>
                <Space h={12} />
                <AspectRatio ratio={2480 / 3508} maw={600} mx="auto">
                    <Image src={viewModel.fileImage ? URL.createObjectURL(viewModel.fileImage) : ""} />
                    <LoadingOverlay visible={viewModel.loading} overlayBlur={2} />
                </AspectRatio>
                <Space h={22} />
                <Center>
                    <Group position="center" spacing={12}>
                    <Button onClick={ viewModel.openModal } variant="outline" color="red">{t("Delete file")}</Button>
                    <Button onClick={ viewModel.saveFile } radius="md">{t("Download")}</Button>
                    </Group>
                </Center>
            </Container>
        </Modal>
    );
};