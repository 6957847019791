import { useState } from "react";
import { PetDTO, PetListService } from "../../Domain/Pets/PetService";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { UserInfoIconsProps } from "./PetListItemView";
import { useNavigate } from "react-router-dom";
import { dateToHumanString } from "../../Core/Extensions/ConvertToDateFromTS";

export function PetListViewModel() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const localized = {
        obtainPetsError: {
            title: t("Error obtain your pets"),
            message: t("Error list obtain")
        }
    }

    const petService = new PetListService();

    let petDTOs: Array<PetDTO>
    const [content, setContents] = useState<Array<UserInfoIconsProps>>([]);
    const [state, setState] = useState<"loading" | "loaded" | "error" | "empty">("loading")

    const itemTapped = (id: string) => {
        navigate(`/pets/${id}`);
    };

    function obtainPets() {
        setState("loading")
        petService.obtainPetList()
        .catch(error => {
            petDTOs = [];
            showNotification({
                title: localized.obtainPetsError.title,
                message: localized.obtainPetsError.message,
                color: "red"
            });
            setState("error");
        })
        .then(result => {
            if (!result) {
                return 
            }
            if (result.status != 200) {
                setState("error");
                return
            }

            petDTOs = result.data;
            const newContent = result.data.map((petDTO, index) => (
                {
                    id: petDTO.id,
                    avatar: petDTO.imageID,
                    name: petDTO.name,
                    weight: petDTO.weight?.toString() ?? "-",
                    height: petDTO.height?.toString() ?? "-",
                    birsday: dateToHumanString(petDTO.birthday),
                    onTapped: itemTapped
                }
            ));
            setContents(newContent)
            setState(result.data.length > 0 ? "loaded" : "empty")
        })
    }

    return {
        state,
        obtainPets,
        content
    }
}