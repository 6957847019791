import { 
    Button,
    Container,
    createStyles,
    FileButton, 
    Group, 
    LoadingOverlay, 
    NumberInput,
    Select,
    Space,
    Stack,
    Text,
    TextInput } from "@mantine/core";
import { Avatar } from '@mantine/core';
import { useState } from "react";
import { DatePickerUTC } from "../../Core/DatePickerUTC";
import { PetDTO, PetListService, PetUpdateDTO } from "../../Domain/Pets/PetService";
import placeholder_image from "../../Resources/Assets/square.svg";
import { t } from "i18next";

export interface CPetEditCardInfoModel {
    avatar: any;
    petDTO: PetDTO;
    update: () => void;
}

interface CPetEditFillDataInterface {
    petName?: string;
    petHeight?: number;
    petWeight?: number;
    petBirthday?: Date;
    petColour?: string;
    petBreed?: string;
    petGender?: string;
    petType?: string;
}

export function PetEditViewModel(infoModel: CPetEditCardInfoModel) {
    const petService = new PetListService();

    type PetEditPole = "petName" | "petHeight" | "petBirthday" | 
                                "petColour" | "petBreed" | "petGender" |
                                "petWeight" | "petType";

    const originalPetDTO = infoModel.petDTO;

    const [data, setData] = useState<CPetEditFillDataInterface>({
        petName: originalPetDTO.name,
        petHeight: originalPetDTO.height,
        petBirthday: new Date(originalPetDTO.birthday),
        petBreed: originalPetDTO.breed,
        petColour: originalPetDTO.colour,
        petGender: originalPetDTO.genderType,
        petWeight: originalPetDTO.weight,
        petType: originalPetDTO.biologicalKindType
    });
    const [file, setFile] = useState<File | null>(null);
    const [dataLoading, setDataLoading] = useState(false);
    const [avatarLoading, setAvatarLoading] = useState(false);
    const [dataChanged, setDataChanged] = useState(false);
    const [imageChanged, setImageChanged] = useState(false);

    function valueChanged(value: any, pole: PetEditPole) {
        const newData = { ...data, [pole]:value };

        setData(
            { ...data, [pole]:value }
        );
        checkChange(newData);
    };

    function checkChange(newData: CPetEditFillDataInterface) {
        const data = new Date(originalPetDTO.birthday);

        setDataChanged(
            (
                newData.petName !== originalPetDTO.name ||
                newData.petHeight !== originalPetDTO.height ||
                newData.petBirthday?.toISOString() !== data.toISOString() ||
                newData.petBreed !== originalPetDTO.breed ||
                newData.petColour !== originalPetDTO.colour ||
                newData.petGender !== originalPetDTO.genderType ||
                newData.petWeight !== originalPetDTO.weight ||
                newData.petType !== originalPetDTO.biologicalKindType
            )
        );
    };

    function checkChangeImage(file?: File) {
        setImageChanged(file != null);
    };

    function saveTapped() {
        const dto: PetUpdateDTO = {
            name: data.petName,
            height: data.petHeight,
            weight: data.petWeight,
            birthday: data.petBirthday != null ? data.petBirthday.toISOString() : undefined,
            gender: data.petGender,
            colour: data.petColour,
            breed: data.petBreed,
            kind: data.petType
        };

        if (dataChanged) {
            saveChangedData(dto);
        }

        if (file && imageChanged) {
            saveAvatar(file);
        };
    };

    function saveChangedData(dto: PetUpdateDTO) {
        setDataLoading(true);
        petService.updatePet(originalPetDTO.id, dto)
            .catch(error => {
                setDataLoading(false);
            })
            .then(result => {
                if (!result) { return; }
                infoModel.update();
                setDataLoading(false);
                setDataChanged(false);
            }
        );
    };

    function saveAvatar(file: File) {
        setAvatarLoading(true);
        petService.updateAvatar(originalPetDTO.id, file)
            .catch(error => {
                setAvatarLoading(false);
            })
            .then(result => {
                if (!result) { return; }
                infoModel.update();

                setAvatarLoading(false);
                setImageChanged(false);
            }
        );
    };

    function changeFiles(file: File) {
        setFile(file);
        checkChangeImage(file);
    };

    return ({
        data, file, dataLoading, avatarLoading, dataChanged, imageChanged,
        valueChanged, saveTapped, changeFiles
    });
}

export function CPetEditCardView(model: CPetEditCardInfoModel) {
    const viewModel = PetEditViewModel(model);

    const useStyles = createStyles((theme) => ({
        input: {
            fontWeight: 600,
        }}
        )
    );
    const { classes } = useStyles();

    return (
        <>
            <Container p={0}>
                <Stack>
                    <Stack spacing={8}>
                        <Group position="center" spacing={0}>
                        <FileButton multiple={false} onChange={viewModel.changeFiles} accept="image/png,image/jpeg">
                            {(props) => 
                                <Avatar radius={150 / 2} size={150} {...props} src={ viewModel.file != null ? URL.createObjectURL(viewModel.file) : model.avatar ?? placeholder_image}></Avatar>
                            }
                        </FileButton>
                        </Group>
                        <Text align="center" size="sm" weight={600} color="dimmed">{t("To update the avatar, click on it")}</Text>
                    </Stack>
                    <Stack spacing={8}>
                        <Text
                            size="sm"
                            weight={600}
                            color="dimmed"
                        >
                            {t("Pet name")}
                        </Text>
                        <TextInput
                            size="sm"
                            classNames={classes}
                            value={viewModel.data.petName ?? ""}
                            placeholder={t("Timon")!}
                            onChange={(e) => { viewModel.valueChanged(e.target.value, "petName") }}
                            radius="md"
                        />
                    </Stack>
                    <Stack spacing={8}>
                        <Text
                            size="sm"
                            weight={600}
                            color="dimmed"
                        >
                            {t("Height at the withers")}
                        </Text>
                        <Group>
                            <NumberInput
                                size="sm"
                                classNames={classes}
                                value={viewModel.data.petHeight ?? ""}
                                placeholder="42"
                                onChange={(e) => { viewModel.valueChanged(e, "petHeight") }}
                                radius="md"
                                min={1}
                                precision={1}
                                step={1}
                                max={150}
                            />
                            <Text weight={600}>{t("cm")}</Text>
                        </Group>
                    </Stack>
                    <Stack spacing={8}>
                        <Text
                            size="sm"
                            weight={600}
                            color="dimmed"
                        >
                            {t("Weight")}
                        </Text>
                        <Group>
                            <NumberInput
                                size="sm"
                                classNames={classes}
                                value={viewModel.data.petWeight ?? ""}
                                placeholder="24"
                                onChange={(e) => { viewModel.valueChanged(e, "petWeight") }}
                                radius="md"
                                precision={1}
                                min={1}
                                step={1}
                                max={150}
                            />
                            <Text weight={600}>{t("kg")}</Text>
                        </Group>
                    </Stack>
                    <Stack spacing={8}>
                        <Text
                            size="sm"
                            weight={600}
                            color="dimmed"
                        >
                            {t("Date of birth of D.M.G.")}
                        </Text>
                        <DatePickerUTC
                            size="sm"
                            classNames={classes}
                            value={viewModel.data.petBirthday}
                            placeholder="30.11.2018"
                            onChange={(e) => { viewModel.valueChanged(e, "petBirthday") }}
                            radius="md"
                        />
                    </Stack>
                    <Stack spacing={8}>
                        <Text
                            size="sm"
                            weight={600}
                            color="dimmed"
                        >
                            {t("Colour")}
                        </Text>
                        <TextInput
                            size="sm"
                            classNames={classes}
                            value={viewModel.data.petColour ?? ""}
                            placeholder={t("Brown")!}
                            onChange={(e) => { viewModel.valueChanged(e.target.value, "petColour") }}
                            radius="md"
                        />
                    </Stack>
                    <Stack spacing={8}>
                        <Text
                            size="sm"
                            weight={600}
                            color="dimmed"
                        >
                            {t("Breed")}
                        </Text>
                        <TextInput
                            size="sm"
                            classNames={classes}
                            value={viewModel.data.petBreed ?? ""}
                            placeholder={t("Half-breed Siba-inu and Siberian husky")!}
                            onChange={(e) => { viewModel.valueChanged(e.target.value, "petBreed") }}
                            radius="md"
                        />
                    </Stack>
                    <Stack spacing={8}>
                        <Text
                            size="sm"
                            weight={600}
                            color="dimmed"
                        >
                            {t("Gender of the pet")}
                        </Text>
                        <Select
                            size="sm"
                            classNames={classes}
                            value={viewModel.data.petGender ?? ""}
                            placeholder={t("Choose a gender")!}
                            onChange={e => viewModel.valueChanged(e, "petGender")}
                            radius="md"
                            data={[
                                { value: "boy", label: t("Boy")! },
                                { value: "girl", label: t("Girl")! },
                            ]}
                        />
                    </Stack>
                    <Stack spacing={8}>
                        <Text
                            size="sm"
                            weight={600}
                            color="dimmed"
                        >
                            {"Type of pet"}
                        </Text>
                        <Select
                            size="sm"
                            classNames={classes}
                            value={viewModel.data.petType ?? ""}
                            placeholder={t("Select a type")!}
                            onChange={e => viewModel.valueChanged(e, "petType")}
                            radius="md"
                            data={[
                                { value: "cat", label: t("Cat")! },
                                { value: "dog", label: t("Dog")! },
                            ]}
                        />
                    </Stack>
                    <Space />
                    <Button onClick={viewModel.saveTapped} radius="md" disabled={!(viewModel.dataChanged || viewModel.imageChanged)}>{t("Update")}</Button>
                    <LoadingOverlay visible={viewModel.dataLoading || viewModel.avatarLoading} overlayBlur={2} />
                </Stack>
            </Container>
        </>
    )
}