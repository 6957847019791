import { Box, Container, createStyles, Grid, SimpleGrid, Title, Space, Transition } from "@mantine/core";
import { useElementSize, useViewportSize } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CGradientView } from "../../Core/CGradientView/CGradientView";
import { CModalProvider } from "../../Core/CModalProvider/CModalProvider";
import { PetDTO } from "../../Domain/Pets/PetService";
import { CPetEditCardInfoModel } from "../PetEditCard/CPetEditCardView";
import { CMedicineListType, CMedicineListView } from "../PetDetailsMedicaList/CMedicineListView";
import { CPetInfoView } from "./CPetInfoView";
import { CPetMiniGridItemView } from "./CPetMiniGridItemView";
import { DataObservable } from "../../Core/DataObservable";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export type CPetDetailsGlobalLoading = "loading" | "loaded";

interface CPetDetailsViewModelBuilder {
    needUpdate: () => void;
};

function CPetDetailsViewModel(builder: CPetDetailsViewModelBuilder) {
    const { petID } = useParams();

    const developContent = {
        title: "care.ammunition",
        subtitle: t("This section is under development, but it will be interesting soon!")
    }

    const [chipID, _chipID] = useState<string | undefined>(undefined);
    const [brandID, _brandID] = useState<string | undefined>(undefined);
    const [submoduleViewState, _submoduleViewState] = useState<CPetDetailsGlobalLoading>("loading");

    const modalProvider = CModalProvider();

    function petDidUpdated(petDTO: PetDTO) {
        _chipID(petDTO.chipDTO?.id);
        _brandID(petDTO.brandDTO?.id);
        _submoduleViewState("loaded");
    };

    function needUpdateTable() {
        DataObservable.getInstance().notifyObservers("medicalTable");
    };

    function handleAddTapped(tab: CMedicineListType) {
        modalProvider.chooseMedicalCreateView({
            tab: tab,
            petID: petID ?? "",
            moduleDidCloseAfterSuccess: needUpdateTable,   
        });
    };

    function handleDetailsTapped(medicalID: string, tab: CMedicineListType) {
        modalProvider.chooseMedicalDetailsView({
            tab: tab,
            petID: petID ?? "",
            medicalID: medicalID
        });
    };

    function petDetailsTapped(infoModel: CPetEditCardInfoModel) {
        modalProvider.openPetCard(infoModel)
    };

    function itemTapped(type: "chip" | "brand") {
        switch (type) {
            case "brand":
                modalProvider.openBrandCard({
                    brandID: brandID,
                    petID: petID ?? "",
                    needUpdate: needUpdatePetDTO
                });
                break;

            case "chip":
                modalProvider.openChipCard({
                    chipID: chipID,
                    petID: petID ?? "",
                    needUpdate: needUpdatePetDTO
                });
                break;
        }
    };

    function needUpdatePetDTO() {
        builder.needUpdate();
    };

    return ({
        petID,
        developContent,
        chipID, brandID,
        submoduleViewState,
        petDidUpdated,
        handleAddTapped,
        handleDetailsTapped,
        petDetailsTapped,
        itemTapped,
        mobileView: modalProvider.drawerProvider,
        desktopView: modalProvider.modalProvider
    })
};

export function CPetDetailsView() {
    const { classes } = createStyles((theme) => ({
        wrapper: {
            padding: 0,
        },

        title: {
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontSize: 24,
            marginBottom: 20,
        },

        borders: {
            borderRadius: theme.radius.lg
        }
    }))();

    const viewModel = CPetDetailsViewModel({ needUpdate: () => { petInfoModule.obtainPet() }});
    const { t } = useTranslation();
    const { ref, height } = useElementSize();

    const petInfoModule = CPetInfoView({petID: viewModel.petID ?? "", petDidUpdated: viewModel.petDidUpdated, detailsTapped: viewModel.petDetailsTapped})

    const gridView = (
        <SimpleGrid cols={2} spacing="md" breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
            <Box className={classes.borders} p={0} ref={ref}>
                { petInfoModule.view }       
            </Box>
            <Grid gutter={10}>
                <Grid.Col>
                <CGradientView
                    radius="lg" imageAsset="random_grid" content={viewModel.developContent}
                    h={height / 2 - 5} p={0} color="white"
                />
                </Grid.Col>
                <Grid.Col span={6}>
                    <CPetMiniGridItemView dataBuilder={{ buttonTapped: viewModel.itemTapped, loadingState: viewModel.submoduleViewState, objectID: viewModel.chipID, type: "chip" }} h={height / 2 - 5} />
                </Grid.Col>
                <Grid.Col span={6}>
                    <CPetMiniGridItemView dataBuilder={{ buttonTapped: viewModel.itemTapped, loadingState: viewModel.submoduleViewState, objectID: viewModel.brandID, type: "brand" }} h={height / 2 - 5} />
                </Grid.Col>
            </Grid>
        </SimpleGrid>
    );

    const size = useViewportSize();

    const mainView = (
        <>
            { gridView }
            <Space h={8} />
            <CMedicineListView
                dataBuilder={{
                    petID: viewModel.petID ?? "",
                    addTapped: viewModel.handleAddTapped,
                    detailsTapped: viewModel.handleDetailsTapped
                }}
                minheight={300}
                maxheight={500}
                height={ size.height - 120 - height }
            />
        </>
    );

    const [mounted, _mounted] = useState(false);
    useEffect(() => {
        _mounted(true);
    }, []);

    return (
        <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
                    {(styles) => <div style={{height: "100%", ...styles}}>
                    <Container className={classes.wrapper} pb="sm" size="lg">
            <Title className={classes.title}>{t("My pets")}</Title>
            { mainView }
            { viewModel.mobileView }
            { viewModel.desktopView }
        </Container>
                </div>}
        </Transition>  
    );
};