import { CLocation2DPoint, DangerAreaPoint, PlaceClientDTO, PlaceFitler, PlaceService } from "../../Domain/Places/PlaceService";

export class MapInteractor {
    constructor() {
        this.service = new PlaceService();
    }

    service: PlaceService;

    obtainPlaces(location: CLocation2DPoint, filters: Array<PlaceFitler>, isOwners: boolean) {
        const filter = (): PlaceFitler => {
            if (filters.includes("safe") && filters.includes("danger")) {
                return "all";
            }

            return filters[0]
        }

        if (isOwners) {
            return this.service.obtainOwnerPlaces(location, filter());
        } else {
            return this.service.obtainPlaces(location, filter());
        }
    };

    createPlace(location: CLocation2DPoint, placeType: PlaceFitler, comment: string) {
        const dto: PlaceClientDTO = {
            latitude: location.latitude,
            longitude: location.longtitude,
            messageText: comment,
            type: placeType.toString()
        }

        return this.service.createPlace(dto);
    };

    obtainPlace(elementID: string) {
        return this.service.obtainPlace(elementID);
    };

    deletePlace(placeID: string) {
        return this.service.deletePlace(placeID);
    }
};
