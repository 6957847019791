import axios from "axios";
import { BaseURL } from "../BasicURLScheme";
import Cookies from 'universal-cookie';

export class AuthorizationService {
    private baseURLService: BaseURL = BaseURL.shared;

    public async login(request: LoginRequest) {
        return axios.post<LoginResponse>(BaseURL.shared.buildEndpoin("/api/authentication/login"),
        { ...request })
        .then(response => {
            this.baseURLService.setToken(response.data);
            return response.data;
        })
    }

    public async register(request: RegisterRequest) {
        return axios.post<LoginResponse>(BaseURL.shared.buildEndpoin("/api/authentication/register"),
        { ...request})
        .then(response => {
            this.baseURLService.setToken(response.data);
            return response.data
        })
    }

    public async validate() {
        const data  = this.baseURLService.obtainToken();

        return (data.userID && data.userID.length > 0 && data.token && data.token.length > 0)
    }
}

export interface LoginResponse {
    token: string,
    userID: string
}

interface LoginRequest {
    email: string
    password: string
}

interface RegisterRequest {
    email: string
    password: string
    name: string
}