import axios, { AxiosRequestConfig } from "axios";
import { BaseURL } from "../BasicURLScheme";

export enum MedicalDocumentTypeDTO {
    deworming = "deworming",
    test = "test",
    notes = "notes",
    examination = "examination",
    ectoparasites = "ectoparasites",
    vaccination = "vaccination"
}

export class FileService {
    private baseURL: BaseURL = BaseURL.shared;

    public async uploadMedicalFiles(medicalID: string, medicalType: MedicalDocumentTypeDTO, files: File[]) {
        const token = this.baseURL.obtainHeader();

        const formData = new FormData();
        files.forEach((file, index) => {
            const blob = new Blob([file], {
                type: `${file.type}`,
              });
              
            formData.append(`file_${index}`, blob, file.name); 
        });

        return axios.post(this.baseURL.buildEndpoin(`api/modern/files/`), formData, {
            headers: { 
                ...token,
                "documentType": "medical",
                "medicalID": medicalID,
                "medicineType": medicalType,
            }
        })
    }

    public async obtainFile(id?: string) {
        if (!id) {
            throw new Error("Response error: " + "No url");
        }

        const token = this.baseURL.obtainHeader();

        try {
            const response = await fetch(
                this.baseURL.buildEndpoin(`api/modern/files/file/${id}`), {
                    method: "GET",
                    headers: {
                        ...token
                    }
                }
            );
            
            if (!response.ok) {
                throw new Error("Response error: " + response.status);
            }
            const data = await response.blob();
            const image = URL.createObjectURL(data);
            return image;
        } catch(error) {
            throw error;
        }
    }

    public async obtainFileData(id?: string) {
        if (!id) {
            throw new Error("Response error: " + "No url");
        }

        const token = this.baseURL.obtainHeader();

        try {
            const response = await fetch(
                this.baseURL.buildEndpoin(`api/modern/files/file/${id}`), {
                    method: "GET",
                    headers: {
                        ...token
                    }
                }
            );
            
            if (!response.ok) {
                throw new Error("Response error: " + response.status);
            }
            const data = await response.blob();
            return data;
        } catch(error) {
            throw error;
        }
    }
}
