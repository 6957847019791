import { BackgroundImage, Text, Box, createStyles, MantineNumberSize, Paper, PaperProps, Stack, MantineColor, useMantineTheme, MediaQuery, rem } from "@mantine/core";
import React, { ReactElement, useEffect, useState } from "react";
import gr_1  from "../../Resources/Assets/gr/gr_1.png";
import gr_2  from "../../Resources/Assets/gr/gr_2.png";
import gr_3  from "../../Resources/Assets/gr/gr_3.png";
import gr_4  from "../../Resources/Assets/gr/gr_4.png";
import gr_5  from "../../Resources/Assets/gr/gr_5.png";
import gr_6  from "../../Resources/Assets/gr/gr_6.png";
import gr_7  from "../../Resources/Assets/gr/gr_7.png";
import gr_8  from "../../Resources/Assets/gr/gr_8.png";
import gr_9  from "../../Resources/Assets/gr/gr_9.png";
import gr_10  from "../../Resources/Assets/gr/gr_10.png";
import gr_11  from "../../Resources/Assets/gr/gr_11.png";
import gr_12  from "../../Resources/Assets/gr/gr_12.png";
import gr_13  from "../../Resources/Assets/gr/gr_13.png";
import gr_14 from "../../Resources/Assets/gr/gr_14.png";
import gr_15 from "../../Resources/Assets/gr/gr_15.png";

interface CGradientViewBuilder {
    radius: MantineNumberSize;
    children?: JSX.Element;
    content?: { title: string; subtitle: string; };
    imageAsset: "random_gradient" | "random_grid";
    h?: number;
    p?: number;
    color?: MantineColor;
}

export function randomGradient() {
    const array = [gr_1, gr_2, gr_3, gr_4, gr_5, gr_6, gr_7, gr_8, gr_9, gr_10, gr_11, gr_12, gr_13, gr_14, gr_15]
    const element = array[Math.floor(Math.random() * array.length)];
    return element;
};

export function CGradientView(builder: CGradientViewBuilder): ReactElement {
    const theme = useMantineTheme();
    const [gradient, setGradient] = useState("");

    const { classes } = createStyles((theme) => ({
        textShadow: {
            cursor: "default",
            textShadow: builder.color === "white" ? "2px 3px 5px #000000" : "2px 3px 5px #FFFFFF",
            [theme.fn.smallerThan("xs")]: {
                fontSize: rem(24),
            },
        }
    }))();

    useEffect(() => {
        setGradient(randomGradient());
    }, []);

    if (builder.children) {
        return (
            <Paper withBorder radius="lg">
            <BackgroundImage
                src={builder.imageAsset === "random_gradient" ? gradient : gradient }
                radius="lg"
                p={builder.p}
            >
                { builder.children }
            </BackgroundImage>
            </Paper>
        );
    };

    return (
        <Paper withBorder p={0} radius="lg" h={builder.h}>
            <BackgroundImage
                src={builder.imageAsset === "random_gradient" ? gradient : gradient }
                radius="lg"
                h={builder.h}
            >
                <Box p="sm">
                    <Stack spacing={0}>
                        <Text className={classes.textShadow} size={28} weight={900} color={builder.color}>{builder.content?.title}</Text>
                        <Text className={classes.textShadow} style={{lineHeight: 1}} size={26} weight={900} color={builder.color}>{builder.content?.subtitle}</Text>
                    </Stack>
                </Box>
            </BackgroundImage>
        </Paper>
    ) 
}