import axios from "axios";
import { BaseURL } from "../BasicURLScheme";
import { DocumentLiteDTO } from "./VaccinationService";

export class AppointmentService {
    private baseURL: BaseURL = BaseURL.shared;
    private module: string = "/api/modern/appointment"

    public async fetchList(petID: string) {
        const token = this.baseURL.obtainHeader();
        return axios.get<Array<AppointmentAPIDTO>>(
            this.baseURL.buildEndpoin(`${this.module}/pet/${petID}`),
            {
                headers: {
                    ...token
                }
            }
        );
    }

    public async fetchDetails(id: string) {
        const token = this.baseURL.obtainHeader();
        return axios.get<AppointmentAPIDTO>(
            this.baseURL.buildEndpoin(`${this.module}/${id}`),
            {
                headers: {
                    ...token
                }
            }
        );
    }

    public async create(dto: AppointmentCreateAPIDTO) {
        const token = this.baseURL.obtainHeader();

        return axios({
            method: "POST",
            url: this.baseURL.buildEndpoin(`${this.module}/`),
            headers: { ...token },
            data: { ...dto }
        });
    }

    public async update(dto: AppointmentUpdateAPIDTO) {
        const token = this.baseURL.obtainHeader();

        return axios({
            method: "PUT",
            url: this.baseURL.buildEndpoin(`${this.module}/`),
            headers: { ...token },
            data: { ...dto }
        });
    }

    public async delete(id: string) {
        const token = this.baseURL.obtainHeader();

        return axios.delete(
            this.baseURL.buildEndpoin(`${this.module}/${id}`),
            {
                headers: {
                    ...token
                }
            }
        );
    }
}

export interface AppointmentCreateAPIDTO {
    petID: string;
    appointmentDate: string;
    reason: string;
}

export interface AppointmentUpdateAPIDTO {
    id: string;
    reason?: string;
    appointmentDate?: string;
}

export interface AppointmentAPIDTO {
    id: string;
    reason: string;
    appointmentDate: string;
    documentDTOs: DocumentLiteDTO[],
    isEditable: Boolean
}