import { Button, Center, createStyles, Group, PasswordInput, Space, Stack, Text, TextInput, Transition } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconArrowLeft } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AuthorizationService } from "../../../Domain/Authorization/AuthorizationService";

export const CLoginView: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const authorizationService = new AuthorizationService();

    interface FillData {
        email?: string;
        password?: string;
    };

    const localized = {
        loginError: {
            title: t("Sign in error."),
            message: t("Invalid email or password. Try again, please."),
            color: "red"
        }
    };

    type Pole = "email" | "password";
    
    const [fillData, _fillData] = useState<FillData>({});
    const [mounted, _mounted] = useState(false);
    const [process, _process] = useState(false);

    function valueChanged(data: string, pole: Pole) {
        _fillData({ ...fillData, [pole]:data });
    };

    function buttonTapped() {
        if (fillData.password == undefined || fillData.password.length == 0 || fillData.email == undefined || fillData.email.length == 0) {
            showNotification({
                title: localized.loginError.title,
                message: localized.loginError.message,
                autoClose: 5000,
                color: localized.loginError.color
            });

            return;
        }

        _process(true);
        authorizationService
        .login(
            { email: fillData.email, password: fillData.password }
        )
        .catch(error => {
            _process(false);
            showNotification({
                title: localized.loginError.title,
                message: localized.loginError.message,
                autoClose: 5000,
                color: localized.loginError.color
            })
            return
        })
        .then(result => {
            _process(false);
            if (result == undefined) { return }
            window.location.reload();
            window.location.href = "/";
        })
    };

    useEffect(() => { 
        _mounted(true);
     }, []);

    const { classes } = createStyles((theme) => ({
        input: {
            fontWeight: 600,
        }}
        )
    )();

    const greeting = (
        <Stack>
            <Group>
                <Button variant="light" radius="md" leftIcon={<IconArrowLeft />} onClick={() => { navigate("/") }}>{t("Back")}</Button>
            </Group>
            <Space h={2}/>
            <Text size={24} weight={800} style={{lineHeight: 1.1}}>{t("Hey, hello! 👋")}</Text>
            <Text size={16} weight={500} style={{lineHeight: 1.1}} color="dimmed">{t("Enter the information you entered while registering")}</Text>
        </Stack>
    );

    const inputs = (
        <Stack>
            <Stack spacing={0}>
                <Text
                    size="sm"
                    weight={600}
                >
                    {t("Email")}
                </Text>
                <TextInput
                    size="md"
                    classNames={classes}
                    value={fillData.email ?? ""}
                    placeholder="hello@mail.com"
                    onChange={(e) => { valueChanged(e.target.value, "email") }}
                    radius="md"
                />
            </Stack>

            <Stack spacing={0}>
                <Text
                    size="sm"
                    weight={600}
                >
                    {t("Password")}
                </Text>
                <PasswordInput
                    size="md"
                    classNames={classes}
                    value={fillData.password ?? ""}
                    placeholder="•••••••••••"
                    onChange={(e) => { valueChanged(e.target.value, "password") }}
                    radius="md"
                />
            </Stack>
            <Button loading={process} onClick={buttonTapped} h={40} radius="md">{t("Login")}</Button>
            </Stack>
    )

    return (
        <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
        {(styles) => <div style={{height: "100%", ...styles}}>
            <Center h={"100%"}>
            <Stack justify="center" h={"100%"} maw={400}>
                { greeting }
                { inputs }
            </Stack>
        </Center>
        </div>}
        </Transition>  
    )
};