import { notifications } from "@mantine/notifications";
import i18next from "i18next";

export class ToastManager {
    private static instance: ToastManager;

    private constructor() {}

    public static getInstance(): ToastManager {
        if (!ToastManager.instance) {
            ToastManager.instance = new ToastManager();
        }
        return ToastManager.instance;
    }

    showSuccess(message: string) {
        notifications.show({
            title: i18next.t("Success!"),
            message: message,
            color: "green",
            autoClose: true
        });
    };
    
    showError(message: string) {
        notifications.show({
            title: i18next.t("Error"),
            message: message,
            color: "red",
            autoClose: true
        });
    };
};