import { Button, Center, FileInput, FileInputProps, Group, Space } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { DocumentDTO } from "../../Domain/Ambulance/AmbulanceService";
import { IconPhoto, IconUpload } from '@tabler/icons-react';
import { CareFileInputViewModel } from "./CareFileInputViewModel";

export interface CareFileInputItemViewInterace {
    file: File
}

export function CareFileInputItemView(infoModel: CareFileInputItemViewInterace) {
    return (
        <Center
            inline
            sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[1],
                fontSize: theme.fontSizes.xs,
                padding: '3px 7px',
                borderRadius: theme.radius.sm,
            })}
        >
            <IconPhoto size={14} style={{ marginRight: 5 }} />
            <span
                style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: 200,
                    display: 'inline-block',
                }}
            >
                {infoModel.file.name}
            </span>
        </Center>
    );
};

export const CareFileInputItemViewComponent: FileInputProps['valueComponent'] = ({ value }) => {
    if (Array.isArray(value)) {

        const items = value.map((file, index) => (
            <CareFileInputItemView file={file} key={index} />
        ));

        return (
            <Group spacing="sm" py="xs">
                { items }
            </Group>
      );
    }
  
    if (value) {
      return <CareFileInputItemView file={value} />;
    }
  
    return <></>;
  }; 